<template>
  <div style="flex: 1; overflow: scroll">
    <van-pull-refresh v-model="pullFresh" @refresh="onRefresh" class="pull-wrapper">
      <van-list
        v-model:loading="listFresh"
        :finished="loadFinished"
        finished-text="已经全部加载完毕"
        v-model:error="listError"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
        class="list-wrap"
      >
        <div class="list-item" v-for="item in list" :key="item.id" @click.stop="clickItem(item)">
          <slot :item="item"></slot>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted, nextTick, watch } from 'vue'
import { http_clean } from '@/utils/request'
// 下拉刷新列表组件
export default {
  props: {
    sourceUrl: {
      type: String,
      require: true
    },
    // 请求的数据
    data: {
      type: Object,
      require: true
    }
  },
  emits: ['click-item'],
  setup(props, { emit }) {
    const state = reactive({
      list: [],
      pullFresh: false,
      listFresh: false,
      listError: false,
      loadFinished: true // 默认加载完成，手动触发加载，防止没有数据，vant list组件一直加载
    })
    const pager = {
      pageCount: 0,
      nowPage: 1,
      showPage: 1,
      pageSize: 10
    }

    const showPage = ref(1) // 图钉展示的当前页数
    const scrollEl = ref() // 外层滚动元素

    onMounted(() => {
      state.pullFresh = true
      onRefresh()
    })
    watch(
      () => props.data,
      () => {
        onRefresh()
      },
      {
        deep: true
      }
    )

    const onRefresh = async () => {
      state.list = []
      pager.nowPage = 1
      const data = await fetchData()
      const { pageCount, exhibitDatas } = data
      state.list = exhibitDatas
      await nextTick()
      pager.pageCount = pageCount
      state.pullFresh = false
    }

    const onLoad = async () => {
      if (state.pullFresh) {
        state.listFresh = false
        return
      }
      pager.nowPage++
      const data = await fetchData()
      const { pageCount, exhibitDatas } = data
      pager.pageCount = pageCount
      state.list = state.list.concat(exhibitDatas)
      state.listFresh = false
    }

    const clickItem = item => {
      emit('click-item', item)
    }

    async function fetchData() {
      const gridPager = JSON.stringify({
        pageSize: '10',
        nowPage: pager.nowPage.toString(),
        parameters: props.data
      })
      try {
        const data = await http_clean({
          url: props.sourceUrl,
          method: 'post',
          data: { gridPager }
        })
        const { pageCount } = data
        if (pager.nowPage >= pageCount) {
          state.loadFinished = true
        } else {
          state.loadFinished = false
        }
        return data
      } catch (error) {
        state.listError = true
      }
    }
    return {
      ...toRefs(state),
      scrollEl,
      clickItem,
      onRefresh,
      onLoad,
      showPage
    }
  }
}
</script>
<style scoped lang="scss">
.pull-wrapper {
  background: #fff;
  :deep .van-pull-refresh__track {
    height: auto;
  }
}
.list-item {
  padding: 8px 4px;
}
</style>
