<template>
  <div class="cover-page">
    <NavBar />
    <div class="container">
      <div class="freq-wrap">
        <div class="text-cell">
          <span>检测频率[秒]：</span>
          <input type="text" v-model="frequency" />
        </div>
        <div class="text-cell">
          <span>检测次数：</span>
          <input type="text" v-model="DetectionTimes" />
        </div>
        <div class="operate-section">
          <van-button type="primary" @click="requestStart" v-show="isShowStartBtn">开始</van-button>
          <van-button type="primary" v-show="!isShowStartBtn" @click="endSubmit">结束[{{ TestingTimes }}]</van-button>
        </div>
      </div>
      <div class="info-detail">
        <van-row>
          <van-col span="12" class="text-cell">
            <span>应答次数:</span>
            <span>{{ answerTimes }}</span>
          </van-col>
          <van-col span="12" class="text-cell">
            <span>设备ID:</span>
            <span>{{ deviceId }}</span>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="12" class="text-cell">
            <span>采集时间:</span>
            <span>{{ SignalTestingData.code_0407 }}</span>
          </van-col>
          <van-col span="12" class="text-cell">
            <span>GPS模块:</span>
            <span>
              <span v-if="SignalTestingData.code_04D8 == '未识别'" style="color: red">
                {{ SignalTestingData.code_04D8 }}
              </span>
              <span v-if="SignalTestingData.code_04D8 != '未识别'">{{ SignalTestingData.code_04D8 }}</span>
            </span>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="12" class="text-cell">
            <span>GPS时间:</span>
            <span>
              <span v-if="SignalTestingData.isShowRedTime" style="color: red">{{ SignalTestingData.code_0408 }}</span>
              <span v-if="!SignalTestingData.isShowRedTime">{{ SignalTestingData.code_0408 }}</span>
            </span>
          </van-col>
          <van-col span="12" class="text-cell">
            <span>GPS航向:</span>
            <span>{{ SignalTestingData.code_040B }}</span>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="12" class="text-cell">
            <span>GPS经度:</span>
            <span>{{ SignalTestingData.code_0409 }}</span>
          </van-col>
          <van-col span="12" class="text-cell">
            <span>GPS纬度:</span>
            <span>{{ SignalTestingData.code_040A }}</span>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="12" class="text-cell">
            <span>GPS速度:</span>
            <span>{{ SignalTestingData.code_040C }}</span>
          </van-col>
          <van-col span="12" class="text-cell">
            <span>信号强度:</span>
            <span>{{ SignalTestingData.code_041D }}</span>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="12" class="text-cell">
            <span>定位状态:</span>
            <span>
              <span v-if="SignalTestingData.code_04D0 == 'GPS无定位'" style="color: red">
                {{ SignalTestingData.code_04D0 }}
              </span>
              <span v-if="SignalTestingData.code_04D0 != 'GPS无定位'">{{ SignalTestingData.code_04D0 }}</span>
            </span>
          </van-col>
          <van-col span="12" class="text-cell">
            <span>GPS天线:</span>
            <span>
              <span
                v-if="
                  SignalTestingData.code_04D7 == 'GPS天线未知' ||
                  SignalTestingData.code_04D7 == 'GPS天线开路' ||
                  SignalTestingData.code_04D7 == 'GPS天线短路'
                "
                style="color: red"
              >
                {{ SignalTestingData.code_04D7 }}
              </span>
              <span
                v-if="
                  SignalTestingData.code_04D7 != 'GPS天线未知' &&
                  SignalTestingData.code_04D7 != 'GPS天线开路' &&
                  SignalTestingData.code_04D7 != 'GPS天线短路'
                "
              >
                {{ SignalTestingData.code_04D7 }}
              </span>
            </span>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="12" class="text-cell">
            <span>GPS定位卫星:</span>
            <span>{{ SignalTestingData.code_04D9 }}</span>
          </van-col>
          <van-col span="12" class="text-cell">
            <span>GPS可视卫星:</span>
            <span>{{ SignalTestingData.code_04DB }}</span>
          </van-col>
        </van-row>

        <van-row>
          <van-col span="12" class="text-cell">
            <span>北斗定位卫星:</span>
            <span>{{ SignalTestingData.code_04DA }}</span>
          </van-col>
          <van-col span="12" class="text-cell">
            <span>北斗可视卫星:</span>
            <span>{{ SignalTestingData.code_04DC }}</span>
          </van-col>
        </van-row>
      </div>

      <div class="chart-section">
        <chart-view height="300px" :chartOption="option_GPS"></chart-view>
        <chart-view height="300px" :chartOption="option_North"></chart-view>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, toRefs, onMounted, onBeforeUnmount } from 'vue'
import { useRoute } from 'vue-router'
import { Button } from 'vant'
import ChartView from '@/components/echart/index.vue'
import signalTest from './signalTest'

export default defineComponent({
  name: 'SignalTest',
  components: {
    ChartView,
    [Button.name]: Button
  },
  setup() {
    const route = useRoute()

    const { state, functions, requestTimes, option_GPS, option_North } = signalTest()
    onMounted(() => {
      const { deviceId } = route.query
      state.SignalTestingData.code_deviceId = deviceId
      const { initSignalChart } = functions
      initSignalChart()
    })
    onBeforeUnmount(() => {
      if (requestTimes) clearInterval(requestTimes)
    })
    return {
      ...toRefs(state),
      ...functions,
      option_GPS,
      option_North
    }
  }
})
</script>
<style lang="scss" scoped>
.operate-section {
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  button {
    width: 100%;
    border-radius: 8px;
  }
}
.info-detail {
  background-color: #fff;
  margin-bottom: 18px;
}
</style>
