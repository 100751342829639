import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import { http_clean } from '@/utils/request'
import { throttle } from '@/utils/common'
import { formatTimeStr } from '@/utils/common'
import { Toast } from 'vant'

export const useScroll = () => {
  const route = useRoute()

  const state = reactive({
    pullLoading: false // 下拉刷新的加载状态
  })
  const pager = reactive({
    currentPage: 1,
    pageCount: 1,
    queryPage: 1,
    pageSize: 10
  })
  const listState = reactive({
    orderList: [],
    listLoading: false,
    error: false, // list 加载出错
    finished: true
  })

  const { startTime, endTime } = initSearch()
  // 绑定元素不能绑定到 van-pull-refresh 组件上， 编译后绑定不是 dom 元素
  const scrollEl = ref()

  onMounted(() => {
    fetchData()
  })

  const onRefresh = () => {
    scrollEl.value.scrollTop = 0
    state.pullLoading = true
    pager.queryPage = 1
    queryData()
  }

  // 如果当前页数超过总页数请求结束
  const onLoad = () => {
    if (!listState.finished) {
      pager.queryPage++
      queryData()
    }
  }

  function queryData() {
    if (new Date(startTime.value).getTime() > new Date(endTime.value).getTime()) {
      Toast('开始时间不能晚于结束时间')
      return
    }
    const start = formatTimeStr(startTime.value)
    const end = formatTimeStr(endTime.value)

    fetchData({
      start,
      end
    })
  }

  const fetchData = data => {
    const tempParam = {
      deviceId: route.params.device
    }
    if (data) {
      tempParam.startTime = data.start
      tempParam.endTime = data.end
    }
    const params = JSON.stringify(tempParam)

    const gridPager = `{"pageSize":"10","nowPage":"${pager.queryPage}","parameters":${params}}`
    http_clean({
      url: 'garbage/onceGarbagePageList.html',
      method: 'post',
      data: { gridPager }
    })
      .then(res => {
        if (!res.isSuccess) {
          pager.queryPage--
          state.pullLoading = false
          listState.listLoading = false
          listState.finished = false
          listState.error = true
          return
        }
        res.exhibitDatas = res.isSuccess ? res.exhibitDatas : []

        if (state.pullLoading) {
          listState.orderList = []
        }
        pager.pageCount = res.pageCount
        listState.finished = pager.queryPage >= pager.pageCount
        listState.orderList.push(...res.exhibitDatas)
        state.pullLoading = false
        listState.listLoading = false
      })
      .catch(() => {
        pager.queryPage--
        state.pullLoading = false
        listState.listLoading = false
        listState.error = true
      })
  }

  const scrollHandler = throttle(e => {
    let distance = e.target.scrollTop + e.target.getBoundingClientRect().height
    const itemEl = document.querySelector('.list-wrap__item')
    // dom 高度
    const innerHeigh = itemEl.getBoundingClientRect().height
    // 获取css属性
    const cssComAttrs = document.defaultView.getComputedStyle(itemEl)
    const marginT = parseFloat(cssComAttrs.marginTop)
    const elHeight = innerHeigh + marginT

    pager.currentPage =
      Math.ceil(distance / (elHeight * 10)) >= pager.pageCount ? pager.pageCount : Math.ceil(distance / (elHeight * 10))
  }, 100)

  function initSearch() {
    const startTime = ref('')
    const endTime = ref('')
    let current = new Date()
    let paramEnd = formatTimeStr(current)
    // 开始时间减一天
    current.setDate(current.getDate() - 1)
    let paramStart = formatTimeStr(current)
    startTime.value = paramStart
    endTime.value = paramEnd
    return { startTime, endTime }
  }

  return {
    startTime,
    endTime,
    state,
    listState,
    pager,
    scrollEl,
    onRefresh,
    onLoad,
    queryData,
    scrollHandler
  }
}
