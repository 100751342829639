<template>
  <div class="up-operate">
    <NavBar />
    <div class="container up-operate__wrap">
      <div class="card">
        <list-row rowTitle="公司名称" :rowContent="info.companyName"></list-row>
        <list-row rowTitle="车辆分类" :rowContent="carDetailUseList[info.useTypeL2]"></list-row>
        <list-row rowTitle="硬件版本" :rowContent="info.protocolVersion === '2' ? '第二代' : '第一代'"></list-row>
        <list-row rowTitle="上传时间" :rowContent="info.updateDate"></list-row>
        <list-row rowTitle="版本号" :rowContent="info.version"></list-row>
        <list-row rowTitle="包个数" :rowContent="info.packetTotal"></list-row>
        <list-row rowTitle="描述" :rowContent="info.description"></list-row>
      </div>
      <div class="log">
        <div class="log__title">升级日志:</div>
        <div class="log__content"></div>
      </div>
      <div class="bottom">
        <van-button type="primary" size="small" class="operate" @click="upgradeSubmit">开始升级</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import http from '@/utils/request'
import { carDetailUseList } from '@/utils/types'
import ListRow from '@/views/components/ListRow.vue'
import { Toast } from 'vant'

export default defineComponent({
  name: 'UpgradeOperate',
  components: { ListRow },
  setup() {
    const store = useStore()
    const router = useRouter()
    const deviceId = computed(() => store.state.search.deviceId)
    const info = inject('binInfo')

    const upgradeSubmit = () => {
      const params = {
        binId: info.value.binId,
        deviceId: deviceId.value,
        protocolVersion: 2
      }
      http({
        url: 'system/upgradeoffline.html',
        method: 'post',
        data: params
      }).then(res => {
        Toast(res.message)
        if (res.success) {
          setTimeout(() => {
            router.go(-1)
          }, 500)
        }
      })
    }
    return {
      info,
      carDetailUseList,
      upgradeSubmit
    }
  }
})
</script>
<style lang="scss" scoped>
.up-operate {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 80;
  display: flex;
  flex-direction: column;
  &__wrap {
    display: flex;
    flex-direction: column;
    .card {
      box-shadow: 0 0 6px #e5e7eb;
    }
    .log {
      flex: 1;
      padding: 2px 4px;
    }
    .bottom {
      background: #f1f1f1;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .operate {
        width: 220px;
      }
    }
  }
}
</style>
