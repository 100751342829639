// 厂区类型list
export const companyTypeList = [
  { value: '1', text: '提货厂区', keyword: 'companyType' },
  { value: '2', text: '安装厂区', keyword: 'companyType' }
]

// 问题分类枚举list
export const questionTypeList = [
  {
    value: '1',
    text: '算法问题',
    keyword: 'problem',
    children: [
      { value: '101', text: '上货问题', keyword: 'programType' },
      { value: '102', text: '卸货问题', keyword: 'programType' },
      { value: '103', text: '在途问题', keyword: 'programType' },
      { value: '100', text: '其他', keyword: 'programType' }
    ]
  },
  {
    value: '2',
    text: '硬件问题',
    keyword: 'problem',
    children: [
      { value: '201', text: '主机问题', keyword: 'programType' },
      { value: '202', text: '传感器问题', keyword: 'programType' },
      { value: '203', text: 'GPS问题', keyword: 'programType' },
      { value: '204', text: '线材问题', keyword: 'programType' },
      { value: '200', text: '其他', keyword: 'programType' }
    ]
  },
  {
    value: '3',
    text: '司机问题',
    keyword: 'problem',
    children: [
      { value: '301', text: '司机问题', keyword: 'programType' },
      { value: '300', text: '其他', keyword: 'programType' }
    ]
  },
  {
    value: '4',
    text: '对接问题',
    keyword: 'problem',
    children: [
      { value: '401', text: '对接问题', keyword: 'programType' },
      { value: '400', text: '其他', keyword: 'programType' }
    ]
  },
  {
    value: '5',
    text: '运维问题',
    keyword: 'problem',
    children: [
      { value: '501', text: '运维问题', keyword: 'programType' },
      { value: '502', text: '未升级', keyword: 'programType' },
      { value: '503', text: '系数问题', keyword: 'programType' },
      { value: '500', text: '其他', keyword: 'programType' }
    ]
  },
  {
    value: '6',
    text: '安装问题',
    keyword: 'problem',
    children: [
      { value: '601', text: '安装问题', keyword: 'programType' },
      { value: '600', text: '其他', keyword: 'programType' }
    ]
  },
  {
    value: '7',
    text: '平台问题',
    keyword: 'problem',
    children: [
      { value: '701', text: '未识别异常', keyword: 'useTypeL2' },
      { value: '702', text: '算法曲线断裂', keyword: 'useTypeL2' },
      { value: '703', text: '平台卸货又上货', keyword: 'useTypeL2' },
      { value: '700', text: '其他', keyword: 'useTypeL2' }
    ]
  },
  {
    value: '8',
    text: '磅单问题',
    keyword: 'problem',
    children: [
      { value: '801', text: '磅单问题', keyword: 'useTypeL2' },
      { value: '800', text: '其他', keyword: 'useTypeL2' }
    ]
  },
  {
    value: '9',
    text: '断电问题',
    keyword: 'problem',
    children: [{ value: '901', text: '断电问题', keyword: 'useTypeL2' }]
  }
]
// 处理状态list
export const detailStatusList = [
  { value: '', text: '全部', keyword: 'detailStatus' },
  { value: '0', text: '待处理', keyword: 'detailStatus' },
  { value: '1', text: '人工处理', keyword: 'detailStatus' },
  { value: '2', text: '算法处理', keyword: 'detailStatus' },
  { value: '3', text: '无异常', keyword: 'detailStatus' }
]
// GPS状态
export const isGpsErrorList = [
  { value: '', text: '全部', keyword: 'isGpsError' },
  { value: '1', text: 'GPS问题', keyword: 'isGpsError' },
  { value: '3', text: '无GPS', keyword: 'isGpsError' },
  { value: '4', text: '停车车速平直', keyword: 'isGpsError' },
  { value: '5', text: '大段丢失', keyword: 'isGpsError' },
  { value: '6', text: '偶尔丢失', keyword: 'isGpsError' }
]
// 订单状态
export const queryCheckStatusList = [
  { value: '', text: '全部', keyword: 'queryCheckStatus' },
  { value: '0', text: '正常卸货', keyword: 'queryCheckStatus' },
  { value: '2', text: '未清零 [进厂]', keyword: 'queryCheckStatus' },
  { value: '3', text: '未识别上货 [离厂]', keyword: 'queryCheckStatus' },
  { value: '4', text: '重量突变 [在途]', keyword: 'queryCheckStatus' },
  { value: '5', text: '卸货未清零', keyword: 'queryCheckStatus' },
  { value: '6', text: '设备离线', keyword: 'queryCheckStatus' },
  { value: '8', text: '垂直卸货', keyword: 'queryCheckStatus' },
  { value: '10', text: '异常订单', keyword: 'queryCheckStatus' },
  { value: '11', text: '卸货有速度', keyword: 'queryCheckStatus' }
]
// 订单进度
export const orderStatus2List = [
  { value: '', text: '全部', keyword: 'orderStatus2' },
  { value: '0', text: '未结束', keyword: 'orderStatus2' },
  { value: '1', text: '已结束', keyword: 'orderStatus2' }
]
// 算法
export const isFixWeightList = [
  { value: '', text: '全部', keyword: 'isFixWeight' },
  { value: '0', text: '未运行算法', keyword: 'isFixWeight' },
  { value: '1', text: '算法正常运行', keyword: 'isFixWeight' },
  { value: '2', text: '算法未识别上货', keyword: 'isFixWeight' },
  { value: '3', text: '算法未清零', keyword: 'isFixWeight' },
  { value: '4', text: '算法已运行', keyword: 'isFixWeight' },
  { value: '5', text: '标定重量', keyword: 'isFixWeight' }
]
// 时间类型
export const dateTypeList = [
  { value: '1', text: '进厂时间', keyword: 'dateType' },
  { value: '2', text: '离厂时间', keyword: 'dateType' },
  { value: '3', text: '订单结束时间', keyword: 'dateType' },
  { value: '4', text: '操作时间', keyword: 'dateType' }
]
