import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

const provinceAbbr = ['京沪粤津冀晋蒙辽吉黑', '苏浙皖闽赣鲁豫鄂湘', '桂琼渝川贵云藏', '陕甘青宁新港澳台'] // 车牌键盘第一个字
const keyBoardNumber = '1234567890' // 车牌键盘数字
const keyBoardLast = '学警挂领试超' // 车牌最后一位可能的特殊字
const keyBoardChar = ['QWERTYUIOP', 'ASDFGHJKL', 'ZXCVBNM'] //车牌键盘大写字母

const carNumberModule = () => {
  const store = useStore()
  const state = reactive({
    carNumber: '', //确认选中的carNumber
    carInputContent: '', // 车牌输入框展示的carNumber
    isInput: false, // 是否显示车牌输入框
    showKeyboard: true //自动聚焦
  })

  // 展示车牌输入popup，并自动填入车牌值
  const numberCheck = () => {
    state.carInputContent = state.carNumber
    if (state.carInputContent.length > 7) {
      state.carInputContent = state.carInputContent.substr(0, 7)
    }
    state.isInput = true
  }
  // 车牌输入事件
  const inputCarNumber = e => {
    if (state.carInputContent.length < 7) {
      let val = e.target.dataset.val
      state.carInputContent = state.carInputContent + val
    }
  }
  // 车牌最后一位中文字符输入
  const inputCarNumberSpec = e => {
    if (state.carInputContent.length > 1 && state.carInputContent.length < 7) {
      let val = e.target.dataset.val
      state.carInputContent = state.carInputContent + val
    }
  }
  // 车牌删除事件
  const delCarNumber = () => {
    state.carInputContent = state.carInputContent.substring(0, state.carInputContent.length - 1)
  }
  // 确认车辆信息
  const confirmCar = () => {
    store.commit('changeNum/updateState', { carNumber: state.carInputContent })
    state.carNumber = state.carInputContent
    state.isInput = false
  }
  return {
    provinceAbbr,
    keyBoardNumber,
    keyBoardLast,
    keyBoardChar,
    ...toRefs(state),
    numberCheck,
    inputCarNumber,
    inputCarNumberSpec,
    delCarNumber,
    confirmCar
  }
}
export default carNumberModule
