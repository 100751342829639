<template>
  <div class="device-detail">
    <NavBar />
    <div class="flex-main overflow-scroll">
      <van-cell-group class="baseInfo card">
        <van-row class="text-cell">
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">车牌号码:</div>
              <div class="baseInfo__content">{{ baseInfo.carNumber }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">公司名称:</div>
              <div class="baseInfo__content">{{ baseInfo.companyName }}</div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">录入人员:</div>
              <div class="baseInfo__content">{{ deviceBaseInfo.userNameForCreate }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">订单状态:</div>
              <div class="baseInfo__content">
                <span>
                  <span
                    v-if="
                      baseInfo.orderStatus == 0 &&
                      baseInfo.checkStatus == 0 &&
                      (!baseInfo.isGpsError || baseInfo.isGpsError == 2)
                    "
                    class="orange"
                  >
                    运输中
                  </span>
                  <span
                    v-if="
                      baseInfo.orderStatus != 0 &&
                      baseInfo.checkStatus == 0 &&
                      (!baseInfo.isGpsError || baseInfo.isGpsError == 2)
                    "
                    class="green"
                  >
                    正常卸货
                  </span>
                  <span v-if="baseInfo.isGpsError && baseInfo.isGpsError != 2" class="red">
                    <span v-if="baseInfo.isGpsError == 1" class="red">GPS异常</span>
                    <span v-if="baseInfo.isGpsError == 3" class="red">无GPS</span>
                    <span v-if="baseInfo.isGpsError == 4" class="red">停车速度平直</span>
                    <span v-if="baseInfo.isGpsError == 5" class="red">大段丢失</span>
                    <span v-if="baseInfo.isGpsError == 6" class="red">偶尔丢失</span>
                    <span v-if="baseInfo.checkStatus == 2" class="red" title="未清零 [进厂]">-未清零 [进厂]</span>
                    <span v-if="baseInfo.checkStatus == 3" class="red" title="未识别上货 [离厂]">
                      -未识别上货 [离厂]
                    </span>
                    <span v-if="baseInfo.checkStatus == 4" class="red" title="重量突变 [在途]">-重量突变 [在途]</span>
                    <span v-if="baseInfo.checkStatus == 5" class="red" title="卸货未清零">-卸货未清零</span>
                    <span v-if="baseInfo.checkStatus == 6" class="red" title="设备离线">-设备离线</span>
                    <span v-if="baseInfo.checkStatus == 7" class="red" title="平台问题">-平台问题</span>
                    <span v-if="baseInfo.checkStatus == 8" class="red" title="垂直卸货">-垂直卸货</span>
                    <span v-if="baseInfo.checkStatus == 9" class="red" title="磅单问题">-磅单问题</span>
                    <span v-if="baseInfo.checkStatus == 10" class="red" title="异常订单">-异常订单</span>
                    <span v-if="baseInfo.checkStatus == 11" class="red" title="卸货有速度">-卸货有速度</span>
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 2"
                    class="red"
                  >
                    未清零 [进厂]
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 3"
                    class="red"
                  >
                    未识别上货 [离厂]
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 4"
                    class="red"
                  >
                    重量突变 [在途]
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 5"
                    class="red"
                  >
                    卸货未清零
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 6"
                    class="red"
                  >
                    设备离线
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 7"
                    class="red"
                  >
                    平台问题
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 8"
                    class="red"
                  >
                    垂直卸货
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 9"
                    class="red"
                  >
                    磅单问题
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 10"
                    class="red"
                  >
                    异常订单
                  </span>
                  <span
                    v-if="(!baseInfo.isGpsError || baseInfo.isGpsError == 2) && baseInfo.checkStatus == 11"
                    class="red"
                  >
                    卸货有速度
                  </span>
                </span>
              </div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">车辆用途:</div>
              <div class="baseInfo__content">{{ deviceBaseInfo.userTypeName }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">车辆状态:</div>
              <div class="baseInfo__content">
                <span>
                  <span class="red" v-if="versionMessage.runStatus == '0'">离线</span>
                  <span class="green" v-if="versionMessage.runStatus !== '0'">在线</span>
                </span>
              </div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">磅单重量:</div>
              <div class="baseInfo__content">{{ baseInfo.sendWeight }}</div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="24">
            <div class="flex-spb">
              <div class="baseInfo__title">安装时间:</div>
              <div class="baseInfo__content">{{ deviceBaseInfo.inputDate }}</div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="24">
            <div class="flex-spb">
              <div class="baseInfo__title">载重更新:</div>
              <div class="baseInfo__content">{{ versionMessage.carUploadDate }}</div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="24">
            <div class="flex-spb">
              <div class="baseInfo__title">GPS更新:</div>
              <div class="baseInfo__content">{{ versionMessage.gpsUploadDate }}</div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">传感器:</div>
              <div class="baseInfo__content">{{ deviceBaseInfo.sensorTypeName }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">处理状态:</div>
              <div class="baseInfo__content">
                <span>
                  <span v-if="baseInfo.detailStatus === 0" class="orange">待处理</span>
                  <span v-if="baseInfo.detailStatus === 1" class="green">人工处理</span>
                  <span v-if="baseInfo.detailStatus === 2" class="green">算法处理</span>
                  <span v-if="baseInfo.detailStatus === 3" class="green">无异常</span>
                </span>
              </div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="24">
            <div class="flex-spb">
              <div class="baseInfo__title">算法版本:</div>
              <div class="baseInfo__content">{{ deviceBaseInfo.upgradeInfo.version }}</div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">维修人:</div>
              <div class="baseInfo__content">{{ baseInfo.repairedUser }}</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="flex-spb">
              <div class="baseInfo__title">最近维修:</div>
              <div class="baseInfo__content">{{ baseInfo.lastServiceTime }}</div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="24">
            <div class="flex-spb">
              <div class="baseInfo__title">维修记录:</div>
              <div class="baseInfo__content">
                <span>
                  <span v-if="baseInfo.repairedRecords && baseInfo.repairedRecords.length > 0">
                    <span v-for="lis of baseInfo.repairedRecords" :key="lis">
                      <span>{{ lis }}</span>
                    </span>
                  </span>
                  <span
                    v-if="
                      baseInfo.repairedRecords == null ||
                      baseInfo.repairedRecords == undefined ||
                      baseInfo.repairedRecords.length == 0
                    "
                  >
                    --
                  </span>
                </span>
              </div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="24">
            <div class="flex-spb">
              <div class="baseInfo__title">当前系数:</div>
              <div class="baseInfo__content">
                <span>
                  <span v-if="deviceBaseInfo.coefT.length > 0">
                    <span>[</span>
                    <span v-for="(lis, i) of deviceBaseInfo.coefT" :key="i">
                      <span>CH{{ lis.index }}:</span>
                      <span>
                        {{ lis.value }}
                        <span v-if="i < deviceBaseInfo.coefT.length - 1">,</span>
                      </span>
                    </span>
                    <span>]</span>
                  </span>
                  <span v-if="deviceBaseInfo.coefT.length == 0">--</span>
                </span>
              </div>
            </div>
          </van-col>
        </van-row>

        <van-row class="text-cell">
          <van-col span="24">
            <div class="sensors">
              <div class="sensors__title">传感器：</div>
              <div class="legend">
                <div v-for="item in renderReasonSensors" :key="item.text">
                  <span :class="['channel-style', 'bg-green', 'sensorModuleFront', { 'bg-normal': !item.flag }]">
                    {{ item.value }}
                    <i class="channel-style sensorFront front" v-if="item.isFront == '1'">前</i>
                    <i class="channel-style sensorFront bottom" v-if="item.isFront == '2'">后</i>
                  </span>
                  <span style="padding-left: 8px">{{ item.sensorProblem }}</span>
                </div>
              </div>
            </div>
          </van-col>
        </van-row>
      </van-cell-group>

      <div class="card">
        <!-- 图表展示区域 -->
        <van-row class="chartOption">
          <van-col span="8">
            <van-checkbox v-model="lookSignal">AD信号</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="showApi">API修正</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="loadometer">地磅数据</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="carSpeed">车辆速度</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="showModify">修正数据</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="isShowMap" @click="showMapCk">地图联动</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="isShowQuestion">问题提交</van-checkbox>
          </van-col>
        </van-row>
        <div class="chartContain">
          <div class="chart-empty position-absolute" v-if="processInfo && (!allData || allData.length === 0)">
            {{ processInfo }}
          </div>
          <chart-view height="250px" :chartOption="echartOption"></chart-view>
          <div
            id="device-map"
            :style="{ height: isShowMap ? '250px' : '1px', visible: isShowMap ? 'visible' : 'hidden' }"
          ></div>
        </div>
        <!-- 图表展示区域 end -->

        <!-- 优化区域的数据输入 -->
        <div class="formArea">
          <van-field
            label="上货前置零:"
            label-width="100px"
            readonly
            placeholder="请选择上货前置零时间"
            v-model="formFields.zeroBeforeDate"
            @click-input="pickTime('zeroBeforeDate')"
          />

          <van-field
            label="上货开始:"
            label-width="100px"
            readonly
            placeholder="请选择上货开始时间"
            v-model="formFields.upStartDate"
            @click-input="pickTime('upStartDate')"
          />

          <van-field
            label="上货结束:"
            label-width="100px"
            readonly
            placeholder="请选择上货结束时间"
            v-model="formFields.upEndDate"
            @click-input="pickTime('upEndDate')"
          />

          <van-field
            label="卸货开始:"
            label-width="100px"
            readonly
            placeholder="请选择卸货开始时间"
            v-model="formFields.downStartDate"
            @click-input="pickTime('downStartDate')"
          />

          <van-field
            label="卸货结束:"
            label-width="100px"
            readonly
            placeholder="请选择卸货结束时间"
            v-model="formFields.downEndDate"
            @click-input="pickTime('downEndDate')"
          />

          <van-field
            label="卸货后置零:"
            label-width="100px"
            readonly
            placeholder="请选择卸货后置零时间"
            v-model="formFields.zeroAfterDate"
            @click-input="pickTime('zeroAfterDate')"
          />

          <van-field
            label="上货前重量:"
            label-width="100px"
            readonly
            placeholder="请输入上货前重量"
            v-model="formFields.startWeight"
            @click-input.stop="showNumInput('startWeight')"
          />

          <van-field
            label="重量:"
            label-width="100px"
            readonly
            placeholder="请输入重量"
            v-model="formFields.weight"
            @click-input.stop="showNumInput('weight')"
          />

          <van-field
            label="卸货后重量:"
            label-width="100px"
            readonly
            placeholder="请输入卸货后重量"
            v-model="formFields.endWeight"
            @click-input.stop="showNumInput('endWeight')"
          />

          <van-field label="数据波动:" label-width="80px">
            <template #input>
              <div class="input-switch">
                <span>1吨内</span>
                <van-switch v-model="formFields.isWave" size="20px" />
              </div>
            </template>
          </van-field>

          <van-field
            label="波动范围:"
            label-width="100px"
            readonly
            placeholder="波动范围"
            v-model="formFields.waveWeight"
            @click-input="wavePicker = true"
          />

          <van-field label="上卸货曲线:" label-width="100px" readonly placeholder="波动范围">
            <template #input>
              <div class="input-switch">
                <span>斜线（默认是sin曲线）</span>
                <van-switch v-model="formFields.isDiagonal" size="20px" />
              </div>
            </template>
          </van-field>

          <van-field label="更新订单状态:" label-width="100px" readonly placeholder="波动范围">
            <template #input>
              <div class="input-switch">
                <van-switch v-model="formFields.changeStatus" size="20px" />
              </div>
            </template>
          </van-field>
          <!-- 优化区域的数据输入 end -->
          <div class="option__btns">
            <van-button type="primary" @click="GPSDealWith">GPS已处理</van-button>
            <van-button type="primary" @click="cancelDeviceIspush" v-if="deviceRepairedIspushFlag">
              取消进厂限制
            </van-button>
            <van-button
              @click="waitDealWith"
              :type="baseInfo.isWaitDetail == 0 ? 'primary' : 'warining'"
              v-if="baseInfo && (baseInfo.isWaitDetail == 0 || baseInfo.isWaitDetail == 1)"
            >
              {{ baseInfo.isWaitDetail == 0 ? '加入待处理' : '取消待处理' }}
            </van-button>
            <van-button type="primary" @click="reserveOrder">刷新订单</van-button>
            <van-button type="primary" @click="runMatlab">手动执行算法</van-button>
            <van-button type="primary" @click="makeData">优化</van-button>
            <van-button type="danger" @click="recall">撤回</van-button>

            <van-button type="primary" @click="dealOrderAlgorithm('2')">算法已处理</van-button>
            <van-button type="primary" @click="dealOrderAlgorithm('3')">订单无异常</van-button>
          </div>
        </div>
        <!-- 下方整个功能区，通过单选问题提交来展示隐藏 -->
        <div class="expand" v-if="isShowQuestion">
          <div class="card">
            <div class="expand__title"><van-checkbox v-model="sensorSelectAll.selected">传感器问题</van-checkbox></div>
            <van-checkbox v-model="isAllStraight" style="margin-left: 16px">传感器全部平直</van-checkbox>
            <div v-for="item in sensorModuleList" :key="item.value" class="form-group">
              <button class="btn-link" title="单个传感器问题提交" @click="updateSensorProblem(item)">更新</button>
              <span :class="['channel-style', 'bg-green', 'sensorModuleFront', { 'bg-normal': !item.flag }]">
                {{ item.value }}
                <i class="channel-style sensorFront front" v-if="item.isFront == '1'">前</i>
                <i class="channel-style sensorFront bottom" v-if="item.isFront == '2'">后</i>
              </span>
              <!-- 问题类型展示 -->
              <div class="flex-content">
                <van-checkbox
                  v-for="(lis, i) in item.child"
                  v-model="lis.selected"
                  @click="sensorLisSelected(item, i)"
                  :key="lis.code"
                  class="flex-item"
                >
                  {{ lis.text }}
                </van-checkbox>
              </div>
              <div class="subtitle" v-if="item.selected && item.startTime && item.endTime">
                <div>{{ item.operator }}</div>
                <div>{{ item.startTime }} 至 {{ item.endTime }}</div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="expand__title"><van-checkbox v-model="GPSSelectAll.selected">GPS问题</van-checkbox></div>
            <div class="form-group GPSModuleBox">
              <button class="btn-link" title="GPS问题提交" @click="updateGPSProblem()" style="margin-left: 8px">
                更新
              </button>
              <div class="flex-content">
                <van-checkbox
                  v-for="(lis, i) in GPSModuleList"
                  :key="lis.code"
                  v-model="lis.selected"
                  @click="GPSLisSelected(i)"
                >
                  {{ lis.text }}
                </van-checkbox>
              </div>
              <div
                v-if="GPSProblemList.selected && GPSProblemList.startTime && GPSProblemList.endTime"
                class="subtitle"
              >
                <div>
                  {{ GPSProblemList.operator }}
                </div>
                <div>{{ GPSProblemList.startTime }} 至 {{ GPSProblemList.endTime }}</div>
              </div>
            </div>
          </div>

          <div class="option__btns">
            <van-button type="primary" @click="updateDeviceStatus" class="single">更新设备状态</van-button>
          </div>

          <div class="card">
            <div class="form-group flex-content">
              <div class="flex-item">
                <van-checkbox v-model="deviceOffline.selected">
                  <span>{{ deviceOffline.text }}</span>
                </van-checkbox>
              </div>
              <div class="flex-item">
                <van-checkbox v-model="deviceContact.selected">
                  <span>{{ deviceContact.text }}</span>
                </van-checkbox>
              </div>
              <div class="flex-item">
                <van-checkbox v-model="deviceRepairedIspush.selected">
                  <span>{{ deviceRepairedIspush.text }}</span>
                </van-checkbox>
              </div>
              <div class="flex-item">
                <van-checkbox @click="deviceOtherClick" v-model="deviceOther.selected">
                  <span>{{ deviceOther.text }}</span>
                </van-checkbox>
              </div>
              <textarea
                class="form-textarea"
                v-if="deviceOther.selected"
                style="margin-top: 4px"
                placeholder="问题描述(如: 4pin接口, 更换主机)"
                v-model="hardwareIssueDescription"
              />
            </div>
          </div>

          <div class="option__btns">
            <van-button type="primary" @click="addDeviceRepaired" class="double">添加维修记录</van-button>
            <van-button type="primary" @click="makerGPS" class="double">标记GPS记录</van-button>
          </div>

          <div class="card">
            <div class="expand__title">Matlab算法问题</div>
            <div class="form-group flex-content">
              <van-checkbox
                v-for="lis in matlabProbs"
                :key="lis.programType"
                class="flex-item"
                @click="algorithmLisSelected(lis.text)"
                v-model="lis.selected"
              >
                {{ lis.text }}
              </van-checkbox>
            </div>
          </div>

          <div class="card">
            <div class="expand__title">平台问题</div>
            <div class="form-group flex-content">
              <div>
                <div v-for="lis in plateProbs" :key="lis.programType">
                  <van-checkbox v-model="lis.selected" @click="algorithmLisSelected(lis.text)" style="flex: 0 0 33%">
                    {{ lis.text }}
                  </van-checkbox>
                  <textarea
                    class="form-textarea"
                    v-if="lis.programType == 700 && lis.selected"
                    style="margin-top: 4px"
                    placeholder="问题描述(如: 4pin接口, 更换主机)"
                    v-model="platformIssueDescription"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="time-range">
            <div style="min-width: 60px">问题区间:</div>
            <div class="daterange">
              <input
                type="text"
                class="daterange__start"
                v-model="formFields.startTime_problem"
                @click="pickTime('startTime_problem')"
              />
              <span class="separator">-</span>
              <input
                type="text"
                class="datarange__end"
                v-model="formFields.endTime_problem"
                @click="pickTime('endTime_problem')"
              />
            </div>
          </div>

          <div class="option__btns">
            <van-button type="primary" @click="updateOrderStatus" class="single">更新订单状态</van-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 选择时间 -->
    <van-popup
      :show="pickerState.datePickerShow"
      position="bottom"
      round
      style="height: 46%"
      @click-overlay="cancelDate"
    >
      <van-datetime-picker
        v-model="pickerState.pickedDate"
        type="datetime"
        title="选择时间"
        @confirm="confirmDate"
        @cancel="cancelDate"
      />
    </van-popup>

    <van-number-keyboard
      :show="pickerState.numKeyShow"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      @blur="pickerState.numKeyShow = false"
      @input="onNumInput"
      @delete="onNumDelete"
    />
  </div>
</template>
<script>
import { defineComponent, toRefs, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { increaseDate, decreaseDate } from '@/utils/common'
import ChartView from '@/components/echart/index.vue'
import { http_clean } from '@/utils/request'
import orderDetail from './orderDetail'
import chartModule from './printChart'
export default defineComponent({
  name: 'OrderManageDeviceDetail',
  components: {
    ChartView
  },
  setup() {
    const route = useRoute()
    // 获取展示状态
    const {
      state,
      getDeviceInfoData,
      initIspushData,
      baseFuncs,
      getRealTime,
      formFields,
      pickerState,
      formFuncs,
      operateState,
      matlabProbs,
      plateProbs
    } = orderDetail()
    // 获取echart相关问题
    const { orderInfo, chartState, chartOption, showChart, showMapCk, initMap, echartOption } = chartModule()

    // 获取订单ID
    const { id } = route.params
    state.orderId = id
    state.renewData = getOrderInfo
    operateState.id = id
    operateState.refreshFunction = getOrderInfo

    onMounted(() => {
      getOrderInfo(id)
      initMap()
    })

    // 根据订单ID拉去信息
    function getOrderInfo(id) {
      http_clean({
        url: 'orderController/getOrderInfo.html',
        method: 'post',
        data: { id: id }
      }).then(result => {
        if (!result.success) return
        const { data } = result
        const deviceId = data.deviceId
        const factoryId = data.factoryId
        // 根据请求的数据获取时间节点
        const echartStartDate = decreaseDate('m', 30, data.emptyLoadTime) //订单开始时间:往前推半个小时
        let temEndTime = data.unloadEndTime ? data.unloadEndTime : data.lastDeviceTime
        const echartEndDate = increaseDate('m', 30, temEndTime) //订单结束时间:往后推半个小时
        // 修改展示列表的数据
        state.baseInfo = data
        formFields.startTime_problem = echartStartDate
        formFields.endTime_problem = echartEndDate
        // 修改 echart 相关数据
        chartState.echartStartDate = echartStartDate
        chartState.echartEndDate = echartEndDate
        orderInfo.deviceId = deviceId
        orderInfo.emptyLoadTime = data.emptyLoadTime
        orderInfo.lastDeviceTime = data.lastDeviceTime

        // 获取数据展示项
        getDeviceInfoData(deviceId, factoryId)
        getRealTime(deviceId)
        initIspushData()
        // 绘制echart
        showChart()
      })
    }
    return {
      echartOption,
      ...toRefs(state),
      ...toRefs(chartState),
      ...toRefs(chartOption),
      ...baseFuncs,
      showMapCk,
      formFields,
      pickerState,
      ...formFuncs,
      matlabProbs,
      plateProbs
    }
  }
})
</script>
<style lang="scss" scoped>
.baseInfo {
  .text-cell {
    font-size: 12px;
    line-height: 16px;
  }
  &__title {
    min-width: 60px;
  }
  &__content {
    flex: 1;
  }
  .sensors {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    &__title {
      min-width: 50px;
    }
    .legend {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.card {
  font-size: 12px;
}
.device-detail {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  display: flex;
  flex-direction: column;
  * {
    box-sizing: border-box;
    font-size: 12px;
  }
  :deep .van-checkbox__icon {
    font-size: 14px;
  }
}
.chartOption {
  padding: 0 12px;
  margin-bottom: 8px;
}
.chartContain {
  background-color: #f8f8f8;
  position: relative;
  .chart-empty {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.input-switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
}
.option__btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  button {
    font-size: 10px;
    padding: 0 4px;
    width: 80px;
    height: 28px;
    flex-shrink: 1;
    transform: scale(0.95);
  }
  .single,
  .double {
    flex: 1;
  }
}
.expand {
  &__title {
    padding: 4px 4px;
    font-size: 14px;
  }
  .card {
    background-color: #f1f1f1;
  }
}
.form-group {
  padding: 4px 8px;
}
.time-range {
  display: flex;
  height: 32px;
  line-height: 32px;
  margin-top: 12px;
}
.daterange {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  height: 30px;
  padding: 2px 8px;
  border: 1px #f2f2f2 solid;
  border-radius: 2px;
  color: #333;
  .separator {
    display: inline-block;
    text-align: center;
    line-height: 32px;
    flex: 1;
  }
  input {
    border: none;
    outline: none;
    display: inline-block;
    height: 100%;
    box-sizing: border-box;
    width: 46%;
    text-align: center;
  }
}
.flex-content {
  display: flex;
  flex-wrap: wrap;
  .flex-item {
    flex: 0 0 25%;
    text-align: center;
    padding: 0 2px;
  }
  :deep .van-radio__icon {
    font-size: 14px;
  }
}
.subtitle {
  font-size: 10px;
  display: flex;
  div {
    margin-right: 8px;
  }
}
.form-textarea {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  background-color: #fff;
  color: #606266;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
}
#transportMap {
  transition: height 0.3s ease-in-out;
}
</style>
