<template>
  <div class="cover-page">
    <NavBar />
    <div class="container maintain__wrapper">
      <div class="tab__content">
        <div @click="selectStatus('0')" :class="['tab__item', { 'tab__item-active': tabStatus === '0' }]">
          <span>待维修记录</span>
        </div>
        <div @click="selectStatus('1')" :class="['tab__item', { 'tab__item-active': tabStatus === '1' }]">
          <span>已维修记录</span>
        </div>
      </div>
      <div class="content" @scroll="scrollTest" ref="scrollEl">
        <van-pull-refresh v-model="pullLoading" @refresh="onRefresh" class="pull-wrapper">
          <van-list
            v-model:loading="listLoading"
            :finished="finished"
            finished-text="没有更多了"
            v-model:error="error"
            error-text="请求失败，点击重新加载"
            @load="onLoad"
            class="list-wrap"
          >
            <div class="list-wrap__item" v-for="item in records" :key="item.id" @click="checkDetail(item)">
              <van-row>
                <van-col span="24">
                  <span v-if="tabStatus === '0'">
                    故障类型:
                    <span>{{ item.faultTypeName }}</span>
                  </span>
                  <span v-else>
                    故障现象:
                    <span>{{ item.faultPhenomenonList[0] }}</span>
                  </span>
                </van-col>
              </van-row>

              <van-row>
                <van-col span="24">
                  <span v-if="tabStatus === '0'">
                    故障现象:
                    <span>{{ item.faultPhenomenon[0] }}</span>
                  </span>
                  <span v-else>
                    维修内容:
                    <span>{{ item.repairContentList.join(',') }}</span>
                  </span>
                </van-col>
              </van-row>

              <van-row>
                <van-col span="24">
                  <span v-if="tabStatus === '0'">
                    进厂限制:
                    <span :style="item.isPush === 0 ? 'color:#07c160' : 'color:#ee0a24'">
                      {{ item.isPush == 1 ? '限制' : '不限制' }}
                    </span>
                  </span>
                  <span v-else>
                    维修细分:
                    <span>{{ item.repairSubdivideList.join(',') }}</span>
                  </span>
                </van-col>
                <van-col span="24">
                  <span v-if="tabStatus === '0'">
                    故障时间:
                    <span>{{ item.faultTime }}</span>
                  </span>
                  <span v-else>
                    维修时间:
                    <span>{{ item.repairedTime }}</span>
                  </span>
                </van-col>
                <van-col span="24">
                  <span v-if="tabStatus === '0'">
                    录入人员:
                    <span>{{ item.lastUpdateUser }}</span>
                  </span>
                  <span v-else>
                    维修人员:
                    <span>{{ item.repairedUserName }}</span>
                  </span>
                </van-col>
              </van-row>

              <van-row v-if="tabStatus === '0'">
                <van-col span="24">
                  <span>
                    录入时间:
                    <span>{{ item.createTime }}</span>
                  </span>
                </van-col>
              </van-row>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <router-view v-slot="{ Component }">
      <transition :name="animationName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
import { ref, reactive, toRefs, onMounted, provide } from 'vue'
import { useStore } from 'vuex'
import { http_clean } from '@/utils/request'
import router from '@/router'
import { useAnimation } from '@/hooks/useAnimation'

export default {
  name: 'DeviceRepair',
  setup() {
    const waitingRepairUrl = 'deviceRepaired/findDeviceRepairedList.html'
    const repairedRecordUrl = 'deviceRepairedV2/findDeviceRepairedV2List.html'
    let requestUrl = waitingRepairUrl

    // 是否使用动画
    const store = useStore()
    const { animationName } = useAnimation()

    const tabStatus = ref('0')

    const state = reactive({
      records: [],
      listLoading: false,
      error: false, // list 加载出错
      finished: true,
      pullLoading: false // 下拉刷新的加载状态
    })

    const pager = reactive({
      currentPage: 1,
      pageCount: 1,
      queryPage: 1,
      pageSize: 10
    })

    let active = false

    const deviceId = store.state.search.deviceId

    onMounted(() => {
      active = true
      load(requestUrl)
    })

    const selectStatus = flag => {
      if (tabStatus.value === flag && !active) return
      active = false
      // 记录当前选中 tab
      tabStatus.value = flag
      // 切换 tab 使用下拉刷新，防止 list 清空数据，导致闪屏
      state.pullLoading = true
      state.listLoading = false
      // 设置结束状态为 true，阻止页面切换不足一页自动请求两次
      state.finished = true
      pager.queryPage = 1
      scrollEl.value.scrollTop = 0
      state.records = []
      if (flag === '0') {
        requestUrl = waitingRepairUrl
      } else {
        requestUrl = repairedRecordUrl
      }
      load()
    }

    const scrollEl = ref()

    const scrollTest = e => {
      let distance = e.target.scrollTop + e.target.getBoundingClientRect().height
      const itemEl = document.querySelector('.list-wrap__item')
      // dom 高度
      const innerHeigh = itemEl.getBoundingClientRect().height
      // 获取css属性
      const cssComAttrs = document.defaultView.getComputedStyle(itemEl)
      const marginT = parseFloat(cssComAttrs.marginTop)
      const elHeight = innerHeigh + marginT

      pager.currentPage =
        Math.ceil(distance / (elHeight * 10)) >= pager.pageCount
          ? pager.pageCount
          : Math.ceil(distance / (elHeight * 10))
      if (pager.currentPage < 1) pager.currentPage = 1
    }

    // 下拉刷新
    function onRefresh() {
      state.pullLoading = true
      pager.queryPage = 1
      load()
    }

    // list的加载事件，页面一进入，默认会执行一次
    function onLoad() {
      // 是否全部加载完成
      if (!state.finished) {
        pager.queryPage++
        load()
      }
    }

    const auditStatus = ref() // 向下提供的审核状态
    const auditDescription = ref('') // 向下提供的描述
    provide('auditStatus', auditStatus)
    provide('auditDescription', auditDescription)

    const checkDetail = item => {
      if (tabStatus.value === '1') {
        auditStatus.value = item.auditStatus
        auditDescription.value = item.describe
        router.push({
          name: 'DeviceRepairDetail',
          params: {
            id: item.id,
            deviceId: item.deviceId
          }
        })
      } else {
        router.push({
          name: 'DeviceRepairEchart',
          params: {
            createTime: item.createTime,
            deviceId: item.deviceId
          }
        })
      }
    }

    function load() {
      const params = JSON.stringify({
        sortType: '2',
        deviceId,
        repairedStatus: tabStatus.value,
        dateType: '1'
      })
      const gridPager = `{"pageSize":"10","nowPage":"${pager.queryPage}","parameters":${params}}`
      http_clean({
        url: requestUrl,
        method: 'post',
        data: { gridPager }
      })
        .then(res => {
          if (!res.isSuccess) {
            // 加载失败需要重新请求当前页
            pager.queryPage--
            state.pullLoading = false
            state.listLoading = false
            state.finished = false
            state.error = true
            return
          }
          res.exhibitDatas = res.isSuccess ? res.exhibitDatas : []
          setTimeout(() => {
            if (state.pullLoading) {
              state.records = []
            }
            state.records = state.records.concat(res.exhibitDatas)
            pager.pageCount = res.pageCount
            // 判断是否全部加载完成
            state.finished = pager.queryPage >= pager.pageCount
            state.pullLoading = false
            state.listLoading = false
          }, 200)
        })
        .catch(() => {
          pager.queryPage--
          state.pullLoading = false
          state.listLoading = false
          state.error = true
        })
    }

    return {
      animationName,
      tabStatus,
      selectStatus,
      scrollEl,
      scrollTest,
      onRefresh,
      onLoad,
      checkDetail,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.maintain__wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
  overflow-y: scroll;
  margin-top: 8px;
  .pull-wrapper {
    :deep .van-pull-refresh__track {
      height: auto;
    }
    div {
      color: #455a6499;
      font-weight: normal;
      line-height: 18px;
    }
    .list-wrap {
      &__item {
        box-sizing: border-box;
        overflow-y: hidden;
        background: #f2f2f2;
        font-size: 12px;
        margin: 4px 8px 4px;
        padding: 12px;
        border-radius: 6px;
      }
    }
  }
}
.tab__content {
  display: flex;
  color: #323233;
  background-color: #fff;
  .tab__item {
    box-sizing: border-box;
    flex: 1;
    text-align: center;
    height: 36px;
    line-height: 36px;
    &-active {
      color: $blue;
    }
  }
}
</style>
