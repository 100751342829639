import { reactive, ref } from 'vue'
import { http_clean } from '@/utils/request'
import { formatTimeStr, getMilliSecond, getTimeDiff } from '@/utils/common'
import { lineGetColor } from '@/utils/common'

//重量y轴线
const yAxiWeight = {
  show: true,
  name: '重量',
  type: 'value',
  position: 'left',
  axisLine: {
    show: true,
    lineStyle: {
      color: 'gray',
      fontSize: 12
    }
  },
  axisLabel: {
    formatter: '{value}'
  }
}

//信号y轴线
const yAxiSignal = {
  show: true,
  name: '信号',
  splitLine: {
    show: false
  },
  type: 'value',
  position: 'right',
  min: undefined,
  max: undefined,
  nameTextStyle: {
    align: 'left'
  },
  axisLine: {
    lineStyle: {
      color: '#5c427c'
    }
  },
  axisLabel: {
    align: 'left',
    fontSize: 12
  }
}

//速度y轴线
const yAxiSpeed = {
  show: true,
  name: 'km/h',
  splitLine: {
    show: false
  },
  type: 'value',
  min: 0,
  max: 120,
  offset: 80,
  nameTextStyle: {
    align: 'center'
  },
  position: 'right',
  axisLine: {
    lineStyle: {
      color: '#5793f3',
      fontSize: 12
    }
  },
  axisLabel: {
    align: 'right',
    formatter: '{value}'
  }
}

//速度y轴线
const yfullAxiSpeed = {
  show: true,
  name: 'km/h',
  splitLine: {
    show: false
  },
  type: 'value',
  min: 0,
  max: 120,
  offset: 80,
  nameTextStyle: {
    align: 'center'
  },
  position: 'right',
  axisLine: {
    lineStyle: {
      color: '#5793f3',
      fontSize: 12
    }
  },
  axisLabel: {
    align: 'right',
    formatter: '{value}'
  }
}

export default function printChart(deviceId) {
  //	指定图表的配置项和数据
  const chartOptions = ref({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross' //十字星
      },

      formatter: function (params) {
        let res = ''
        const len = params.length
        for (let i = 0; i < len; i++) {
          res = res + params[i].marker + params[i].seriesName + ':' + params[i].data + '</br>'
        }
        return res
      }
    },
    dataZoom: [
      {
        id: 'dataZoomX',
        type: 'inside',
        xAxisIndex: [0],
        filterMode: 'filter'
      }
    ],

    grid: {
      left: '10%',
      right: '30%',
      top: '40%',
      bottom: '20%'
    },
    legend: {
      selected: {}
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: [yAxiWeight],

    series: [
      {
        name: '重量',
        type: 'line',
        data: []
      }
    ]
  })

  const fullChartOptions = ref({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross' //十字星
      },
      formatter: function (params) {
        let res = ''
        const len = params.length
        for (let i = 0; i < len; i++) {
          res = res + params[i].marker + params[i].seriesName + ':' + params[i].data + '</br>'
        }
        return res
      }
    },
    dataZoom: [
      {
        id: 'dataZoomX',
        type: 'inside',
        xAxisIndex: [0],
        filterMode: 'filter'
      }
    ],

    grid: {
      left: '10%',
      right: '20%',
      top: '30%',
      bottom: '15%'
    },
    legend: {
      top: 20,
      selected: {}
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: [yAxiWeight],

    series: [
      {
        name: '重量',
        type: 'line',
        data: []
      }
    ]
  })
  const chartState = reactive({
    processInfo: '加载中...',
    allData: [], //echart图后台请求回来的数据
    passageData: [], //所有chart图折线的数组
    loadometerData: [], //后台返回的地磅数据
    loadometerArr: [], //拼接成echart数组
    weightArr: [], //重量折线的数据
    carSpeedArr: [], //速度折线的数据
    passageName: [], //ad信号折线名
    passageArr: [], //ad信号数据
    voltageData: [],
    filterTimeDefault: 0,
    timeShadow: [], //没有历史数据区域
    legendSleced: {},
    multiple: 1,
    hideOrshowline: []
  })
  //初始化echart图
  function initChart() {
    chartState.allData = [] //echart图后台请求回来的数据
    chartState.passageData = [] //所有chart图折线的数组
    chartState.loadometerData = [] //后台返回的地磅数据
    chartState.loadometerArr = [] //拼接成echart数组
    chartState.weightArr = [] //重量折线的数据
    chartState.carSpeedArr = [] //速度折线的数据
    chartState.passageName = [] //ad信号折线名
    chartState.passageArr = [] //ad信号数据
    chartOptions.value.xAxis.data = []
    chartOptions.value.series = []
    fullChartOptions.value.xAxis.data = []
    fullChartOptions.value.series = []
  }

  //后台获取数据
  async function askForData(startTime, endTime) {
    initChart()
    getLine(deviceId, chartState)
    chartState.processInfo = '加载中...'
    chartState.loadometerData = []
    chartState.loadometerArr = []
    chartState.voltageData = []
    let params = {
      deviceId: deviceId,
      showModify: true,
      showApi: true,
      startTime: startTime,
      endTime: endTime
    }
    const result = await http_clean({
      url: 'weighbridge/weightforreal.html',
      method: 'post',
      data: params
    })
    if (result.data.length >= 1) {
      chartState.loadometerData = result.data
    }

    http_clean({
      url: 'logForVoltage/queryLogForVoltageByParam.html',
      method: 'post',
      data: params
    }).then(result => {
      if (result.data.length >= 1) {
        chartState.voltageData = result.data
      }
    })
    http_clean({
      url: 'orderController/getCarDataList.html',
      method: 'post',
      data: params
    }).then(data => {
      if (data.data.length < 2) {
        chartState.processInfo = '没有数据'
        return
      }
      chartState.processInfo = ''

      // 如果有数据，赋值初始化echart
      chartState.allData = data.data
      chartState.carSpeedArr = [] //画速度折线数据
      let timeValue = []
      let len = chartState.allData.length
      // let lostWeight = [] //记录重量丢失的时间
      let lostWeightMark = [] //计算丢失重量的时长
      let nullGpsArr = [] //经纬度为空或者0.0的画折线数据
      let nullMarkerData = [] //计算经纬度丢失的时长
      let isLostGps = false //是否有丢失经纬度
      let isHavePoint = 0 //控制判断经纬度丢失起始结束位置计算时间条件
      /**
       * 计算采样周期,用于判断重量丢失的阈值
       * 取10组历史数据,取中位数作为数据采样的频率
       */
      let temFilterArr = []
      chartState.filterTimeDefault = 0
      if (len > 10) {
        for (let i = 0; i < 10; i++) {
          // 转换时间为毫秒值，并计算时间差
          let time1 = getMilliSecond(chartState.allData[i].uploadDate)
          let time2 = getMilliSecond(chartState.allData[i + 1].uploadDate)
          let diffTime = (time2 - time1) / 1000
          temFilterArr.push(diffTime)
        }
        temFilterArr.sort(function (a, b) {
          return a - b
        })
        chartState.filterTimeDefault = Number(temFilterArr[6]) * 1.5
      }
      chartState.filterTimeDefault = chartState.filterTimeDefault > 150 ? chartState.filterTimeDefault : 150
      /**
       * 对筛选时间范围内,返回的历史数据做处理,对于数据缺失的时间段进行数据的补点,在echart对应显示灰色区域,表明该时间段范围内没有历史数据.
       */
      chartState.timeShadow = []
      if (getTimeDiff(chartState.allData[0].uploadDate, startTime) > chartState.filterTimeDefault * 1000) {
        // 判断曲线数据前有没有过磅数据,有过磅数据,显示无数据区域,没有过磅数据则不显示无数据区域
        let hasWeightInEmptyDataStartTime = false
        for (let i = 0; i < chartState.loadometerData.length; i++) {
          let item = chartState.loadometerData[i]
          if (
            getMilliSecond(startTime) < getMilliSecond(item.date) &&
            getMilliSecond(item.date) < getMilliSecond(chartState.allData[0].uploadDate)
          ) {
            hasWeightInEmptyDataStartTime = true
            break
          }
        }
        if (hasWeightInEmptyDataStartTime) {
          let emptyDataStartTime = [] //存放搜索开始时间空数据补点的集合
          for (
            let t = getMilliSecond(startTime);
            t < getMilliSecond(chartState.allData[0].uploadDate);
            t = t + chartState.filterTimeDefault * 1000
          ) {
            let temDate = formatTimeStr(new Date(t))
            let temObj = {
              aCH1: '',
              aCH2: '',
              aCH3: '',
              aCH4: '',
              aCH5: '',
              aCH6: '',
              aCH7: '',
              aCH8: '',
              aCH9: '',
              aCH10: '',
              aCH11: '',
              aCH12: '',
              aCH13: '',
              aCH14: '',
              aCH15: '',
              aCH16: '',
              checkGps: '',
              checkWeight: '',
              deviceId: deviceId,
              handBrakeHardwareStatus: '',
              handBrakeSoftwareStatus: '',
              mWeight: '',
              rectifyX: '',
              rectifyY: '',
              speed: '',
              uploadDate: temDate,
              weight: '',
              x: chartState.allData[0].x,
              y: chartState.allData[0].y
            }
            emptyDataStartTime.push(temObj)
          }

          let emptyShadowStart = [
            {
              name: '无数据',
              color: '#ddd',
              opacity: 0.3,
              label: {
                color: '#333',
                fontSize: '16',
                position: ['50%', '50%'],
                offset: [-24, -8]
              },
              emphasis: {
                color: '#ddd',
                opacity: 0.5,
                label: {
                  position: ['50%', '50%'],
                  offset: [-24, -8]
                }
              },
              xAxis: startTime
            },
            {
              xAxis: chartState.allData[0].uploadDate
            }
          ]
          chartState.timeShadow.push(emptyShadowStart)
          chartState.allData = emptyDataStartTime.concat(chartState.allData)
        }
      }
      len = chartState.allData.length

      for (let i = 0; i < len; i++) {
        timeValue[i] = chartState.allData[i].uploadDate //x时间轴数据
        chartState.weightArr[i] = chartState.allData[i].weight ? chartState.allData[i].weight : '--' //重量集合

        let _speed = parseFloat(chartState.allData[i].speed) //速度
        if (_speed > -1) {
          chartState.carSpeedArr[i] = parseFloat(chartState.allData[i].speed)
        } else {
          chartState.carSpeedArr[i] = 0
        }
        chartState.loadometerArr[i] = '--'
        nullGpsArr[i] = '--'
        //计算经纬度等于0.0或者丢失的时候是时长start
        if (!chartState.allData[i] || !chartState.allData[i].x || chartState.allData[i].x == '0.0') {
          if (isHavePoint < 1) {
            isHavePoint = i
          }
        } else {
          if (isHavePoint > 0) {
            const lostTime =
              (getMilliSecond(chartState.allData[i].uploadDate) -
                getMilliSecond(chartState.allData[isHavePoint].uploadDate)) /
              1000
            if (lostTime > chartState.filterTimeDefault) {
              let point = {
                value: convertTime(lostTime),
                xAxis: isHavePoint,
                yAxis: chartState.allData[isHavePoint].weight,
                lostime: lostTime
              }
              nullMarkerData.push(point)
            }
            isHavePoint = 0
          }
        }
        //计算end
        if (i < len - 2) {
          //获取丢失的数据
          let lostStartime = getMilliSecond(chartState.allData[i].uploadDate)
          let lostEndtime = getMilliSecond(chartState.allData[i + 1].uploadDate)
          let lostime = (lostEndtime - lostStartime) / 1000
          if (lostime > 600) {
            //重量时间段丢失   阈值固定,10分钟以上显示
            let point = {
              value: convertTime(lostime),
              xAxis: i,
              yAxis: chartState.allData[i].weight,
              lostime: lostime
            }
            lostWeightMark.push(point)
          }
          if (!chartState.allData[i].x || chartState.allData[i].x == '0.0') {
            //经纬度坐标丢失
            if (i > 0) {
              isLostGps = true
              nullGpsArr[i] = chartState.allData[i].weight
            }
          }
        }
      }
      //查询连续性为空的经纬度数据结束空的位置
      chartState.passageData = []
      //获取异常信息数据
      let alertReq = {
        deviceId: deviceId,
        startTime: startTime,
        endTime: endTime
      }
      let logReq = {
        deviceId: deviceId,
        startDate: startTime,
        endDate: endTime
      }

      //警报信息:离线警报,断电警报
      http_clean({
        url: 'alertlog/queryAllTypeDeviceShutDownAlarmList.html',
        method: 'post',
        data: alertReq
      }).then(result => {
        //固件升级,系数升级信息
        http_clean({
          url: 'log/queryLogByDeviceId.html',
          method: 'post',
          data: logReq
        }).then(data => {
          // result.data 为数组
          let alertArr = result.data //警报
          // data.data 结构： {logForCoeList: [], UpgradeInfoList: []}
          let logArr = data.data //固件升级,系数升级
          let temResult = []
          // 将报警信息按照类别全部放入 temResult
          if (alertArr.length > 0) {
            for (let i = 0; i < alertArr.length; i++) {
              alertArr[i].flag = 'alert'
            }
            temResult = temResult.concat(alertArr)
          }
          if (logArr.logForCoeList.length > 0) {
            for (let i = 0; i < logArr.logForCoeList.length; i++) {
              logArr.logForCoeList[i].flag = 'coef'
            }
            temResult = temResult.concat(logArr.logForCoeList)
          }
          if (logArr.UpgradeInfoList.length > 0) {
            for (let i = 0; i < logArr.UpgradeInfoList.length; i++) {
              logArr.UpgradeInfoList[i].flag = 'version'
            }
            temResult = temResult.concat(logArr.UpgradeInfoList)
          }
          let len = temResult.length
          let offlineError = []
          if (len > 0) {
            for (let i = 0; i < len; i++) {
              let tim
              let sort = 0
              let item = temResult[i]
              // 根据不同类别获取时间 tim
              if (item.flag == 'alert') {
                tim = getMilliSecond(item.offTime)
              } else if (item.flag == 'coef') {
                tim = getMilliSecond(item.sendTime)
              } else if (item.flag == 'version') {
                tim = getMilliSecond(item.createTime)
              }
              for (let j = sort; j < chartState.allData.length; j++) {
                let _item = chartState.allData[j]
                let _tim = getMilliSecond(_item.uploadDate)
                if (_tim - tim >= 0 && j > 0) {
                  sort = j
                  let errorItem
                  if (item.type == 1 && item.flag == 'alert') {
                    errorItem = {
                      name: '离线警报',
                      value: '离',
                      xAxis: j - 1,
                      yAxis: chartState.allData[j - 1].weight,
                      lostime: '',
                      rangeTime: item.totalSecond ? convertTime(item.totalSecond) : '无',
                      symbol: 'image://chartImg/ofline_jb.png',
                      symbolSize: 14,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.type == 2 && item.flag == 'alert') {
                    errorItem = {
                      name: '断电警报',
                      value: '',
                      xAxis: j - 1,
                      yAxis: chartState.allData[j - 1].weight,
                      lostime: '',
                      rangeTime: item.totalSecond ? convertTime(item.totalSecond) : '无',
                      symbol: 'image://chartImg/powerOff2.png',
                      symbolSize: 14,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.flag == 'coef') {
                    errorItem = {
                      name: '系数设置',
                      value: '系',
                      xAxis: j - 1,
                      yAxis: chartState.allData[j - 1].weight,
                      lostime: '',
                      symbol: 'image://chartImg/version_icon.png',
                      symbolSize: 16,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.flag == 'version') {
                    errorItem = {
                      name: '固件升级',
                      value: '固',
                      xAxis: j - 1,
                      yAxis: chartState.allData[j - 1].weight,
                      lostime: '',
                      symbol: 'image://chartImg/version_icon.png',
                      symbolSize: 16,
                      symbolOffset: ['0', '-50%']
                    }
                  }
                  if (errorItem) {
                    for (let keys in item) {
                      errorItem[keys] = item[keys]
                    }
                    offlineError.push(errorItem)
                  }
                  break
                }
              }
            }
          }
          /**
           *   处理警报不在历史数据时间范围之内,但在搜索的时间范围.  将不在历史数据时间范围的数据,补打在历史数据最后时间点上.
           */
          for (let w = 0; w < alertArr.length; w++) {
            let itemAlert = alertArr[w]
            if (
              getMilliSecond(itemAlert.offTime) >
              getMilliSecond(chartState.allData[chartState.allData.length - 1].uploadDate)
            ) {
              let errorItem
              let index = alertArr.length - w
              if (itemAlert.type == 1 && itemAlert.flag == 'alert') {
                errorItem = {
                  name: '离线警报',
                  value: '离',
                  xAxis: chartState.allData.length - index,
                  yAxis: chartState.allData[chartState.allData.length - index].weight,
                  lostime: '',
                  rangeTime: itemAlert.totalSecond ? convertTime(itemAlert.totalSecond) : '离线中',
                  symbol: 'image://chartImg/ofline_jb.png',
                  symbolSize: 14,
                  symbolOffset: ['0', '-50%']
                }
              } else if (itemAlert.type == 2 && itemAlert.flag == 'alert') {
                errorItem = {
                  name: '断电警报',
                  value: '',
                  xAxis: chartState.allData.length - index,
                  yAxis: chartState.allData[chartState.allData.length - index].weight,
                  lostime: '',
                  rangeTime: itemAlert.totalSecond ? convertTime(itemAlert.totalSecond) : '断电中',
                  symbol: 'image://chartImg/powerOff2.png',
                  symbolSize: 14,
                  symbolOffset: ['0', '-50%']
                }
              }
              if (errorItem) {
                for (let keys in itemAlert) {
                  errorItem[keys] = itemAlert[keys]
                }
                offlineError.push(errorItem)
              }
            }
          }
          for (let i = 0; i < offlineError.length; i++) {
            let item = offlineError[i]
            let isFind = false
            for (let _i = 0; _i < lostWeightMark.length; _i++) {
              let iem = lostWeightMark[_i]
              if (iem.flag) {
                // 如果重量丢失和其他报警重合
                // 将重量丢失向后移动一位
                if (item.xAxis == iem.xAxis) {
                  //处理警报点重合问题
                  item.xAxis = item.xAxis + 1
                  item.lostime = iem.lostime
                  lostWeightMark.push(item)
                  isFind = true
                  break
                }
              } else {
                if ((item.xAxis < iem.xAxis + 1 && item.xAxis > iem.xAxis - 1) || item.xAxis == iem.xAxis) {
                  item.lostime = iem.lostime
                  lostWeightMark[_i] = item
                  isFind = true
                  break
                }
              }
            }
            if (!isFind) lostWeightMark.push(item)
          }
          chartState.passageData.push({
            name: '重量（吨）',
            type: 'line',
            borderWidth: 5,
            color: '#c23531',
            data: chartState.weightArr,
            markArea: {
              data: chartState.timeShadow
            },
            markPoint: {
              data: lostWeightMark,
              normal: {
                label: {
                  fontSize: 10
                }
              }
            }
          })
          if (isLostGps) {
            chartState.passageData.push({
              name: '',
              type: 'line',
              color: 'gray',
              lineStyle: {
                width: 3 //折线宽度
              },
              data: nullGpsArr,
              markPoint: {
                data: nullMarkerData
              }
            })
          }
          //时间轴
          chartOptions.value.xAxis.data = timeValue
          fullChartOptions.value.xAxis.data = timeValue
          //ad信号
          showAdSignal(chartState)
          showCarSpeed(chartState)
          showLoadometer(chartState)
          chartOptions.value.yAxis = [yAxiWeight, yAxiSignal, yAxiSpeed]
          chartOptions.value.series = chartState.passageData
          chartOptions.value.legend.selected = chartState.legendSleced

          fullChartOptions.value.yAxis = [yAxiWeight, yAxiSignal, yfullAxiSpeed]
          fullChartOptions.value.series = chartState.passageData
          fullChartOptions.value.legend.selected = chartState.legendSleced

          http_clean({
            url: 'realtime/getRealTimeDataForBase.html',
            method: 'post',
            data: { deviceId: deviceId }
          }).then(result => {
            if (result.data.unit == '2') {
              chartOptions.value.series[0].name = '重量（千克）'
              fullChartOptions.value.series[0].name = '重量（千克）'
            }
          })
        })
      })
    })
  }

  return {
    askForData,
    chartState,
    chartOptions,
    fullChartOptions
  }
}
function getLine(deviceId, state) {
  http_clean({
    url: 'system/getRealTimeData.html',
    method: 'post',
    data: {
      deviceId
    }
  }).then(result => {
    if (!result.data.coef) return
    for (let i = 0; i < result.data.coef.length; i++) {
      let item = result.data.coef[i]
      if (item) {
        let vname = '通道' + (i + 1)
        state.hideOrshowline.push(vname)
      }
    }
  })
}
//绘画ad信号折线,及丢失数据点.
function showAdSignal(state) {
  state.passageName = []
  state.passageArr = []
  const data = state.allData
  let len = data.length
  let wareStatusArr = [] //手刹信号
  //nullPassArr中对应的值为空的时候,从allPassArr移除对应的通道数据,最终得到filterArr过滤后的数据 passageArr
  const allPassArr = [] //16通道所有的数组
  const nullPassArr = [] //记录通道全部数据是否为空.,默认值为true
  for (let z = 0; z < 16; z++) {
    const newArr = []
    allPassArr.push(newArr)
    nullPassArr.push(true)
  }
  // i 当前数据索引
  for (let i = 0; i < len; i++) {
    const handSingal = parseFloat(data[i].handBrakeHardwareStatus) * 10 * state.multiple //手刹信号放大10万倍
    if (handSingal >= 0 || handSingal < 0) {
      wareStatusArr[i] = handSingal
    }
    // j 通道数
    for (let j = 0; j < 14; j++) {
      const name = 'aCH' + (j + 1)
      const passVal = parseFloat(data[i][name]) //获取16个通道的值;
      // 在allPassArr中按照通道数，依次放入数据
      allPassArr[j][i] = passVal
      // 只要有一项不为空，即认为有数据
      if (passVal > 0 || passVal < 0) {
        nullPassArr[j] = false
      }
    }
  }
  state.legendSleced = {}
  for (let _z = 0; _z < nullPassArr.length; _z++) {
    // 如果某个通道存在数据
    if (!nullPassArr[_z]) {
      let passName = '通道' + (_z + 1)
      state.passageName.push(passName)
      state.passageArr.push(allPassArr[_z])
      if (state.hideOrshowline.indexOf(passName) > -1) {
        state.legendSleced[passName] = true
      } else {
        state.legendSleced[passName] = false
      }
    }
  }
  if (wareStatusArr.length > 1) {
    state.passageName.push('手刹信号')
    state.passageArr.push(wareStatusArr)
  }

  for (let _i = 0; _i < state.passageName.length; _i++) {
    const obj = {
      name: state.passageName[_i],
      type: 'line',
      yAxisIndex: 1,
      color: lineGetColor(_i),
      lineStyle: {
        width: 2, //折线宽度
        opacity: 0.75
      },
      data: state.passageArr[_i]
    }
    if (state.passageName[_i] === '手刹信号') {
      obj.color = '#8B668B'
      obj.yAxisIndex = 0
    }
    state.passageData.push(obj)
  }
}
//绘画车辆速度折线
function showCarSpeed(state) {
  let obj = {
    name: '车辆速度',
    type: 'line',
    yAxisIndex: 2,
    normal: {
      color: '#5793f3',
      lineStyle: {
        width: 1, //折线宽度
        opacity: 0.5
      }
    },
    data: state.carSpeedArr,
    areaStyle: {
      color: 'rgba(83, 146, 230,0.5)'
    }
  }
  state.passageData.push(obj)
}
//获取地磅数据在chart图表显示.
function showLoadometer(state) {
  let orderIndex = 0
  for (let x = 0; x < state.loadometerData.length; x++) {
    let time1 = getMilliSecond(state.loadometerData[x].date)
    for (let i = orderIndex; i < state.allData.length; i++) {
      let time2 = getMilliSecond(state.allData[i].uploadDate)
      orderIndex++
      if (time2 - time1 >= 0) {
        let lmWeight = parseFloat(state.loadometerData[x].weight)
        loadometerVoluation(i - 1, lmWeight, state)
        break
      }
    }
  }
  if (state.loadometerData.length >= 1) {
    let obj = {
      name: '地磅数据',
      type: 'scatter',
      yAxisIndex: 0,
      color: 'orange',
      data: state.loadometerArr
    }
    state.passageData.push(obj)
  }
}
//地磅数据赋值到对应的折线数据位置上,若数据上有值则往后移
function loadometerVoluation(sort, weight, state) {
  if (sort + 1 <= state.loadometerArr.length) {
    if (state.loadometerArr[sort + 1] == '--') {
      state.loadometerArr[sort + 1] = weight
    } else {
      loadometerVoluation(sort + 1, weight, state)
    }
  }
}

//转换时间格式
function convertTime(timeInt) {
  let d, h, m
  if (timeInt >= 0) {
    d = Math.floor(timeInt / 60 / 60 / 24)
    h = Math.floor((timeInt / 60 / 60) % 24)
    m = Math.floor((timeInt / 60) % 60)
  }
  if (d < 1) {
    if (h < 1) {
      return '约' + m + '分'
    } else {
      return h + '小时' + m + '分'
    }
  } else {
    return d + '天' + h + '小时' + m + '分'
  }
}
