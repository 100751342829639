<template>
  <div class="cover-page">
    <NavBar />
    <div class="container">
      <van-cell-group>
        <!-- <van-field
          label="厂区类型"
          readonly
          placeholder="请选择厂区类型"
          v-model="searchOptions.companyTypeStr"
          @click="pickOperate(companyTypeList)"
        /> -->

        <van-field
          label="公司名称"
          readonly
          placeholder="请选择公司名称"
          v-model="searchOptions.companyName"
          @click="pickCompany"
        />
        <van-field
          label="车辆分类"
          readonly
          placeholder="请选择车辆分类"
          v-model="searchOptions.useTypeL2Str"
          @click="pickOperate(carTypeList)"
        />

        <van-field
          label="车辆号码"
          readonly
          placeholder="请选择车辆号码"
          v-model="searchOptions.carNumber"
          @click="pickCar"
        />
        <van-field
          label="问题分类"
          readonly
          placeholder="请选择问题分类"
          v-model="searchOptions.programTypeStr"
          @click="pickOperate(questionTypeList)"
        />

        <van-field
          label="处理状态"
          readonly
          placeholder="请选择处理状态"
          v-model="searchOptions.detailStatusStr"
          @click="pickOperate(detailStatusList)"
        />
        <van-field
          label="GPS状态"
          readonly
          placeholder="请选择GPS状态"
          v-model="searchOptions.isGpsErrorStr"
          @click="pickOperate(isGpsErrorList)"
        />

        <van-field
          label="订单状态"
          readonly
          placeholder="请选择订单状态"
          v-model="searchOptions.queryCheckStatusStr"
          @click="pickOperate(queryCheckStatusList)"
        />
        <van-field
          label="订单进度"
          readonly
          placeholder="请选择订单进度"
          v-model="searchOptions.orderStatus2Str"
          @click="pickOperate(orderStatus2List)"
        />

        <van-field
          label="算法"
          readonly
          placeholder="请选择算法"
          @click="pickOperate(isFixWeightList)"
          v-model="searchOptions.isFixWeightStr"
        />
        <van-field
          label="时间类型"
          readonly
          placeholder="请选择时间类型"
          @click="pickOperate(dateTypeList)"
          v-model="searchOptions.dateTypeStr"
        />

        <van-field
          label="起始时间"
          readonly
          placeholder="请选择起始时间"
          @click="pickTime('startTime')"
          v-model="searchOptions.startTime"
        />
        <van-field
          label="截止时间"
          readonly
          placeholder="请选择结束时间"
          @click="pickTime('endTime')"
          v-model="searchOptions.endTime"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" @click="submitOption">查询</van-button>
      </div>
    </div>
    <van-popup :show="isPicking" @click-overlay="isPicking = false" round position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="pickerColumns"
        @confirm="confirmPicker"
        @cancel="isPicking = false"
      />
    </van-popup>
    <van-popup :show="isPickTime" @click-overlay="isPickTime = false" round position="bottom">
      <van-datetime-picker
        v-model="pickedTime"
        type="datetime"
        title="选择时间"
        @confirm="confirmDate"
        @cancel="isPickTime = false"
      />
    </van-popup>
    <router-view v-slot="{ Component }">
      <transition :name="animationName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
import { toRefs, reactive, provide } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useAnimation } from '@/hooks/useAnimation'

import { carTypeList } from '@/utils/types'

import {
  dateTypeList,
  questionTypeList,
  detailStatusList,
  isGpsErrorList,
  queryCheckStatusList,
  orderStatus2List,
  isFixWeightList
} from './enumTypes'

export default {
  name: 'SingleQueryOptions',
  setup() {
    const store = useStore()
    const router = useRouter()
    const state = reactive({
      isPicking: false, // 是否展示 Picker
      pickerColumns: [], // Picker绑定的数据
      timeField: '', // 选择时间字段
      pickedTime: new Date(),
      isPickTime: false // 时间弹框选择
    })

    const { animationName } = useAnimation()

    const searchOptions = reactive({ ...store.state.search.queryOptions })
    provide('searchCompanyName', searchOptions.companyName)
    provide('searchCompanyId', searchOptions.companyId)

    const updateCompany = (companyName, companyId) => {
      searchOptions.companyName = companyName
      searchOptions.companyId = companyId
    }

    provide('updateCompany', updateCompany)

    // 选择公司
    const pickCompany = () => {
      router.push({
        name: 'QueryOptionCompany'
      })
    }
    // 展示类型选择弹框，根据传入数据修改picker的数据源
    const pickOperate = data => {
      state.pickerColumns = data
      state.isPicking = true
    }

    provide('searchCarNumber', searchOptions.carNumber)
    provide('searchDeviceId', searchOptions.deviceId)

    const updateCar = (number, deviceId) => {
      searchOptions.carNumber = number
      searchOptions.deviceId = deviceId
    }

    provide('updateCar', updateCar)
    // 选择车辆
    const pickCar = () => {
      router.push({
        name: 'QueryOptionCar'
      })
    }
    // 类型选择弹框的确认事件
    const confirmPicker = val => {
      const dataType = Array.isArray(val)
      val = dataType ? val : [val]
      state.isPicking = false
      state.pickerColumns = []
      for (const el of val) {
        // 获取 keyword，代表搜索项中使用字段
        const { keyword } = el
        searchOptions[keyword] = el.value
        // keyword + 'Str' 是展示用的字段
        searchOptions[keyword + 'Str'] = el.text
      }
    }

    const pickTime = field => {
      state.timeField = field
      state.isPickTime = true
    }

    const confirmDate = date => {
      const { timeField } = state
      searchOptions[timeField] = formate(date)
      state.isPickTime = false
    }

    const submitOption = () => {
      store.commit('search/updateOption', searchOptions)
      router.go(-1)
    }

    return {
      dateTypeList,
      questionTypeList,
      detailStatusList,
      isGpsErrorList,
      queryCheckStatusList,
      orderStatus2List,
      isFixWeightList,
      carTypeList,
      ...toRefs(state),
      animationName,
      searchOptions,
      pickCompany,
      pickOperate,
      pickCar,
      confirmPicker,
      submitOption,
      pickTime,
      confirmDate
    }
  }
}

// 对timePicker中选中date进行转换
function formate(time) {
  const date = new Date(time)
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  let day = date.getDate()
  day = day < 10 ? `0${day}` : day
  let hr = date.getHours()
  hr = hr < 10 ? `0${hr}` : hr
  let mins = date.getMinutes()
  mins = mins < 10 ? `0${mins}` : mins
  const timeStr = `${year}-${month}-${day} ${hr}:${mins}:00`
  return timeStr
}
</script>
<style lang="scss" scoped>
.edit__submit {
  margin-top: 12px;
}
.query-options {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  display: flex;
  flex-direction: column;
}
</style>
