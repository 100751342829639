import { reactive, ref, watch, onBeforeUnmount } from 'vue'
import { Toast } from 'vant'
import { http_clean } from '@/utils/request'
import { toBDMapPoint } from '@/utils/mapUtil'
import { lineGetColor, formatTimeStr, getMilliSecond, getTimeDiff } from '@/utils/common'
import { yAxiWeight, yAxiSignal, yAxiSpeed } from '@/utils/signalChartConst'
import stIcon from '@/assets/bMap/icon_stsrt.png'
import endIcon from '@/assets/bMap/icon_end.png'
import carIcon from '@/assets/bMap/map-indicator-60x60.png'

const chartModule = () => {
  let Vmap = null
  let movingMarker = null

  const initMap = () => {
    Vmap = new BMapGL.Map('device-map', { enableMapClick: false }, { minZoom: 6, maxZoom: 18 })
    Vmap.centerAndZoom(new BMapGL.Point(116.404, 39.915), 14)
    Vmap.enableScrollWheelZoom(true)
  }

  onBeforeUnmount(() => {
    Vmap && Vmap.destroy()
    Vmap = null
    movingMarker = null
  })
  //地图联动绘画轨迹路线.
  const showMapTrack = () => {
    let pos = chartState.linetoMapData
    const polylineList = [...pos].map(el => new BMapGL.Point(el.lng, el.lat))
    const posLen = pos.length
    Vmap.clearOverlays()
    if (pos.length < 1) return
    //绘制路线
    const pStart = new BMapGL.Marker(new BMapGL.Point(pos[0].lng, pos[0].lat), {
      offset: new BMapGL.Size(0, -10),
      icon: new BMapGL.Icon(stIcon, new BMapGL.Size(30, 30), {
        imageSize: new BMapGL.Size(30, 30)
      })
    })
    pStart.setZIndex(10)
    const pEnd = new BMapGL.Marker(new BMapGL.Point(pos[posLen - 1].lng, pos[posLen - 1].lat), {
      offset: new BMapGL.Size(0, -10),
      icon: new BMapGL.Icon(endIcon, new BMapGL.Size(30, 30), {
        imageSize: new BMapGL.Size(30, 30)
      })
    })
    const polyline = new BMapGL.Polyline(polylineList, {
      strokeColor: '#4C84F1',
      strokeWeight: 5,
      strokeOpacity: 1,
      fillOpacity: 0.5
    })
    Vmap.addOverlay(polyline)
    Vmap.addOverlay(pStart)
    Vmap.addOverlay(pEnd)
    setTimeout(() => {
      Vmap.setViewport(polylineList)
    }, 200)
  }
  const orderInfo = {
    deviceId: '',
    emptyLoadTime: '',
    lastDeviceTime: ''
  }
  const chartState = reactive({
    echartStartDate: '',
    echartEndDate: '',
    isRectifyWeight: false, //检查是否有人工数据修正
    ismWeight: false, //检查是否有算法修正
    processInfo: '加载中...',
    inTimeArr: [],
    outTimeArr: [],
    allData: [], //echart图后台请求回来的数据
    passageData: [], //所有chart图折线的数组
    linetoMapData: [], //地图联动画轨迹数据
    loadometerData: [], //后台返回的地磅数据
    loadometerArr: [], //拼接成echart数组
    weightArr: [], //重量折线的数据
    rectifyWeight: [], //修正重量折线的数据
    carSpeedArr: [], //速度折线的数据
    passageName: [], //ad信号折线名
    passageArr: [], //ad信号数据
    voltageData: [],
    filterTimeDefault: 0,
    timeShadow: [], //没有历史数据区域
    legendSleced: {},
    isChanel15: false, //是否显示15通道的终端重量
    signal15Arr: [], //15通道的数据
    isRate16: false, //是否计算16通道的误差率
    signal16Arr: [], //16通道的数据
    multiple: 1,
    hideOrshowline: []
  })
  const chartOption = reactive({
    apiType: '1', //api接口
    lookSignal: true, //是否查看信号的条件
    showApi: true, //api修正系数
    loadometer: true, //是否查地磅信息的条件
    carSpeed: true, //是否查看速度的条件
    showModify: false, //显示修正数据
    isShowRailChart: false, // 显示围栏
    isShowMap: false, //地图联动
    isShowQuestion: true // 是否显示下面的问题提交选项
  })

  //初始化echart图
  function initChart() {
    chartState.allData = [] //echart图后台请求回来的数据
    chartState.passageData = [] //所有chart图折线的数组
    chartState.linetoMapData = [] //地图联动画轨迹数据
    chartState.loadometerData = [] //后台返回的地磅数据
    chartState.loadometerArr = [] //拼接成echart数组
    chartState.weightArr = [] //重量折线的数据
    chartState.rectifyWeight = [] //修正重量折线的数据
    chartState.carSpeedArr = [] //速度折线的数据
    chartState.passageName = [] //ad信号折线名
    chartState.passageArr = [] //ad信号数据
    echartOption.value.xAxis.data = []
    echartOption.value.series = []
  }

  //生成echart折线图
  function showChart() {
    getLine(orderInfo.deviceId, chartState)
    askforData()
  }
  //后台获取数据
  async function askforData() {
    initChart()
    // getCompanyRail() //绘制厂区围栏
    chartState.isRectifyWeight = false //检查是否有人工数据修正
    chartState.ismWeight = false //检查是否有算法修正
    chartState.processInfo = '加载中...'
    chartState.inTimeArr = []
    chartState.outTimeArr = []
    let startTime = chartState.echartStartDate
    let endTime = chartState.echartEndDate
    chartState.loadometerData = []
    chartState.loadometerArr = []
    chartState.voltageData = []
    let params = {
      deviceId: orderInfo.deviceId,
      showModify: chartOption.showModify,
      showApi: chartOption.showApi,
      startTime,
      endTime
    }
    const result = await http_clean({
      url: 'weighbridge/weightforreal.html',
      method: 'post',
      data: params
    })
    if (result.data.length >= 1) {
      chartState.loadometerData = result.data
    }

    http_clean({
      url: 'logForVoltage/queryLogForVoltageByParam.html',
      method: 'post',
      data: params
    }).then(result => {
      if (result.data.length >= 1) {
        chartState.voltageData = result.data
      }
    })
    http_clean({
      url: 'orderController/getCarDataList.html',
      method: 'post',
      data: params
    }).then(data => {
      if (data.data.length < 2) {
        Toast('没有数据')
        chartState.processInfo = '没有数据'
        return
      }
      chartState.processInfo = ''

      // 如果有数据，赋值初始化echart
      chartState.allData = data.data
      chartState.linetoMapData = [] //地图联动轨迹画线数据
      chartState.carSpeedArr = [] //画速度折线数据
      let timeValue = []
      let len = chartState.allData.length
      // let lostWeight = [] //记录重量丢失的时间
      let lostWeightMark = [] //计算丢失重量的时长
      let nullGpsArr = [] //经纬度为空或者0.0的画折线数据
      let nullMarkerData = [] //计算经纬度丢失的时长
      let isLostGps = false //是否有丢失经纬度
      let isHavePoint = 0 //控制判断经纬度丢失起始结束位置计算时间条件
      /**
       * 计算采样周期,用于判断重量丢失的阈值
       * 取前10组历史数据,取中位数作为数据采样的频率
       */
      let temFilterArr = []
      chartState.filterTimeDefault = 0
      if (len > 10) {
        for (let i = 0; i < 10; i++) {
          // 转换时间为毫秒值，并计算时间差
          let diffTime = getTimeDiff(chartState.allData[i + 1].uploadDate, chartState.allData[i].uploadDate) / 1000
          temFilterArr.push(diffTime)
        }
        temFilterArr.sort(function (a, b) {
          return a - b
        })
        chartState.filterTimeDefault = Number(temFilterArr[6]) * 1.5
      }
      // 默认采样时间 150
      chartState.filterTimeDefault = chartState.filterTimeDefault > 150 ? chartState.filterTimeDefault : 150
      /**
       * 对筛选时间范围内,返回的历史数据做处理,对于数据缺失的时间段进行数据的补点,在echart对应显示灰色区域,表明该时间段范围内没有历史数据.
       */
      chartState.timeShadow = []
      if (getTimeDiff(chartState.allData[0].uploadDate, startTime) > chartState.filterTimeDefault * 1000) {
        // 判断曲线数据前有没有过磅数据,有过磅数据,显示无数据区域,没有过磅数据则不显示无数据区域
        let hasWeightInEmptyDataStartTime = false
        for (let i = 0; i < chartState.loadometerData.length; i++) {
          let item = chartState.loadometerData[i]
          if (
            getMilliSecond(startTime) < getMilliSecond(item.date) &&
            getMilliSecond(item.date) < getMilliSecond(chartState.allData[0].uploadDate)
          ) {
            hasWeightInEmptyDataStartTime = true
            break
          }
        }
        if (hasWeightInEmptyDataStartTime) {
          let emptyDataStartTime = [] //存放搜索开始时间空数据补点的集合
          for (
            let t = getMilliSecond(startTime);
            t < getMilliSecond(chartState.allData[0].uploadDate);
            t = t + chartState.filterTimeDefault * 1000
          ) {
            let temDate = formatTimeStr(new Date(t))
            let temObj = {
              aCH1: '',
              aCH2: '',
              aCH3: '',
              aCH4: '',
              aCH5: '',
              aCH6: '',
              aCH7: '',
              aCH8: '',
              aCH9: '',
              aCH10: '',
              aCH11: '',
              aCH12: '',
              aCH13: '',
              aCH14: '',
              aCH15: '',
              aCH16: '',
              checkGps: '',
              checkWeight: '',
              deviceId: orderInfo.deviceId,
              handBrakeHardwareStatus: '',
              handBrakeSoftwareStatus: '',
              mWeight: '',
              rectifyWeight: '',
              rectifyX: '',
              rectifyY: '',
              speed: '',
              uploadDate: temDate,
              weight: '',
              x: chartState.allData[0].x,
              y: chartState.allData[0].y
            }
            emptyDataStartTime.push(temObj)
          }

          let emptyShadowStart = [
            {
              name: '无数据',
              color: '#ddd',
              opacity: 0.3,
              label: {
                color: '#333',
                fontSize: '16',
                position: ['50%', '50%'],
                offset: [-24, -8]
              },
              emphasis: {
                color: '#ddd',
                opacity: 0.5,
                label: {
                  position: ['50%', '50%'],
                  offset: [-24, -8]
                }
              },
              xAxis: startTime
            },
            {
              xAxis: chartState.allData[0].uploadDate
            }
          ]
          chartState.timeShadow.push(emptyShadowStart)
          chartState.allData = emptyDataStartTime.concat(chartState.allData)
        }
      }
      len = chartState.allData.length
      let timeShadow = [] //进入围栏在echart显示的阴影区域
      if (chartOption.isShowRailChart) {
        for (let i = 0; i < chartState.inTimeArr.length; i++) {
          const dataArr = [
            {
              xAxis: chartState.inTimeArr[i]
            },
            {
              xAxis: chartState.outTimeArr[i]
            }
          ]
          timeShadow.push(dataArr)
        }
        chartState.timeShadow = chartState.timeShadow.concat(timeShadow)
      }
      for (let i = 0; i < len; i++) {
        if (!chartState.isRectifyWeight) {
          //判断是否存在人工数据修正
          if (chartState.allData[i].rectifyWeight + '') {
            chartState.isRectifyWeight = true
          }
        }
        if (
          orderInfo.emptyLoadTime < chartState.allData[i].uploadDate &&
          chartState.allData[i].uploadDate < orderInfo.lastDeviceTime
        ) {
          if (!chartState.ismWeight) {
            //判断是否存在算法修正
            if (chartState.allData[i].mWeight + '') {
              chartState.ismWeight = true
            }
          }
        }
        timeValue[i] = chartState.allData[i].uploadDate //x时间轴数据
        chartState.weightArr[i] = chartState.allData[i].weight ? chartState.allData[i].weight : '--' //重量集合
        chartState.rectifyWeight[i] = chartState.allData[i].rectifyWeight ? chartState.allData[i].rectifyWeight : '--' //修正重量集合

        let _speed = parseFloat(chartState.allData[i].speed) //速度
        if (_speed > -1) {
          chartState.carSpeedArr[i] = parseFloat(chartState.allData[i].speed)
        } else {
          chartState.carSpeedArr[i] = 0
        }
        chartState.loadometerArr[i] = '--'
        nullGpsArr[i] = '--'
        //计算经纬度等于0.0或者丢失的时候是时长start
        if (!chartState.allData[i] || !chartState.allData[i].x || chartState.allData[i].x == '0.0') {
          if (isHavePoint < 1) {
            isHavePoint = i
          }
        } else {
          if (isHavePoint > 0) {
            const lostTime =
              (getMilliSecond(chartState.allData[i].uploadDate) -
                getMilliSecond(chartState.allData[isHavePoint].uploadDate)) /
              1000
            if (lostTime > chartState.filterTimeDefault) {
              let point = {
                value: convertTime(lostTime),
                xAxis: isHavePoint,
                yAxis: chartState.allData[isHavePoint].weight,
                lostime: lostTime
              }
              nullMarkerData.push(point)
            }
            isHavePoint = 0
          }
        }
        //计算end
        if (i < len - 2) {
          //获取丢失的数据
          let lostStartime = getMilliSecond(chartState.allData[i].uploadDate)
          let lostEndtime = getMilliSecond(chartState.allData[i + 1].uploadDate)
          let lostime = (lostEndtime - lostStartime) / 1000
          if (lostime > 600) {
            //重量时间段丢失   阈值固定,10分钟以上显示
            let point = {
              value: convertTime(lostime),
              xAxis: i,
              yAxis: chartState.allData[i].weight,
              lostime: lostime
            }
            lostWeightMark.push(point)
          }
          if (!chartState.allData[i].x || chartState.allData[i].x == '0.0') {
            //经纬度坐标丢失
            if (i > 0) {
              isLostGps = true
              nullGpsArr[i] = chartState.allData[i].weight
            }
          }
        }

        const mapPoint = toBDMapPoint(chartState.allData[i])
        if (
          mapPoint &&
          mapPoint.lng &&
          mapPoint.lat &&
          mapPoint.lng !== '0.0' &&
          mapPoint.lat !== '0.0' &&
          mapPoint.lat != '0' &&
          mapPoint.lng != '0'
        ) {
          chartState.linetoMapData[i] = chartState.allData[i]
        }
      }
      //查询连续性为空的经纬度数据结束空的位置
      chartState.passageData = []
      //获取异常信息数据
      let alertReq = {
        deviceId: orderInfo.deviceId,
        startTime: startTime,
        endTime: endTime
      }
      let logReq = {
        deviceId: orderInfo.deviceId,
        startDate: startTime,
        endDate: endTime
      }

      //警报信息:离线警报,断电警报
      http_clean({
        url: 'alertlog/queryAllTypeDeviceShutDownAlarmList.html',
        method: 'post',
        data: alertReq
      }).then(result => {
        //固件升级,系数升级信息
        http_clean({
          url: 'log/queryLogByDeviceId.html',
          method: 'post',
          data: logReq
        }).then(data => {
          let alertArr = result.data //警报
          let logArr = data.data //固件升级,系数升级
          let temResult = []
          if (alertArr.length > 0) {
            for (let i = 0; i < alertArr.length; i++) {
              alertArr[i].flag = 'alert'
            }
            temResult = temResult.concat(alertArr)
          }
          if (logArr.logForCoeList.length > 0) {
            for (let i = 0; i < logArr.logForCoeList.length; i++) {
              logArr.logForCoeList[i].flag = 'coef'
            }
            temResult = temResult.concat(logArr.logForCoeList)
          }
          if (logArr.UpgradeInfoList.length > 0) {
            for (let i = 0; i < logArr.UpgradeInfoList.length; i++) {
              logArr.UpgradeInfoList[i].flag = 'version'
            }
            temResult = temResult.concat(logArr.UpgradeInfoList)
          }
          let len = temResult.length
          let offlineError = []
          if (len > 0) {
            for (let i = 0; i < len; i++) {
              let tim
              let sort = 0
              let item = temResult[i]
              let topos = toBDMapPoint({
                lng: item.lng,
                lat: item.lat
              })
              if (topos) {
                item.lng = topos.lng
                item.lat = topos.lat
              }
              if (item.flag == 'alert') {
                tim = getMilliSecond(item.offTime)
              } else if (item.flag == 'coef') {
                tim = getMilliSecond(item.sendTime)
              } else if (item.flag == 'version') {
                tim = getMilliSecond(item.createTime)
              }
              for (let j = sort; j < chartState.allData.length; j++) {
                let _item = chartState.allData[j]
                let _tim = getMilliSecond(_item.uploadDate)
                if (_tim - tim >= 0 && j > 0) {
                  sort = j
                  let errorItem
                  if (item.type == 1 && item.flag == 'alert') {
                    errorItem = {
                      name: '离线警报',
                      value: '离',
                      xAxis: j - 1,
                      yAxis: chartState.allData[j - 1].weight,
                      lostime: '',
                      rangeTime: item.totalSecond ? convertTime(item.totalSecond) : '无',
                      symbol: 'image://chartImg/ofline_jb.png',
                      symbolSize: 24,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.type == 2 && item.flag == 'alert') {
                    errorItem = {
                      name: '断电警报',
                      value: '',
                      xAxis: j - 1,
                      yAxis: chartState.allData[j - 1].weight,
                      lostime: '',
                      rangeTime: item.totalSecond ? convertTime(item.totalSecond) : '无',
                      symbol: 'image://chartImg/powerOff2.png',
                      symbolSize: 24,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.flag == 'coef') {
                    errorItem = {
                      name: '系数设置',
                      value: '系',
                      xAxis: j - 1,
                      yAxis: chartState.allData[j - 1].weight,
                      lostime: '',
                      symbol: 'image://chartImg/version_icon.png',
                      symbolSize: 26,
                      symbolOffset: ['0', '-50%']
                    }
                  } else if (item.flag == 'version') {
                    errorItem = {
                      name: '固件升级',
                      value: '固',
                      xAxis: j - 1,
                      yAxis: chartState.allData[j - 1].weight,
                      lostime: '',
                      symbol: 'image://chartImg/version_icon.png',
                      symbolSize: 26,
                      symbolOffset: ['0', '-50%']
                    }
                  }
                  if (errorItem) {
                    for (let keys in item) {
                      errorItem[keys] = item[keys]
                    }
                    offlineError.push(errorItem)
                  }
                  break
                }
              }
            }
          }
          /**
           *   处理警报不在历史数据时间范围之内,但在搜索的时间范围.  将不在历史数据时间范围的数据,补打在历史数据最后时间点上.
           */
          for (let w = 0; w < alertArr.length; w++) {
            let itemAlert = alertArr[w]
            if (
              getMilliSecond(itemAlert.offTime) >
              getMilliSecond(chartState.allData[chartState.allData.length - 1].uploadDate)
            ) {
              let errorItem
              let index = alertArr.length - w
              if (itemAlert.type == 1 && itemAlert.flag == 'alert') {
                errorItem = {
                  name: '离线警报',
                  value: '离',
                  xAxis: chartState.allData.length - index,
                  yAxis: chartState.allData[chartState.allData.length - index].weight,
                  lostime: '',
                  rangeTime: itemAlert.totalSecond ? convertTime(itemAlert.totalSecond) : '离线中',
                  symbol: 'image://chartImg/ofline_jb.png',
                  symbolSize: 24,
                  symbolOffset: ['0', '-50%']
                }
              } else if (itemAlert.type == 2 && itemAlert.flag == 'alert') {
                errorItem = {
                  name: '断电警报',
                  value: '',
                  xAxis: chartState.allData.length - index,
                  yAxis: chartState.allData[chartState.allData.length - index].weight,
                  lostime: '',
                  rangeTime: itemAlert.totalSecond ? convertTime(itemAlert.totalSecond) : '断电中',
                  symbol: 'image://chartImg/powerOff2.png',
                  symbolSize: 24,
                  symbolOffset: ['0', '-50%']
                }
              }
              if (errorItem) {
                for (let keys in itemAlert) {
                  errorItem[keys] = itemAlert[keys]
                }
                offlineError.push(errorItem)
              }
            }
          }
          for (let i = 0; i < offlineError.length; i++) {
            let item = offlineError[i]
            let isFind = false
            for (let _i = 0; _i < lostWeightMark.length; _i++) {
              let iem = lostWeightMark[_i]
              if (iem.flag) {
                if (item.xAxis == iem.xAxis) {
                  //处理警报点重合问题
                  item.xAxis = item.xAxis + 1
                  item.lostime = iem.lostime
                  lostWeightMark.push(item)
                  isFind = true
                  break
                }
              } else {
                if ((item.xAxis < iem.xAxis + 1 && item.xAxis > iem.xAxis - 1) || item.xAxis == iem.xAxis) {
                  item.lostime = iem.lostime
                  lostWeightMark[_i] = item
                  isFind = true
                  break
                }
              }
            }
            if (!isFind) lostWeightMark.push(item)
          }
          chartState.passageData.push({
            name: '重量（吨）',
            type: 'line',
            borderWidth: 5,
            color: '#c23531',
            data: chartState.weightArr,
            markArea: {
              data: chartState.timeShadow
            },
            markPoint: {
              data: lostWeightMark,
              normal: {
                label: {
                  fontSize: 10
                }
              }
            }
          })
          if (chartState.isRectifyWeight) {
            chartState.passageData.push({
              name: '人工修正',
              type: 'line',
              color: '#29a4e2',
              lineStyle: {
                width: 1 //折线宽度
                //									opacity: 0.75
              },
              data: chartState.rectifyWeight
            })
          }
          if (isLostGps) {
            chartState.passageData.push({
              name: '',
              type: 'line',
              color: 'gray',
              lineStyle: {
                width: 3 //折线宽度
              },
              data: nullGpsArr,
              markPoint: {
                data: nullMarkerData
              }
            })
          }
          //时间轴
          echartOption.value.xAxis.data = timeValue
          //ad信号
          showAdSignal(chartState, chartOption)
          if (chartOption.carSpeed) {
            yAxiSpeed.min = 0
            yAxiSpeed.max = 120
            showCarSpeed(chartState)
          }
          if (chartOption.loadometer) {
            showLoadometer(chartState)
          }
          yAxiSignal.show = chartOption.lookSignal
          yAxiSpeed.show = chartOption.carSpeed
          echartOption.value.yAxis = [yAxiWeight, yAxiSignal, yAxiSpeed]
          echartOption.value.series = chartState.passageData
          chartState.legendSleced['手刹信号'] = false
          chartState.legendSleced['人工修正'] = false
          echartOption.value.legend.selected = chartState.legendSleced

          http_clean({
            url: 'realtime/getRealTimeDataForBase.html',
            method: 'post',
            data: { deviceId: orderInfo.deviceId }
          }).then(result => {
            if (result.data.unit == '2') {
              echartOption.value.series[0].name = '重量（千克）'
            }
          })
        })
      })
    })
  }

  // 根据 AD信号选中状态，重画chart
  watch(
    () => chartOption.lookSignal,
    () => {
      signalChange()
    }
  )

  //ad信号选中画信号折线
  const signalChange = () => {
    let len = chartState.passageArr.length
    if (len >= 1) {
      if (chartOption.lookSignal) {
        if (chartState.isRate16) {
          for (let k = 0; k < chartState.passageName.length; k++) {
            if (chartState.passageName[k] == '算法重量') {
              chartState.passageName.splice(k, 1)
              chartState.passageArr.splice(k, 1)
            }
          }
        }
        if (chartState.isChanel15) {
          for (let k = 0; k < chartState.passageName.length; k++) {
            if (chartState.passageName[k] == '终端重量') {
              chartState.passageName.splice(k, 1)
              chartState.passageArr.splice(k, 1)
            }
          }
        }
        if (chartState.isRate16) {
          let aCH16Info = {
            name: '算法重量',
            type: 'line',
            yAxisIndex: 0,
            color: '#459818',
            lineStyle: {
              width: 2, //折线宽度
              opacity: 0.75
            },
            data: chartState.signal16Arr
          }
          chartState.passageData.push(aCH16Info)
        }
        if (chartState.isChanel15) {
          let aCH15Info = {
            name: '终端重量',
            type: 'line',
            yAxisIndex: 0,
            color: 'orange',
            lineStyle: {
              width: 2, //折线宽度
              opacity: 0.75
            },
            data: chartState.signal15Arr
          }
          chartState.passageData.push(aCH15Info)
        }
        for (let _i = 0; _i < chartState.passageName.length; _i++) {
          const obj = {
            name: chartState.passageName[_i],
            type: 'line',
            yAxisIndex: 1,
            color: lineGetColor(_i),
            lineStyle: {
              width: 2, //折线宽度
              opacity: 0.75
            },
            data: chartState.passageArr[_i]
          }
          if (chartState.passageName[_i] === '手刹信号') {
            obj.color = '#8B668B'
            obj.yAxisIndex = 0
          }
          chartState.passageData.push(obj)
        }
      } else {
        let _len = chartState.passageData.length
        for (let _i = 0; _i < _len; _i++) {
          if (chartState.passageData[_i].name == '算法重量' || chartState.passageData[_i].name == '终端重量') {
            chartState.passageData.splice(_i, 1)
            _len = _len - 1
            _i = _i - 1
          }
          if (chartState.passageName[_i] == '算法重量' || chartState.passageName[_i] == '终端重量') {
            chartState.passageName.splice(_i, 1)
            _len = _len - 1
            _i = _i - 1
          }
          let findIndex = chartState.passageName.indexOf(chartState.passageData[_i].name)
          if (findIndex > -1) {
            chartState.passageData.splice(_i, 1)
            _len = _len - 1
            _i = _i - 1
          }
        }
      }
      yAxiSignal.show = chartOption.lookSignal
      yAxiSpeed.show = chartOption.carSpeed
      echartOption.value.yAxis = [yAxiWeight, yAxiSignal, yAxiSpeed]
      echartOption.value.series = chartState.passageData
    }
  }

  //api修正, 修正数据
  watch(
    () => [chartOption.showApi, chartOption.showModify],
    () => {
      showChart()
    }
  )

  //地磅数据选中画折线
  watch(
    () => chartOption.loadometer,
    () => {
      loadometerChange()
    }
  )
  const loadometerChange = () => {
    if (chartState.allData.length < 2) {
      return
    }
    if (chartState.loadometerData.length >= 1) {
      if (chartOption.loadometer) {
        showLoadometer(chartState)
      } else {
        let len = chartState.passageData.length
        for (let i = 0; i < len; i++) {
          if (chartState.passageData[i].name === '地磅数据') {
            chartState.passageData.splice(i, 1)
            break
          }
        }
      }
      echartOption.value.series = chartState.passageData
    } else {
      if (chartOption.loadometer) {
        Toast('该时间段没有地磅数据')
      }
    }
  }
  //车辆速度选中画折线
  watch(
    () => chartOption.carSpeed,
    () => {
      carSpeedChange()
    }
  )
  const carSpeedChange = () => {
    if (chartState.carSpeedArr.length >= 1) {
      if (chartOption.carSpeed) {
        yAxiSpeed.min = 0
        yAxiSpeed.max = 120
        showCarSpeed(chartState)
      } else {
        yAxiSpeed.min = undefined
        yAxiSpeed.max = undefined
        let len = chartState.passageData.length
        for (let i = 0; i < len; i++) {
          if (chartState.passageData[i].name === '车辆速度') {
            chartState.passageData.splice(i, 1)
            break
          }
        }
      }
      yAxiSignal.show = chartOption.lookSignal
      yAxiSpeed.show = chartOption.carSpeed
      echartOption.value.yAxis = [yAxiWeight, yAxiSignal, yAxiSpeed]
      echartOption.value.series = chartState.passageData
    }
  }
  //	指定图表的配置项和数据
  const echartOption = ref({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross' //十字星
      },
      formatter: function (params) {
        const paramIndex = params[0].dataIndex
        const pointObj = chartState.allData[paramIndex]
        if (pointObj.lat && pointObj.lng) {
          resetMoveInfoBox(pointObj)
        }
        let res = ''
        const len = params.length
        for (let i = 0; i < len; i++) {
          res = res + params[i].marker + params[i].seriesName + ':' + params[i].data + '</br>'
        }
        return res
      }
    },
    grid: {
      left: '8%',
      right: '24%',
      top: '40%',
      bottom: '16%'
    },

    legend: {
      selected: {}
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: [yAxiWeight],

    series: [
      {
        name: '重量',
        type: 'line',
        data: []
      }
    ]
  })

  //移动折线图联动地图移动点
  function resetMoveInfoBox(params) {
    const point = new BMapGL.Point(params.lng, params.lat)
    const icon = new BMapGL.Icon(carIcon, new BMapGL.Size(60, 60), {
      imageSize: new BMapGL.Size(60, 60)
    })
    if (!movingMarker) {
      movingMarker = new BMapGL.Marker(point, {
        icon: icon
      })
      Vmap.addOverlay(movingMarker)
    }
    movingMarker.setPosition(point)
  }
  const showMapCk = () => {
    // 清空地图标记
    movingMarker = null
    showMapTrack()
  }
  return {
    echartOption,
    orderInfo,
    chartState,
    chartOption,
    showChart,
    signalChange,
    initMap,
    showMapCk
  }
}
export default chartModule

//转换时间格式
function convertTime(timeInt) {
  let d, h, m
  if (timeInt >= 0) {
    d = Math.floor(timeInt / 60 / 60 / 24)
    h = Math.floor((timeInt / 60 / 60) % 24)
    m = Math.floor((timeInt / 60) % 60)
  }
  if (d < 1) {
    if (h < 1) {
      return '约' + m + '分'
    } else {
      return h + '小时' + m + '分'
    }
  } else {
    return d + '天' + h + '小时' + m + '分'
  }
}

//绘画ad信号折线,及丢失数据点.
function showAdSignal(state, chartOption) {
  state.passageName = []
  state.passageArr = []
  state.signal16Arr = []
  const data = state.allData
  let len = data.length
  let wareStatusArr = [] //手刹信号
  //nullPassArr中对应的值为空的时候,从allPassArr移除对应的通道数据,最终得到filterArr过滤后的数据 passageArr
  const allPassArr = [] //16通道所有的数组
  const nullPassArr = [] //记录通道全部数据是否为空.,默认值为true
  for (let z = 0; z < 16; z++) {
    const newArr = []
    allPassArr.push(newArr)
    nullPassArr.push(true)
  }
  for (let i = 0; i < len; i++) {
    const handSingal = parseFloat(data[i].handBrakeHardwareStatus) * 10 * state.multiple //手刹信号放大10万倍
    if (handSingal >= 0 || handSingal < 0) {
      wareStatusArr[i] = handSingal
    }
    for (let j = 0; j < 16; j++) {
      const name = 'aCH' + (j + 1)
      const passVal = parseFloat(data[i][name]) //获取16个通道的值;
      allPassArr[j][i] = passVal
      if (passVal > 0 || passVal < 0) {
        nullPassArr[j] = false
      }
    }
  }
  state.legendSleced = {}
  for (let _z = 0; _z < nullPassArr.length; _z++) {
    if (!nullPassArr[_z]) {
      let passName
      _z == 14 ? (passName = '终端重量') : _z == 15 ? (passName = '算法重量') : (passName = '通道' + (_z + 1))
      state.passageName.push(passName)
      state.passageArr.push(allPassArr[_z])
      if (state.hideOrshowline.indexOf(passName) > -1) {
        state.legendSleced[passName] = true
      } else {
        if (state.passageName.indexOf('算法重量') > -1 || state.passageName.indexOf('终端重量') > -1) {
          state.legendSleced[passName] = false
        } else {
          state.legendSleced[passName] = false
        }
      }
    }
  }
  if (!nullPassArr[15]) {
    // 当16通道的值不为空时候
    state.signal16Arr = allPassArr[15]
    state.isRate16 = true
  } else {
    state.isRate16 = false
  }
  if (!nullPassArr[14]) {
    state.signal15Arr = allPassArr[14]
    state.isChanel15 = true
  } else {
    state.isChanel15 = false
  }
  if (wareStatusArr.length > 1) {
    state.passageName.push('手刹信号')
    state.passageArr.push(wareStatusArr)
  }
  if (chartOption.lookSignal) {
    if (state.isRate16) {
      for (let k = 0; k < state.passageName.length; k++) {
        if (state.passageName[k] == '算法重量') {
          state.passageName.splice(k, 1)
          state.passageArr.splice(k, 1)
        }
      }
    }
    if (state.isChanel15) {
      for (let k = 0; k < state.passageName.length; k++) {
        if (state.passageName[k] == '终端重量') {
          state.passageName.splice(k, 1)
          state.passageArr.splice(k, 1)
        }
      }
    }
    if (state.isRate16) {
      const aCH16Info = {
        name: '算法重量',
        type: 'line',
        yAxisIndex: 0,
        color: '#459818',
        lineStyle: {
          width: 2, //折线宽度
          opacity: 0.75
        },
        data: state.signal16Arr
      }
      state.passageData.push(aCH16Info)
    }
    if (state.isChanel15) {
      const aCH15Info = {
        name: '终端重量',
        type: 'line',
        yAxisIndex: 0,
        color: 'orange',
        lineStyle: {
          width: 2, //折线宽度
          opacity: 0.75
        },
        data: state.signal15Arr
      }
      state.passageData.push(aCH15Info)
    }
    for (let _i = 0; _i < state.passageName.length; _i++) {
      const obj = {
        name: state.passageName[_i],
        type: 'line',
        yAxisIndex: 1,
        color: lineGetColor(_i),
        lineStyle: {
          width: 2, //折线宽度
          opacity: 0.75
        },
        data: state.passageArr[_i]
      }
      if (state.passageName[_i] === '手刹信号') {
        obj.color = '#8B668B'
        obj.yAxisIndex = 0
      }
      state.passageData.push(obj)
    }
  }
}
//绘画车辆速度折线
function showCarSpeed(state) {
  let obj = {
    name: '车辆速度',
    type: 'line',
    yAxisIndex: 2,
    normal: {
      color: '#5793f3',
      lineStyle: {
        width: 1, //折线宽度
        opacity: 0.5
      }
    },
    data: state.carSpeedArr,
    areaStyle: {
      color: 'rgba(83, 146, 230,0.5)'
    }
  }
  state.passageData.push(obj)
}
//获取地磅数据在chart图表显示.
function showLoadometer(state) {
  let orderIndex = 0
  for (let x = 0; x < state.loadometerData.length; x++) {
    let time1 = getMilliSecond(state.loadometerData[x].date)
    for (let i = orderIndex; i < state.allData.length; i++) {
      let time2 = getMilliSecond(state.allData[i].uploadDate)
      orderIndex++
      if (time2 - time1 >= 0) {
        let lmWeight = parseFloat(state.loadometerData[x].weight)
        loadometerVoluation(i - 1, lmWeight, state)
        break
      }
    }
  }
  if (state.loadometerData.length >= 1) {
    let obj = {
      name: '地磅数据',
      type: 'scatter',
      yAxisIndex: 0,
      color: 'orange',
      data: state.loadometerArr
    }
    state.passageData.push(obj)
  }
}
//地磅数据赋值到对应的折线数据位置上,若数据上有值则往后移
function loadometerVoluation(sort, weight, state) {
  if (sort + 1 <= state.loadometerArr.length) {
    if (state.loadometerArr[sort + 1] == '--') {
      state.loadometerArr[sort + 1] = weight
    } else {
      loadometerVoluation(sort + 1, weight, state)
    }
  }
}

function getLine(deviceId, state) {
  http_clean({
    url: 'system/getRealTimeData.html',
    method: 'post',
    data: {
      deviceId
    }
  }).then(result => {
    for (let i = 0; i < result.data.coef.length; i++) {
      let item = result.data.coef[i]
      if (item) {
        let vname = '通道' + (i + 1)
        state.hideOrshowline.push(vname)
      }
    }
  })
}
