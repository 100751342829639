<template>
  <NavBar>
    <template #right>
      <div v-if="!loseInfo && !isPending">
        <van-icon name="ellipsis" />
      </div>
    </template>
    <template #extension>
      <div class="extension">
        <div @click="resetId">重置ID</div>
        <div @click="clipText">一键复制</div>
        <div @click="queryOrders">订单查询</div>
        <div @click="deviceRepair">维修查询</div>
        <div @click="editCar">修改车牌</div>
        <div @click="remoteClear">远程清零</div>
        <div @click="signalText">信噪比</div>
      </div>
    </template>
  </NavBar>
  <div class="container">
    <!-- 加载中，骨架屏 -->
    <div v-if="isPending" class="pending">
      <van-skeleton title :row="6" />
      <van-skeleton title :row="3" />
      <van-skeleton title :row="3" />
    </div>

    <div class="results" v-show="!loseInfo && !isPending">
      <!-- 头部Tabs -->
      <div class="results__navbar line">
        <div
          @click="resultTabClick(1)"
          :class="['results__navbar__tab', { results__navbar__active: resultActive === 1 }]"
        >
          <p>车辆信息</p>
        </div>
        <div
          @click="resultTabClick(2)"
          :class="['results__navbar__tab', { results__navbar__active: resultActive === 2 }]"
        >
          <p>运行信息</p>
        </div>
      </div>
      <!-- 头部Tabs结束 -->

      <div class="swiper-content" ref="swiper">
        <!-- 车辆信息section -->
        <div
          :class="['results__car-info', 'swiper__item', { 'is-active': resultActive === 1 }]"
          v-show="resultActive === 1"
        >
          <base-info
            ref="infoRef"
            @restrict="dialogRestrict"
            :start="queryStrTime"
            :end="queryEndTime"
            @update-pending="pendingCb"
            @gps-problem="listGPSProblem"
            @sensor-problem="listSensorProblem"
            @get-car-type="updateType"
          ></base-info>
        </div>
        <!-- 车辆信息section end -->
        <!-- 运行信息section -->
        <div :class="['results__run swiper__item', { 'is-active': resultActive === 2 }]">
          <div class="line">
            <!-- 时间选择 -->
            <div class="results__run__search flex-spa">
              <span class="results__run__search__notice">时间段</span>
              <input
                type="text"
                readonly
                class="results__run__start"
                v-model="startTime"
                @click="selectTime(startTime, true)"
              />
              -
              <input
                type="text"
                readonly
                class="results__run__end"
                v-model="endTime"
                @click="selectTime(endTime, false)"
              />
              <van-button class="results__run__search__btn" type="primary" size="mini" @click="queryData">
                查询
              </van-button>
            </div>
            <!-- 时间选择end -->
            <!-- 快捷时间选项 -->
            <div class="results__run__quicker">
              <p :style="quickSelected === 7 ? 'color:#1989fa' : 'color:#777'" @click="quickSelect(7)">
                <van-icon name="clock-o" />
                7天
              </p>
              <p :style="quickSelected === 3 ? 'color:#1989fa' : 'color:#777'" @click="quickSelect(3)">
                <van-icon name="clock-o" />
                3天
              </p>
              <p :style="quickSelected === 1 ? 'color:#1989fa' : 'color:#777'" @click="quickSelect(1)">
                <van-icon name="clock-o" />
                1天
              </p>
            </div>
            <!-- 快捷时间选项end -->
          </div>
          <div class="results__chart">
            <div class="chart-empty position-absolute">{{ chartState.processInfo }}</div>
            <!-- echart -->
            <chart-view height="320px" :chartOption="chartOptions" v-if="chartLoaded"></chart-view>
            <van-image
              width="32"
              height="32"
              :src="fullPicUrl"
              class="full-pic position-absolute"
              @click="fullClickHandler"
            />

            <div class="full" :style="{ height: fullWidth, width: fullHeight }" v-show="isFull && resultActive === 2">
              <chart-view
                v-show="isFull && resultActive === 2"
                :class="['full-screen', { 'hidden-style': !isFull }]"
                :chartOption="fullChartOptions"
                :height="fullWidth"
                ref="fullScreenRef"
              ></chart-view>
            </div>
          </div>
          <div class="garbage" v-if="carType === '02'" @click="checkGarbage">
            <div class="garbage__title">单次称重明细</div>
            <div class="garbage__notice">
              <span class="garbage__notice__title">查看</span>
              <van-icon name="arrow" />
            </div>
          </div>
        </div>
        <!-- 运行信息section end -->
      </div>

      <!-- 选择时间 -->
      <van-popup
        :show="datePickerIsShow"
        position="bottom"
        round
        style="height: '46%'"
        @click-overlay="datePickerIsShow = false"
      >
        <van-datetime-picker
          v-model="selectedDate"
          type="datetime"
          title="选择时间"
          @confirm="pickTime"
          @cancel="datePickerIsShow = false"
        />
      </van-popup>
      <!-- 选择时间 end  -->
    </div>
    <!-- 空内容展示 -->
    <div v-if="loseInfo && !isPending" class="container empty">
      <van-empty description="没有车辆记录" />
    </div>
    <router-view v-slot="{ Component }">
      <transition :name="animationName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>

  <van-dialog
    v-model:show="showRestrict"
    title="是否限制进场？"
    show-cancel-button
    confirm-button-color="#1989fa"
    @confirm="confirmRestrict"
  >
    <div class="restrictGroup">
      <van-radio-group v-model="restrictChecked">
        <div class="restrictOption">
          <van-radio name="0">不限制</van-radio>
        </div>
        <div class="restrictOption">
          <van-radio name="1">限制</van-radio>
        </div>
        <div class="restrictHour">
          <van-radio name="2">自定义限制(默认6hr)</van-radio>
          <input type="text" v-model="restrictTime" @touchstart.stop="numInputClick" readonly />
        </div>
      </van-radio-group>
    </div>
  </van-dialog>
  <van-number-keyboard
    v-model="restrictTime"
    :show="numInputShow"
    :maxlength="6"
    @blur="numInputShow = false"
    style="z-index: 9999"
  />

  <van-image
    v-show="isFull && resultActive === 2"
    width="32"
    height="32"
    :src="exitFullPicUrl"
    class="exit-full-pic position-absolute"
    @click="exitFullHandler"
  />
</template>
<script>
import { ref, reactive, toRefs, provide, onMounted, computed, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { Toast, Dialog } from 'vant'
import copy from 'copy-to-clipboard'
import ChartView from '@/components/echart/index.vue'
import BaseInfo from './components/BaseInfo.vue'
import http from '@/utils/request'
import { formatTimeStr, decreaseDate } from '@/utils/common'
import printChart from './printChart'
import fullPicUrl from '@/assets/full_screen.png'
import exitFullPicUrl from '@/assets/exit_full_screen.png'

import { useAnimation } from '@/hooks/useAnimation'

function useFullScreen() {
  const store = useStore()
  const isFull = ref(false)
  // 全屏的元素
  const fullScreenRef = ref()
  // const fullHeight = ref(document.documentElement.clientHeight + 'px')
  // const fullWidth = ref(document.documentElement.clientWidth + 'px')
  const fullHeight = computed(() => store.state.screenHeight + 'px')
  const fullWidth = computed(() => store.state.screenWidth + 'px')

  async function fullClickHandler() {
    isFull.value = true
    await nextTick()
    fullScreenRef.value.resizeChart()
  }

  function exitFullHandler() {
    isFull.value = false
  }
  return {
    isFull,
    fullHeight,
    fullWidth,
    fullScreenRef,
    fullClickHandler,
    exitFullHandler
  }
}

function useOnceGarbage() {
  const carType = ref('')
  const deviceId = ref('')

  const router = useRouter()

  const updateType = type => {
    carType.value = type.useTypeL1
    deviceId.value = type.deviceId
  }

  const checkGarbage = () => {
    router.push({
      name: 'OnceGarbage',
      params: {
        device: deviceId.value
      }
    })
  }

  return {
    carType,
    updateType,
    checkGarbage
  }
}

export default {
  components: {
    ChartView,
    BaseInfo,
    [Dialog.Component.name]: Dialog.Component
  },
  setup() {
    const store = useStore() //引入vuex
    const route = useRoute()
    const router = useRouter()
    const swiper = ref(null)
    const state = reactive({
      resultActive: 1, // 当前展示的内容页
      showRestrict: false, // 展示选择限制弹框
      restrictTime: '6',
      restrictChecked: '',
      numInputShow: false,
      isPending: true, // 等待车辆基本信息返回，如果没有记录显示空状态
      loseInfo: false, // 是否无记录
      chartLoaded: false // 记录 echart 是否加载完成
    })

    // 是否使用动画
    const { animationName } = useAnimation()

    //展示的车辆基本信息
    const infoRef = ref()
    const { isFull, fullHeight, fullWidth, fullScreenRef, fullClickHandler, exitFullHandler } = useFullScreen()

    onMounted(() => {
      route.meta.title = store.state.search.carNumber
    })

    watch(
      () => route.name,
      val => {
        // 动态修改页面 title
        if (val === 'SearchResult') {
          route.meta.title = store.state.search.carNumber
          infoRef.value.initData()
        }
      }
    )
    // 时间选择状态
    const timeState = reactive({
      quickSelected: 1, // 快速选择选中项
      datePickerIsShow: false, // 时间选择弹出框是否展示
      selectedDate: new Date(), // 时间选择弹出框绑定值
      isStart: true // 是否是选择开始时间
    })
    // 初始化默认搜索时间
    const { startTime, endTime } = initSearch() //初始化开始，结束时间，默认结束为当前时间，开始为前一天

    // 防止页面刷新丢失数据
    // 获取上个页面传递过来的数据，保存至本地，防止每次刷新页面获取不到报错，就很烦
    const isSaved = localStorage.getItem('tempSearch')
    // 初次进入保存数据
    if (!isSaved) {
      localStorage.setItem('tempSearch', JSON.stringify(store.state.search))
    }
    // 已保存直接获取
    const saveData = JSON.parse(localStorage.getItem('tempSearch'))

    // 初始化 echart 模块
    const chartModule = printChart(saveData.deviceId)
    // 保存 echart 数据，以及重绘的方法
    const { askForData, chartOptions, fullChartOptions, chartState } = chartModule
    const currentDate = new Date()
    const queryStrTime = formatTimeStr(currentDate - 24 * 60 * 60 * 1000)
    const queryEndTime = formatTimeStr(currentDate)

    // 隐藏骨架屏
    const pendingCb = value => {
      state.isPending = value.isPending
      state.loseInfo = value.loseInfo
    }
    //tab点击事件
    const resultTabClick = n => {
      state.resultActive = n
      // 点击 echart tab
      if (n === 2) {
        if (!state.chartLoaded) askForData(startTime.value, endTime.value)
        state.chartLoaded = true
      }
    }
    //快速选择时间段
    const quickSelect = days => {
      timeState.quickSelected = days
      startTime.value = decreaseDate('days', days, endTime.value)
      queryData()
    }
    //运行信息查询事件
    const queryData = () => {
      if (new Date(startTime.value).getTime() > new Date(endTime.value).getTime()) {
        Toast('开始时间不能晚于结束时间')
        return
      }
      const start = formatTimeStr(startTime.value)
      const end = formatTimeStr(endTime.value)
      askForData(start, end)
    }

    // 事件选择弹出事件
    const selectTime = (date, flag) => {
      timeState.datePickerIsShow = true
      timeState.selectedDate = new Date(date)
      timeState.isStart = flag
    }

    // 时间弹框确认选中事件
    const pickTime = date => {
      // 判断当前选择的是否为开始时间
      if (timeState.isStart) {
        startTime.value = formatTimeStr(date)
        timeState.datePickerIsShow = false
        return
      }
      endTime.value = formatTimeStr(date)
      timeState.datePickerIsShow = false
    }
    // 限制进场
    const dialogRestrict = () => {
      state.showRestrict = true
    }

    const numInputClick = () => {
      state.restrictChecked = '2'
      state.numInputShow = true
    }
    // 限制进厂确定操作
    const confirmRestrict = () => {
      const ids = infoRef.value.servicePass.repaired.id
      const isPush = state.restrictChecked === '1' ? '1' : '0'
      const params = {
        ids,
        isPush
      }
      if (state.restrictChecked === '2') {
        params.time = +state.restrictTime
      }
      http({
        url: 'deviceRepaired/enterFactoryLimitBatch.html',
        method: 'post',
        data: params
      }).then(async () => {
        state.restrictTime = '6'
        infoRef.value.initData()
      })
    }

    let GPSProblems = ref(null)
    provide('GPSProblems', GPSProblems)
    const listGPSProblem = problems => {
      GPSProblems.value = problems
      router.push({
        name: 'GPSList',
        query: {
          title: store.state.search.carNumber
        }
      })
    }

    let SensorProblems = ref(null)
    provide('SensorProblems', SensorProblems)
    const listSensorProblem = problems => {
      SensorProblems.value = problems
      router.push({
        name: 'SensorList',
        query: {
          title: store.state.search.carNumber
        }
      })
    }

    /*  导航栏头部功能   */

    // 重置ID
    const resetId = () => {
      setTimeout(() => {
        router.push({
          name: 'ResetID'
        })
      }, 100)
    }
    // 一键复制
    const clipText = () => {
      let content = `车牌：${store.state.search.carNumber}
号码：${store.state.search.phone}
更新时间：${store.state.search.carUploadDate}
设备状态:${store.state.search.runStatus === 1 ? '在线' : '离线'}`
      copy(content)
      Toast('复制成功')
    }

    // 订单查询
    const queryOrders = () => {
      setTimeout(() => {
        router.push({
          name: 'QueryOrder'
        })
      }, 100)
    }
    // 修改车牌
    const editCar = () => {
      setTimeout(() => {
        router.push({
          name: 'SearchEditNumber',
          query: {
            isEdit: 1
          }
        })
      }, 100)
    }

    // 远程清零
    const remoteClear = () => {
      Dialog.confirm({
        title: '提示',
        message: '是否清零',
        confirmButtonColor: '#1989fa'
      })
        .then(() => {
          const { deviceId } = saveData
          const param = {
            isAuto: 0,
            protocolVersion: 2,
            deviceId
          }
          http({
            url: 'system/remotereset.html',
            method: 'post',
            data: param
          }).then(res => {
            if (res.success)
              setTimeout(() => {
                Toast(res.message)
              }, 500)
          })
        })
        .catch(() => {})
    }
    // 信噪比
    const signalText = () => {
      if (saveData.runStatus !== '1') {
        Toast('离线状态的设备不能进行信噪比检测')
        return
      }
      setTimeout(() => {
        router.push({
          name: 'SignalTest'
        })
      }, 100)
    }
    // 维修查询
    const deviceRepair = () => {
      setTimeout(() => {
        router.push({
          name: 'DeviceRepair'
        })
      }, 100)
    }

    const { carType, updateType, checkGarbage } = useOnceGarbage()

    return {
      carType,
      updateType,
      checkGarbage,
      animationName,
      infoRef,
      queryStrTime,
      queryEndTime,
      chartOptions,
      fullChartOptions,
      chartState,
      listGPSProblem,
      listSensorProblem,
      pendingCb,
      ...toRefs(state),
      ...toRefs(timeState),
      dialogRestrict,
      numInputClick,
      confirmRestrict,
      swiper,
      resultTabClick,
      startTime,
      endTime,
      selectTime,
      pickTime,
      quickSelect,
      queryData,
      clipText,
      editCar,
      queryOrders,
      remoteClear,
      resetId,
      signalText,
      deviceRepair,
      isFull,
      fullHeight,
      fullWidth,
      fullScreenRef,
      fullClickHandler,
      fullPicUrl,
      exitFullPicUrl,
      exitFullHandler
    }
  }
}
//初始化搜索框内容，默认开始时间是当前，结束时间为当前
function initSearch() {
  const startTime = ref('')
  const endTime = ref('')
  let current = new Date()
  let paramEnd = formatTimeStr(current)
  // 开始时间减一天
  current.setDate(current.getDate() - 1)
  let paramStart = formatTimeStr(current)
  startTime.value = paramStart
  endTime.value = paramEnd
  return { startTime, endTime }
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
@import '@/style/mixins.scss';
.pending {
  background-color: #fff;
  padding: 20px 0;
  height: 100%;
  .van-skeleton {
    margin-bottom: 30px;
  }
}
.empty {
  padding-top: 30px;
}
.results {
  &__navbar {
    font-size: 14px;
    display: flex;
    background-color: #fff;
    justify-content: space-around;
    &__tab {
      width: 187px;
      text-align: center;
      position: relative;
    }
    &__active::after {
      bottom: 0;
      position: absolute;
      content: '';
      height: 2px;
      background-color: $blue;
      width: 48px;
      transform: translateX(-50%) scale(1.5);
    }
  }
  &__run {
    background-color: #fff;
    &__search {
      height: 32px;
      font-size: 10px;
      &__notice {
        padding-left: 8px;
        width: 40px;
      }
      &__btn {
        width: 40px;
        box-sizing: border-box;
        margin-right: 4px;
      }
      input {
        @include ellipsis;
        width: 30%;
        padding-left: 4px;
        height: 22px;
        margin: 0 8px;
        border: 1px solid #f1f1f1;
        border-radius: 4px;
        background-color: #fff;
        letter-spacing: -0.5px;
      }
    }
    &__quicker {
      display: flex;
      p {
        flex-basis: 33.3%;
        text-align: center;
      }
    }
  }

  &__chart {
    width: 100%;
    position: relative;
    .chart-empty {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;
      color: #777;
    }
  }
}
.extension {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 80px;
  > div {
    padding: 4px 0;
    text-align: center;
  }
}
.restrictGroup {
  font-size: 10px;
  padding: 8px 12px;
  .restrictOption {
    padding: 4px 0;
  }
  .restrictHour {
    display: flex;
    justify-content: space-between;
  }
}
.restrict {
  display: flex;
  &__btn {
    margin-left: 4px;
  }
}
.full {
  position: fixed;
  top: 0;
  box-sizing: border-box;
  padding-left: 44px;
  transform: rotate(90deg);
  transform-origin: top left;
  left: 100vw;
  overflow: hidden;
  z-index: 200;
  background-color: #fff;
}

.hidden-style {
  visibility: hidden;
  z-index: -100;
}
.full-pic {
  right: 20px;
  bottom: 24px;
}
.exit-full-pic {
  bottom: 18px;
  left: 38px;
  z-index: 201;
}

.garbage {
  margin-top: 8px;
  box-shadow: 0 0 0 1px #dcdfe6 inset;
  color: #333;
  font-size: 16px;
  line-height: 20px;
  height: 22px;
  padding: 4px 6px;
  display: flex;
  justify-content: space-between;
  &__notice {
    font-size: 14px;
    color: #909399;
  }
}
</style>
