<template>
  <div class="cover-page">
    <NavBar>
      <template #right>
        <div class="icon-area">
          <van-icon name="search" @click="query" />
        </div>
      </template>
    </NavBar>
    <div class="content" @scroll="scrollTest" ref="scrollEl">
      <van-pull-refresh v-model="pullLoading" @refresh="onRefresh" class="pull-wrapper">
        <van-list
          v-model:loading="listLoading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          class="list-wrap"
          v-model:error="error"
          error-text="请求失败，点击重新加载"
        >
          <div v-for="item in orderList" :key="item.id" class="list-wrap__item" @click="getDetail(item.id)">
            <div class="list-wrap__header line section">
              <div class="list-wrap__header__name">{{ item.companyName }}</div>
              <div class="carNumber">{{ item.carNumber }}</div>
            </div>
            <div class="list-wrap__classify line">
              <div class="list-wrap__classify__row section">
                <div>
                  <div>问题分类：</div>
                  <div>{{ item.exceptionType }}</div>
                </div>
                <div>
                  <div>现象分类：</div>
                  <div>{{ item.programType }}</div>
                </div>
              </div>
              <div class="list-wrap__classify__row section">
                <div class="status">
                  <span v-if="item.detailStatus === 0" class="orange">待处理</span>
                  <span v-else-if="item.detailStatus === 1" class="green">人工处理</span>
                  <span v-else-if="item.detailStatus === 2" class="green">算法处理</span>
                  <span v-else-if="item.detailStatus === 3" class="green">无异常</span>
                </div>
                <div class="status">
                  <span
                    v-if="
                      item.orderStatus == 0 &&
                      item.checkStatus == 0 &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      btnNum != 'abnormal'
                    "
                    class="orange"
                  >
                    运输中
                  </span>
                  <span
                    v-else-if="
                      item.orderStatus != 0 &&
                      item.checkStatus == 0 &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      btnNum != 'abnormal'
                    "
                    class="green"
                  >
                    正常卸货
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      btnNum != 'abnormal' &&
                      item.isGpsError &&
                      item.isGpsError != 2
                    "
                    class="red"
                  >
                    <span v-if="item.isGpsError == 1 && btnNum != 'abnormal'" class="red">GPS异常</span>
                    <span v-else-if="item.isGpsError == 3 && btnNum != 'abnormal'" class="red">无GPS</span>
                    <span v-else-if="item.isGpsError == 4 && btnNum != 'abnormal'" class="red">停车速度平直</span>
                    <span v-else-if="item.isGpsError == 5 && btnNum != 'abnormal'" class="red">大段丢失</span>
                    <span v-else-if="item.isGpsError == 6 && btnNum != 'abnormal'" class="red">偶尔丢失</span>
                    <span v-else-if="item.checkStatus == 2" class="red" title="未清零 [进厂]">-未清零 [进厂]</span>
                    <span v-else-if="item.checkStatus == 3" class="red" title="未识别上货 [离厂]">
                      -未识别上货 [离厂]
                    </span>
                    <span v-else-if="item.checkStatus == 4" class="red" title="重量突变 [在途]">-重量突变 [在途]</span>
                    <span v-else-if="item.checkStatus == 5" class="red" title="卸货未清零">-卸货未清零</span>
                    <span v-else-if="item.checkStatus == 6" class="red" title="设备离线">-设备离线</span>
                    <span v-else-if="item.checkStatus == 7" class="red" title="平台问题">-平台问题</span>
                    <span v-else-if="item.checkStatus == 8" class="red" title="垂直卸货">-垂直卸货</span>
                    <span v-else-if="item.checkStatus == 9" class="red" title="磅单问题">-磅单问题</span>
                    <span v-else-if="item.checkStatus == 10" class="red" title="异常订单">-异常订单</span>
                    <span v-else-if="item.checkStatus == 11" class="red" title="卸货有速度">-卸货有速度</span>
                  </span>
                  <span v-else-if="btnNum == 'abnormal'" class="red">异常卸货</span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 2
                    "
                    class="red"
                  >
                    未清零 [进厂]
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 3
                    "
                    class="red"
                  >
                    未识别上货 [离厂]
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 4
                    "
                    class="red"
                  >
                    重量突变 [在途]
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 5
                    "
                    class="red"
                  >
                    卸货未清零
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 6
                    "
                    class="red"
                  >
                    设备离线
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 7
                    "
                    class="red"
                  >
                    平台问题
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 8
                    "
                    class="red"
                  >
                    垂直卸货
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 9
                    "
                    class="red"
                  >
                    磅单问题
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 10
                    "
                    class="red"
                  >
                    异常订单
                  </span>
                  <span
                    v-else-if="
                      btnNum != 'abnormal' &&
                      btnNum != 'dataErrorSensorNormal' &&
                      btnNum != 'dataErrorSensorAbnormal' &&
                      (!item.isGpsError || item.isGpsError == 2) &&
                      item.checkStatus == 11
                    "
                    class="red"
                  >
                    卸货有速度
                  </span>

                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 2
                    "
                    class="red"
                  >
                    未清零 [进厂]
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 3
                    "
                    class="red"
                  >
                    未识别上货 [离厂]
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 4
                    "
                    class="red"
                  >
                    重量突变 [在途]
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 5
                    "
                    class="red"
                  >
                    卸货未清零
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 6
                    "
                    class="red"
                  >
                    设备离线
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 7
                    "
                    class="red"
                  >
                    平台问题
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 8
                    "
                    class="red"
                  >
                    垂直卸货
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 9
                    "
                    class="red"
                  >
                    磅单问题
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 10
                    "
                    class="red"
                  >
                    异常订单
                  </span>
                  <span
                    v-else-if="
                      (btnNum == 'dataErrorSensorNormal' || btnNum == 'dataErrorSensorAbnormal') &&
                      item.checkStatus == 11
                    "
                    class="red"
                  >
                    卸货有速度
                  </span>
                </div>
              </div>
            </div>

            <div class="list-wrap__time section">
              <div>
                <div>
                  进厂时间：
                  <span>{{ item.emptyLoadTime }}</span>
                </div>
                <div></div>
              </div>
              <div>
                操作时间：
                <span>{{ item.detailTime }}</span>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <div class="affix" v-if="orderList.length > 0">
      <span>
        {{ pager.currentPage }}
      </span>
      <span>/</span>
      <span>
        {{ pager.pageCount }}
      </span>
    </div>
  </div>
</template>
<script>
import { defineComponent, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { formatTimeStr } from '@/utils/common'
import listHooks from './section'

export default defineComponent({
  name: 'QueryOrder',
  setup() {
    const store = useStore()

    const router = useRouter()

    const searchParams = store.state.search.queryOptions
    const newDate = new Date()
    // 写入默认查询时间
    let endTime = formatTimeStr(newDate)
    let startTime = formatTimeStr(newDate.setDate(newDate.getDate() - 90))
    // 如时间为空，则为切换车辆后第一次查看，需要写入默认查看 90 内数据
    searchParams.startTime = !searchParams.startTime ? startTime : searchParams.startTime
    searchParams.endTime = !searchParams.endTime ? endTime : searchParams.endTime
    const result = listHooks(searchParams)
    const state = result.state
    const pager = result.pager
    const onRefresh = result.onRefresh
    const onLoad = result.onLoad
    const { scrollEl, scrollTest } = result

    const query = () => {
      setTimeout(() => {
        router.push({
          name: 'SingleQueryOptions'
        })
      }, 100)
    }

    const getDetail = id => {
      router.push({
        name: 'OrderManageDeviceDetail',
        params: {
          id
        }
      })
    }

    return {
      query,
      getDetail,
      ...toRefs(state),
      pager,
      onRefresh,
      onLoad,
      scrollEl,
      scrollTest
    }
  }
})
</script>
<style lang="scss" scoped>
.icon-area {
  font-size: 18px;
}
.content {
  flex: 1;
  overflow-y: scroll;
  margin-top: 8px;
  background: #fff;
  .pull-wrapper {
    background: #fff;
    :deep .van-pull-refresh__head {
      text-align: center;
    }
    div {
      font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto,
        'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
      color: #455a6499;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
    }
    .list-wrap {
      :deep .van-list__finished-text {
        text-align: center;
      }
      .section {
        display: flex;
        justify-content: space-between;
      }
      &__item {
        background: #f2f2f2;
        margin: 4px 8px 4px;
        padding: 4px;
        border-radius: 6px;
      }
      &__header {
        padding: 8px 4px;
        div {
          padding: 2px 4px;
          font-size: 14px;
          line-height: 16px;
        }
        &__name {
          font-size: 14px;
          color: #323233;
        }
        .carNumber {
          background: #ff892a;
          color: #fff;
          border-radius: 999px;
          padding: 2px 12px;
        }
      }
      &__classify {
        &__row {
          padding: 8px 4px;
          div {
            font-size: 12px;
          }
          > div {
            min-width: 80px;
            display: flex;
          }
          .status {
            color: #ff892a;
          }
        }
      }
      &__time {
        padding: 4px;

        div {
          flex: 1;
          font-size: 10px;
        }
      }
    }
  }
}
.line::after {
  background-color: #d3d1d1;
}
</style>
