<template>
  <div class="item-row">
    <div class="item-row__label">{{ rowTitle }}：</div>
    <div class="item-row__content" :style="{ color: contentColor }">
      {{ rowContent }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListRow',
  props: {
    rowTitle: {
      type: String,
      require: true
    },
    rowContent: {
      type: [String, Number],
      require: true
    },
    contentColor: {
      type: String
    }
  },
  setup() {}
}
</script>
<style scoped lang="scss">
.item-row {
  width: 100%;
  display: flex;
  font-size: 12px;
  color: #333;
  padding: 2px 0;
  word-break: break-all;
  &__label {
    width: 70px;
    min-width: 70px;
    text-align: right;
  }
  &__content {
    flex: 1;
  }
}
</style>
