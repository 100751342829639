<template>
  <div class="info">
    <div class="info__device info__section">
      <div class="flex-spb text-cell">
        <div class="info__title">设备ID:</div>
        <div>{{ routerPass.deviceId }}</div>
      </div>
      <div class="flex-spb text-cell">
        <div class="info__title">设备状态:</div>
        <div :style="routerPass.runStatus === '1' ? 'color:#07c160' : 'color:#ee0a24'">
          {{ routerPass.runStatus === '1' ? '在线' : '离线' }}
        </div>
      </div>
      <div class="flex-spb text-cell" @click="listSensorStatus(sensorObj)">
        <div class="info__title">传感器:</div>
        <div id="sensorRender">
          <div v-for="(item, i) in sensorObj" :key="i" style="margin-right: 20px; display: flex; align-items: center">
            <NewBadge :tag="item.tag" :bg="item.active ? 'background-color:#07c160' : 'background-color:#777'">
              <template #content>{{ item.sensor }}</template>
            </NewBadge>
            <span :style="item.text == '正常' ? 'color:#07c160' : 'color:#ee0a24'">
              {{ item.text }}
            </span>
          </div>
        </div>
        <div v-if="sensorObj.length"><van-icon name="arrow" /></div>
      </div>
      <div class="flex-spb text-cell" @click="listGPSStatus">
        <div class="info__title">GPS状态:</div>
        <div class="flex-spb">
          <div :style="gpsStatus === '正常' ? 'color:#07c160' : 'color:#ee0a24'" style="text-align: right">
            {{ gpsStatus }}
          </div>
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="flex-spb text-cell">
        <div class="info__title">限制进厂:</div>
        <div :style="servicePass.deviceStatus === 0 ? 'color:#07c160' : 'color:#ee0a24'" class="restrict">
          <span style="margin-right: 4px">{{ servicePass.deviceStatus === 0 ? '无限制' : '限制' }}</span>
          <van-button
            type="primary"
            size="small"
            @click="dialogRestrict"
            v-if="servicePass.repaired"
            class="restrict__btn"
          >
            限制
          </van-button>
        </div>
      </div>
      <div class="flex-spb text-cell">
        <div class="info__title">限制原因:</div>
        <div style="color: #ee0a24">
          {{ servicePass.restrictReason }}
        </div>
      </div>
    </div>

    <div class="info__time info__section">
      <div class="flex-spb text-cell">
        <div class="info__title">安装时间:</div>
        <div>{{ servicePass.inputDate }}</div>
      </div>
      <div class="flex-spb text-cell">
        <div class="info__title">重量时间:</div>
        <div>{{ routerPass.carUploadDate }}</div>
      </div>
      <div class="flex-spb text-cell">
        <div class="info__title">GPS时间:</div>
        <div>{{ routerPass.gpsUploadDate }}</div>
      </div>
    </div>

    <div class="info__company info__section">
      <div class="flex-spb text-cell">
        <div>公司名称:</div>
        <div>{{ servicePass.companyName }}</div>
      </div>
      <div class="flex-spb text-cell">
        <div>车辆用途:</div>
        <div>{{ servicePass.useTypeL2Name }}</div>
      </div>
      <div class="flex-spb text-cell">
        <div>司机姓名:</div>
        <div>{{ servicePass.driverName }}</div>
      </div>
      <div class="flex-spb text-cell">
        <div>司机电话:</div>
        <div>{{ servicePass.phone }}</div>
      </div>
    </div>

    <div class="info__firm info__section">
      <div class="flex-spb text-cell">
        <div class="info__title">硬件版本:</div>
        <div style="display: flex; align-items: center">
          {{ servicePass.hwVersion }}
          <van-button type="primary" size="small" style="margin-left: 6px" @click="getDeviceVersion">获取</van-button>
        </div>
      </div>
      <div class="flex-spb text-cell">
        <div class="info__title">算法版本:</div>
        <div class="info__content">{{ servicePass.version }}</div>
      </div>
      <div class="flex-spb text-cell">
        <div class="info__title">当前系数:</div>
        <div class="info__content">{{ coefficient }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref, computed, onMounted, onActivated } from 'vue'
import { useStore } from 'vuex'
import { Toast } from 'vant'
import NewBadge from '@/views/components/NewBadge.vue'
import { http_clean } from '@/utils/request'

export default {
  components: {
    NewBadge
  },
  props: {
    start: {
      type: String,
      require: true
    },
    end: {
      type: String,
      require: true
    }
  },
  emits: ['restrict', 'update-pending', 'gps-problem', 'sensor-problem', 'get-car-type'],
  setup(props, { emit }) {
    const store = useStore()
    const routerPass = reactive({
      deviceId: '',
      carNumber: '',
      gpsUploadDate: '',
      carUploadDate: '',
      runStatus: ''
    })
    const servicePass = reactive({
      deviceStatus: 0, //是否限制进厂 /deviceRepaired/checkDeviceStatus.html
      repaired: '',
      restrictReason: '', // 限制进场原因
      inputDate: '', //安装时间 /car/getCarInfoByDeviceId.html
      companyName: '', //公司名称 /car/getCarInfoByDeviceId.html
      companyId: '',
      useTypeL2Name: '', //车辆用途 /car/getCarInfoByDeviceId.html
      driverName: '', //司机姓名 /car/getCarInfoByDeviceId.html
      phone: '', //司机电话 /car/getCarInfoByDeviceId.html
      hwVersion: '', //硬件版本 getDeviceBaseInfoByDeviceId.html 中返回
      version: '', //算法版本 getDeviceBaseInfoByDeviceid.html 中返回
      coef: [], //使用的通道数据 /system/getDeviceBaseInfoByDeviceId.html
      gpsProblem: [], //gps问题列表 /car/getCarInfoByDeviceId.html
      gpsProblemEntity: [], // GPS问题列表 /car/getCarInfoByDeviceId.html
      sensorAxle: [], //前后轴  0 默认 无区分  1 前轴  2后轴 /car/getCarInfoByDeviceId.html
      sensorChannel: [], //使用的通道 /car/getCarInfoByDeviceId.html
      sensorProblemList: [] //传感器问题列表 /car/getCarInfoByDeviceId.html
    })
    const sensorObj = ref([])

    //GPS状态
    const gpsStatus = computed(() => {
      let gasStatus
      if (servicePass.gpsProblemEntity !== null) {
        if (servicePass.gpsProblem !== null && servicePass.gpsProblem.length !== 0) {
          gasStatus = servicePass.gpsProblem
        } else {
          gasStatus = '正常'
        }
      } else {
        gasStatus = '正常'
      }
      return gasStatus
    })

    const coefficient = computed(() => {
      return store.getters['search/coefficient']
    })

    onMounted(() => {
      const saveData = JSON.parse(localStorage.getItem('tempSearch'))
      const { carNumber, deviceId, gpsUploadDate, carUploadDate, runStatus } = saveData
      routerPass.deviceId = deviceId
      routerPass.carNumber = carNumber
      routerPass.gpsUploadDate = gpsUploadDate
      routerPass.carUploadDate = carUploadDate
      routerPass.runStatus = runStatus

      initData()
    })
    onActivated(() => {
      // 重置订单查询搜索项
      store.commit('search/updateOption', {
        useTypeL2: '', // 车辆分类
        programType: '', // 问题分类
        detailStatus: '', // 处理状态
        isGpsError: '', // GPS状态
        queryCheckStatus: '', // 订单状态
        orderStatus2: '', // 订单进度
        isFixWeight: '', // 算法
        dateType: '1', // 时间类型
        startTime: '',
        endTime: ''
      })
    })

    const dialogRestrict = () => {
      emit('restrict')
    }

    const listGPSStatus = () => {
      emit('gps-problem', servicePass.gpsProblemEntity)
    }
    const listSensorStatus = list => {
      if (list.length) {
        emit('sensor-problem', servicePass.sensorProblemList)
      }
    }

    const getDeviceVersion = async () => {
      const result = await http_clean.post('/devicemanage/sendCodeForReadDeviceVersion.html', {
        protocolVersion: 2,
        deviceId: routerPass.deviceId
      })
      if (!result.success) {
        Toast(result.message)
        return
      }
      const versionStr = result.data
      if (versionStr) {
        servicePass.hwVersion = versionStr.split(',')[0]
      }
    }

    async function initData() {
      const deviceId = routerPass.deviceId
      const carInfo = await http_clean.post('/car/getCarInfoByDeviceId.html', { deviceId: deviceId })
      if (!carInfo.success) {
        Toast(carInfo.message)
        emit('update-pending', { isPending: false, loseInfo: true })
        return
      }
      emit('update-pending', { isPending: false, loseInfo: false })
      emit('get-car-type', { useTypeL1: carInfo.data.useTypeL1, deviceId: carInfo.data.deviceId })
      const {
        companyId,
        sensorAxle,
        sensorChannel,
        sensorProblemList,
        inputDate,
        useTypeL2Name,
        driverName,
        phone,
        gpsProblem,
        gpsProblemEntity
      } = carInfo.data
      const companyName = carInfo.data.company?.companyName ?? ''
      const carObj = {
        companyName,
        companyId,
        sensorAxle,
        sensorChannel,
        sensorProblemList,
        inputDate,
        useTypeL2Name,
        driverName,
        phone,
        gpsProblem,
        gpsProblemEntity
      }
      Object.keys(carObj).forEach(el => {
        servicePass[el] = carObj[el]
      })
      const baseInfo = await http_clean.post('/system/getDeviceBaseInfoByDeviceId.html', {
        deviceId: deviceId,
        showApi: 0,
        showModify: 1
      })
      const { coef, hwVersion, version, repaired } = baseInfo.data
      const deviceInfo = { coef: coef, hwVersion, version, repaired }
      Object.keys(deviceInfo).forEach(el => {
        servicePass[el] = deviceInfo[el]
      })
      // 拼接传感器渲染数组
      const sensors = { sensorAxle, sensorChannel, coef, sensorProblemList }
      sensorObj.value = formatSensor(sensors)
      // 保存 sensor 渲染数据
      store.commit('search/updateSensor', sensorObj.value)
      const deviceStatus = await http_clean.post('/deviceRepaired/checkDeviceStatus.html', { deviceId: deviceId })
      servicePass.restrictReason = deviceStatus.data.reason
      servicePass.deviceStatus = deviceStatus.data.code

      store.commit('changeNum/updateState', { companyName, companyId, carNumber: routerPass.carNumber, deviceId })

      store.commit('search/updateState', {
        phone,
        companyName,
        companyId,
        version,
        sensorChannel,
        coef,
        hwVersion,
        sensorProblemList
      })
    }

    return {
      routerPass,
      servicePass,
      gpsStatus,
      sensorObj,
      coefficient,
      dialogRestrict,
      initData,
      listGPSStatus,
      listSensorStatus,
      getDeviceVersion
    }
  }
}
/**
 * sensor数据汇总为渲染数组
 * @param {Object} params sensor相关数据
 * @returns 传感器展示数据
 */
function formatSensor(params) {
  const { sensorAxle, sensorChannel, coef, sensorProblemList } = params
  let temp = []
  let senorReason = null
  if (sensorProblemList && sensorProblemList.length > 0) {
    senorReason = sensorProblemList.map(el => el.sensorProblem.slice(3))
  }
  if (sensorChannel && coef) {
    sensorChannel.forEach((el, i) => {
      let descri = {}
      if (senorReason && senorReason.length > 0) {
        senorReason.forEach(item => {
          if (item[0] === el) {
            descri.text = item.slice(2)
          }
        })
      }
      if (!descri.text) descri.text = '正常'
      descri.sensor = el
      if (sensorAxle && sensorAxle[i] === '1') {
        descri.tag = '前'
      } else if (sensorAxle && sensorAxle[i] === '2') {
        descri.tag = '后'
      } else {
        descri.tag = ''
      }
      if (!coef || coef[i] === 0) {
        descri.active = false
      } else {
        descri.active = true
      }
      temp.push(descri)
    })
  } else {
    sensorChannel && sensorChannel.splice(0)
  }
  return temp
}
</script>
<style lang="scss" scoped>
.info {
  &__section {
    .text-cell {
      padding: 10px 14px;
    }
    #sensorRender {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
  }
  &__title {
    min-width: 60px;
  }
  &__content {
    word-break: break-all;
    padding-left: 10px;
  }
  .restrict {
    display: inline-flex;
    align-items: center;
  }
  :deep(.van-button--small) {
    height: 24px;
    width: 50px;
  }
}
</style>
