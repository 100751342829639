<template>
  <div class="cover-page">
    <NavBar />
    <div class="container">
      <div class="card">
        <div class="text-cell">
          <span class="cell-title">维修时间：</span>
          <span>{{ baseInfo.repairedTime }}</span>
        </div>
        <div class="text-cell">
          <span class="cell-title">维修状态：</span>
          <span style="color: #07c160">已维修</span>
        </div>
        <div class="text-cell">
          <span class="cell-title">维修人员：</span>
          <span>{{ baseInfo.repairedUserName }}</span>
        </div>
        <div class="text-cell">
          <span class="cell-title">维修前照片：</span>
          <van-image width="100" height="100" :src="baseInfo.beforeImageInfo[0]" />
        </div>
        <div class="text-cell">
          <span class="cell-title">维修后照片：</span>
          <van-image width="100" height="100" :src="baseInfo.afterImageInfo[0]" />
        </div>
        <div class="text-cell">
          <span class="cell-title">审核状态：</span>
          <span :class="{ orange: auditStatus === 0, green: auditStatus === 1, red: auditStatus === 2 }">
            {{ auditStatus === 0 ? '未审核' : auditStatus === 1 ? '审核通过' : '审核不通过' }}
          </span>
        </div>
        <div class="text-cell">
          <span class="cell-title">审核描述：</span>
          <span>{{ describe }}</span>
        </div>
        <div class="problem">
          <div class="problem__row">
            <span>故障现象：</span>
            <span>{{ pTypeList[baseInfo.pType * 1 - 1] }}</span>
          </div>
          <div class="problem__row">
            <span>维修内容：</span>
            <span>{{ typeList[baseInfo.type * 1 - 1] }}</span>
          </div>
          <div class="problem__row">
            <span>维修细分：</span>
            <span>{{ baseInfo.repairContent.join(',') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, onMounted, reactive, toRefs } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { http_clean } from '@/utils/request'
export default {
  setup() {
    // 大类的数组 现象的数组
    const pTypeList = ['离线问题', 'GPS问题', '传感器问题']
    // 小类的数组 内容的数组
    const typeList = [
      '更换主机',
      '电源维修',
      '更换线材',
      '挪动天线位置',
      '更换天线',
      'V4换V7',
      '更换传感器',
      '重新焊接',
      '离线其他',
      'GPS其他',
      '传感器其他'
    ]

    const route = useRoute()
    const deviceRepairedId = route.params.id
    const deviceId = route.params.deviceId
    const state = reactive({
      baseInfo: {
        repairedTime: '',
        repairedUserName: '',
        beforeImageInfo: [],
        afterImageInfo: [],
        repairContent: []
      }
    })

    const auditStatus = inject('auditStatus')
    const describe = inject('auditDescription')
    onMounted(() => {
      fetchData()
    })
    function fetchData() {
      const params = JSON.stringify({
        deviceRepairedId,
        deviceId
      })
      const gridPager = `{"pageSize":"10","nowPage":"1","parameters":${params}}`
      http_clean({
        url: 'deviceRepairedV2/deviceRepairedDetailList.html',
        method: 'post',
        data: { gridPager }
      }).then(res => {
        const data = res.exhibitDatas
        state.baseInfo = data[0]
      })
    }
    return {
      pTypeList,
      typeList,
      auditStatus,
      describe,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  background-color: #f1f1f1;
  .text-cell {
    display: flex;
    align-items: center;
    .cell-title {
      min-width: 100px;
    }
  }
  .problem {
    background-color: #f3f3f3;
    margin: 8px;
    padding: 4px;
    &__row {
      height: 24px;
      line-height: 20px;
    }
  }
}
</style>
