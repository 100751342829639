<template>
  <div class="cover-page up-requests">
    <NavBar />
    <div class="container up-requests__wrap">
      <pull-fresh sourceUrl="log/upgrade.html" :data="params">
        <template #default="scope">
          <list-row rowTitle="车牌号码" :rowContent="scope.item.carNumber"></list-row>
          <list-row rowTitle="操作状态" :rowContent="scope.item.content"></list-row>
          <list-row
            rowTitle="升级状态"
            :rowContent="scope.item.status === '0' ? '等待升级' : '升级成功'"
            :contentColor="scope.item.status === '0' ? '#ffb752' : '#4cae4c'"
          ></list-row>
          <list-row rowTitle="版本名称" :rowContent="scope.item.binName"></list-row>
          <list-row rowTitle="升级时间" :rowContent="scope.item.createTime"></list-row>
          <list-row rowTitle="操作人" :rowContent="scope.item.userName"></list-row>
        </template>
      </pull-fresh>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject } from 'vue'
import PullFresh from '@/views/components/PullFresh.vue'
import ListRow from '@/views/components/ListRow.vue'
import { formatTimeStr, increaseDate } from '@/utils/common'

export default defineComponent({
  name: 'UpgradeRequests',
  components: {
    ListRow,
    PullFresh
  },
  setup() {
    const deviceId = inject('deviceId')
    const current = new Date()
    const params = {
      endTime: formatTimeStr(current),
      startTime: increaseDate('days', -90, current),
      deviceId
    }
    return {
      params
    }
  }
})
</script>
<style lang="scss" scoped>
.up-requests {
  &__wrap {
    flex: 1;
  }
}
</style>
