<template>
  <div class="cover-page">
    <NavBar>
      <template #right>
        <div :class="['operate', { 'operate-active': selectedCompanyId }]" @click="confirmCompany">
          <span>确定</span>
        </div>
      </template>
    </NavBar>
    <div class="container">
      <van-search
        v-model="searchKey"
        placeholder="请输入搜索关键词"
        @update:model-value="searchCom"
        class="search-input"
      />
      <div class="show-company">
        <ParentCompany v-for="item in companyList" :key="item.id" :parentCompany="item" />
      </div>
    </div>
  </div>
</template>
<script>
import { toRefs, provide, ref, inject, reactive } from 'vue'
import http from '@/utils/request'
import { toTree } from '@/utils/common'
import { useRouter } from 'vue-router'
import ParentCompany from '@/views/components/ParentCompany.vue'

export default {
  name: 'QueryOptionCompany',
  components: {
    ParentCompany
  },
  setup() {
    const router = useRouter()
    const state = reactive({
      copyCompanies: [], // 用来保存筛选前的全部原始数据
      companyList: [],
      searchKey: ''
    })
    http({
      url: '/company/companytree.html',
      method: 'get'
    }).then(res => {
      if (res) {
        const companies = toTree(res)
        state.copyCompanies = deepClone(companies)
        state.companyList = companies
      }
    })
    const searchCom = () => {
      state.companyList = deepSearch(state.searchKey, deepClone(state.copyCompanies))
    }

    const selectedCompanyId = ref('')
    const companyName = ref('')
    searchCom()

    const updateCompanyId = com => {
      selectedCompanyId.value = com
    }
    const updateCompanyName = com => {
      companyName.value = com
    }
    // 初始化公司名和ID
    selectedCompanyId.value = inject('searchCompanyId') || ''
    companyName.value = inject('searchCompanyName') || ''

    const updateCompany = inject('updateCompany')

    // 向下提供数据和方法，更新公司名称和公司ID
    provide('selectedCompanyId', selectedCompanyId)
    provide('updateCompanyId', updateCompanyId)
    provide('selectedCompanyName', companyName)
    provide('updateCompanyName', updateCompanyName)

    // 确认选中，返回上级
    const confirmCompany = () => {
      if (!selectedCompanyId.value) return
      updateCompany(companyName.value, selectedCompanyId.value)
      setTimeout(() => {
        state.searchKey = ''
        router.go(-1)
      }, 500)
    }
    return {
      ...toRefs(state),
      selectedCompanyId,
      confirmCompany,
      searchCom
    }
  }
}
//深拷贝浅拷贝
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
// 查询公司名称
function deepSearch(val, origins) {
  let result = []
  for (let index = 0; index < origins.length; index++) {
    const el = origins[index]
    if (el.text.includes(val)) {
      result = result.concat(el)
    } else {
      if (el.children && el.children.length > 0) {
        result = result.concat(deepSearch(val, el.children))
      }
    }
  }
  return result
}
</script>
<style lang="scss" scoped>
.operate {
  color: #646566;
  &-active {
    color: #323233;
  }
}
.container {
  display: flex;
  flex-direction: column;
  .show-company {
    flex-grow: 1;
    overflow-y: scroll;
    padding-top: 8px;
  }
}
</style>
