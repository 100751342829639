<template>
  <div class="cover-page">
    <NavBar></NavBar>
    <div class="time-range flex-spa">
      <span class="time-range__notice">时间段</span>
      <input type="text" readonly class="time-range__start" v-model="startTime" @click="selectTime(startTime, true)" />
      -
      <input type="text" readonly class="time-range__end" v-model="endTime" @click="selectTime(endTime, false)" />
      <van-button type="primary" size="mini" @click="onRefresh">查询</van-button>
    </div>
    <div class="container" @scroll="scrollTest" ref="scrollEl" style="background: #fff">
      <van-pull-refresh v-model="pullLoading" @refresh="onRefresh" class="pull-wrapper">
        <van-list
          v-model:loading="listLoading"
          :finished="finished"
          finished-text="没有更多了"
          v-model:error="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
          class="list-wrap"
        >
          <div class="list-wrap__item list-item" v-for="item in orderList" :key="item.id" @click="checkDetail(item)">
            <div class="list-wrap__item__header">
              <div>{{ item.carUploadDate }}</div>
              <div>{{ item.weight }}吨</div>
            </div>

            <div class="list-wrap__item__address">
              {{ item.address }}
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import { defineComponent, toRefs } from 'vue'
import { useScroll } from './onceGarbage'

export default defineComponent({
  name: 'OnceGarbage',
  setup() {
    const { startTime, endTime, state, listState, scrollEl, onRefresh, onLoad, scrollHandler } = useScroll()

    return {
      ...toRefs(state),
      ...toRefs(listState),
      startTime,
      endTime,
      scrollEl,
      onRefresh,
      onLoad,
      scrollHandler
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/style/mixins.scss';
.time-range {
  height: 32px;
  font-size: 10px;
  &__notice {
    padding-left: 8px;
    width: 40px;
  }
  &__btn {
    width: 40px;
    box-sizing: border-box;
    margin-right: 4px;
  }
  input {
    @include ellipsis;
    width: 30%;
    padding-left: 4px;
    height: 22px;
    margin: 0 8px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    background-color: #fff;
    letter-spacing: -0.5px;
  }
}
.pull-wrapper {
  background: #fff;
  :deep .van-pull-refresh__track {
    height: auto;
  }
  div {
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto,
      'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
    color: #455a6499;
    font-weight: normal;
    line-height: 18px;
  }
  .list-wrap {
    &__item {
      height: 100px;
      overflow-y: hidden;
      font-size: 14px;
      padding: 12px;
      &__header {
        display: flex;
        justify-content: space-between;
        padding: 0 8px;
      }
      &__address {
        font-size: 16px;
        padding: 8px 8px;
        color: #333;
      }
    }
  }
}
</style>
