<template>
  <div class="cover-page">
    <NavBar>
      <template #right>
        <div :class="['operate', { 'operate-active': carNumber.length > 1 }]" @click="confirmCar">
          <span>确定</span>
        </div>
      </template>
    </NavBar>
    <div class="container search-car">
      <van-search v-model="searchKey" placeholder="请输入4位搜索关键词" @update:model-value="onInput" />
      <van-radio-group class="car-list" @scroll="scrollLoad" v-model="deviceId">
        <van-cell-group class="scroll-item">
          <van-cell :title="item.title" clickable @click="pickCar(item)" v-for="item in showList" :key="item.id">
            <template #right-icon>
              <van-radio :name="item.deviceId" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import http from '@/utils/request'
import { throttle } from '@/utils/common'

export default defineComponent({
  name: 'QueryOptionCar',
  setup() {
    const router = useRouter()
    const state = reactive({
      carNumber: '',
      deviceId: '',
      searchKey: '',
      carList: [],
      pushList: [],
      showList: []
    })

    state.carNumber = inject('searchCarNumber').value || ''
    state.deviceId = inject('searchDeviceId').value || ''

    const updateCar = inject('updateCar')

    // 前端分页加载
    const pageSize = 100
    let currentPage = 1
    let maxPage = 1

    const searchCar = () => {
      http({
        url: 'stats/carListByCarNumber.html',
        method: 'post',
        data: {
          carNumber: state.searchKey
        }
      }).then(res => {
        if (res.success) {
          maxPage = Math.ceil(res.data.length / pageSize)
          state.carList = [...res.data]
          state.pushList = [...res.data]
          state.pushList.forEach(el => (el.title = el.carNumber + '[' + el.deviceId + ']'))
          state.showList = state.pushList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        }
      })
    }

    const onInput = () => {
      if (state.searchKey.trim().length >= 4) {
        currentPage = 1
        searchCar()
      } else {
        state.carList = []
        state.pushList = []
        state.showList = []
      }
    }
    const confirmCar = () => {
      if (!state.carNumber && state.carNumber.length < 1) return
      updateCar(state.carNumber, state.deviceId)
      setTimeout(() => {
        router.go(-1)
      }, 500)
    }
    const pickCar = item => {
      state.carNumber = item.carNumber
      state.deviceId = item.deviceId
    }

    const scrollLoad = throttle(e => {
      const upTop = e.target.scrollTop
      const clientH = e.target.clientHeight
      const scrollH = e.target.scrollHeight

      if (scrollH - (upTop + clientH) <= 700) {
        if (currentPage >= maxPage) return
        currentPage++
        state.showList = state.showList.concat(
          state.pushList.slice((currentPage - 1) * pageSize, currentPage * pageSize)
        )
      }
    }, 100)

    return {
      ...toRefs(state),
      confirmCar,
      onInput,
      pickCar,
      scrollLoad
    }
  }
})
</script>
<style lang="scss" scoped>
.operate {
  color: #646566;
  &-active {
    color: #323233;
  }
}
.search-car {
  display: flex;
  flex-direction: column;
  .car-list {
    flex-grow: 1;
    overflow-y: scroll;
  }
}
</style>
