<template>
  <div class="cover-page up-query">
    <NavBar />
    <div class="container">
      <van-cell-group>
        <van-field label="协议版本" v-model="searchOptions.protocolVersion" readonly @click="pickProto" />
        <van-field
          label="公司名称"
          v-model="searchOptions.companyName"
          readonly
          placeholder="请选择公司名称"
          @click="pickCompany"
        />
        <van-field
          label="车辆分类"
          v-model="searchOptions.useTypeL2"
          readonly
          placeholder="请选择车辆分类"
          @click="pickCarType"
        />
        <van-field label="硬件版本" v-model="searchOptions.hwVersion" readonly @click="pickHardware" />
        <van-field v-model="searchOptions.searchKey" label="版本号" placeholder="请输入版本号" />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" @click="submitOption">查询</van-button>
      </div>

      <van-popup :show="pickState.isPick" @click-overlay="pickState.isPick = false" round position="bottom">
        <van-picker show-toolbar :columns="pickState.columns" @confirm="onConfirm" @cancel="pickState.isPick = false" />
      </van-popup>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import { carTypeList } from '@/utils/types'

export default defineComponent({
  name: 'UpgradeQuery',
  setup() {
    const searchOptions = reactive({
      protocolVersion: '第二代', // 协议版本
      companyName: '', // 公司名称
      hwVersion: '全部', // 硬件版本
      useTypeL2: '', // 车辆分类
      searchKey: '' // 版本号
    })
    const store = useStore()
    const router = useRouter()

    const params = {
      companyId: '',
      protocolVersion: '2',
      hwVersion: '',
      useTypeL2: '',
      // use: '', // 车辆分类名称
      searchKey: ''
    }

    const pickState = reactive({
      isPick: false,
      columns: [],
      key: ''
    })

    onMounted(() => {
      const data = store.state.resetId
      // 初始化查询条件
      params.protocolVersion = data.protocolVersion ? data.protocolVersion : '2'
      params.hwVersion = data.hwVersion ? data.hwVersion : ''
      params.useTypeL2 = data.useTypeL2 ? data.useTypeL2 : ''
      params.searchKey = data.searchKey ? data.searchKey : ''
      params.companyId = data.companyId ? data.companyId : ''
      // 有可能没有公司名字，只有公司ID
      if (data.companyName) {
        params.companyName = data.companyName
      }
      // 车辆类型名字
      if (data.use) {
        params.use = data.use
        searchOptions.useTypeL2 = data.use
      }
      // 初始化展示项
      searchOptions.companyName = data.companyName ?? ''
      searchOptions.searchKey = data.searchKey ? data.searchKey : ''
      searchOptions.protocolVersion = options.protocolVersion.find(item => item.value === params.protocolVersion).text
      searchOptions.hwVersion = options.hwVersion.find(item => item.value === params.hwVersion).text
    })

    const options = {
      protocolVersion: [
        { text: '全部', value: '' },
        { text: '第一代', value: '1' },
        { text: '第二代', value: '2' },
        { text: '中凯协议', value: '3' }
      ],
      hwVersion: [
        { text: '全部', value: '' },
        { text: 'V4', value: '4' },
        { text: 'V7', value: '7' },
        { text: 'V8', value: '8' },
        { text: 'V9', value: '9' }
      ],
      useTypeL2: carTypeList
    }

    const pickProto = () => {
      setColumns('protocolVersion')
    }

    const pickCompany = () => {
      router.push({
        name: 'SelectCompany',
        params: {
          section: 'resetId'
        }
      })
    }

    const pickCarType = () => {
      setColumns('useTypeL2')
    }

    const pickHardware = () => {
      setColumns('hwVersion')
    }
    const setColumns = key => {
      pickState.key = key
      pickState.columns = options[key]
      pickState.isPick = true
    }

    const onConfirm = val => {
      const { key } = pickState
      if (Array.isArray(val)) {
        val = val[1]
        params.use = val.text
      }
      searchOptions[key] = val.text
      params[key] = val.value
      pickState.isPick = false
    }

    const submitOption = () => {
      router.go(-1)
    }
    onBeforeRouteLeave(() => {
      params.searchKey = searchOptions.searchKey
      store.commit('resetId/updateState', params)
    })

    return {
      pickProto,
      onConfirm,
      pickState,
      pickCompany,
      pickCarType,
      pickHardware,
      searchOptions,
      submitOption
    }
  }
})
</script>
<style lang="scss" scoped></style>
