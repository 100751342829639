<template>
  <div class="cover-page">
    <NavBar />
    <div class="container">
      <div class="card">
        <div class="text-cell flex-spb">
          <div>开始时间：</div>
          <div>{{ startTime }}</div>
        </div>
        <div class="text-cell flex-spb">
          <div>截止时间：</div>
          <div>{{ endTime }}</div>
        </div>
      </div>

      <div class="card">
        <van-row class="chartOption">
          <van-col span="8">
            <van-checkbox v-model="lookSignal">AD信号</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="showApi">API修正</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="loadometer">地磅数据</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="carSpeed">车辆速度</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="showModify">修正数据</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="isShowMap" @click="showMapCk">地图联动</van-checkbox>
          </van-col>
          <van-col span="8">
            <van-checkbox v-model="isShowQuestion">问题提交</van-checkbox>
          </van-col>
        </van-row>
        <div class="chartContain">
          <div class="chart-empty" v-if="processInfo && (!allData || allData.length === 0)">{{ processInfo }}</div>
          <chart-view height="250px" :chartOption="echartOption"></chart-view>
          <div id="transportMap" :style="{ height: isShowMap ? '250px' : 0 }"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import ChartView from '@/components/echart/index.vue'
import { increaseDate } from '@/utils/common'
import chartModule from './printChart'

export default {
  name: 'DeviceRepairEchart',
  components: {
    ChartView
  },
  setup() {
    const route = useRoute()
    const startTime = route.params.createTime
    const endTime = increaseDate('d', 3, startTime) //订单结束时间:往后推半个小时
    const deviceId = route.params.deviceId
    // 获取echart相关问题
    const { orderInfo, chartState, chartOption, showChart, showMapCk, echartOption } = chartModule()
    onMounted(() => {
      orderInfo.deviceId = deviceId
      orderInfo.startTime = startTime
      orderInfo.endTime = endTime
      showChart()
    })

    return {
      startTime,
      endTime,
      ...toRefs(chartState),
      ...toRefs(chartOption),
      showMapCk,
      echartOption
    }
  }
}
</script>
<style lang="scss" scoped>
.chartOption {
  padding: 0 12px;
  margin-bottom: 8px;
}
.chartContain {
  background-color: #f8f8f8;
  position: relative;
  .chart-empty {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
