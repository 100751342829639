<template>
  <div class="new-badge__wrap">
    <div class="new-badge__content" :style="bg">
      <slot name="content"></slot>
    </div>
    <div class="new-badge__tag new-badge--fixed" v-if="tag">{{ tag }}</div>
  </div>
</template>
<script>
export default {
  name: 'NewBadge',
  props: {
    tag: {
      type: String
    },
    bg: {
      type: String
    }
  },
  setup() {}
}
</script>
<style scoped lang="scss">
.new-badge__wrap {
  position: relative;
  color: #fff;
}
.new-badge__content {
  position: relative;
  width: 16px;
  height: 16px;
  line-height: 1;
  border: 1px solid;
  padding: 0;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 16px;
}
.new-badge__tag {
  background-color: #dda450;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 1.2;
  padding: 3px;
  border-radius: 999px;
  color: #fff;
  display: inline-block;
}
.new-badge--fixed {
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%) scale(0.7);
}
</style>
