<template>
  <div class="problems cover-page">
    <NavBar />
    <div class="container">
      <div class="list-item" v-for="(item, i) in GPSProblems" :key="i">
        <div class="gps-title">
          问题描述：
          <span>{{ item.gpsProblem }}</span>
        </div>
        <div class="gps-content flex-spb">
          <div class="gps-col">
            开始时间：
            <span>{{ item.startTime }}</span>
          </div>
          <div class="gps-col">
            结束时间：
            <span>{{ item.endTime }}</span>
          </div>
        </div>
        <div class="gps-content flex-spb">
          <div class="gps-col">
            操作人：
            <span>{{ item.operator }}</span>
          </div>
          <div class="gps-col">
            操作时间：
            <span>{{ item.operatingTime }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject } from 'vue'
import { Toast } from 'vant'
export default {
  name: 'GPSList',
  setup() {
    let GPSProblems = inject('GPSProblems').value
    if (!GPSProblems || GPSProblems.length === 0) {
      Toast('暂无相关数据')
    }
    if (GPSProblems && !Array.isArray(GPSProblems)) {
      GPSProblems = [GPSProblems]
    }
    return {
      GPSProblems
    }
  }
}
</script>
<style lang="scss" scoped>
.list-item {
  padding: 16px 6px;
  font-size: 14px;
  color: #333;
  letter-spacing: -1px;
  .gps-title {
    margin-bottom: 8px;
  }
  .gps-content {
    font-size: 12px;
    margin-bottom: 4px;
    .gps-col {
      flex: 1;
      line-height: 1.5;
    }
  }
}
</style>
