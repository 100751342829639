<template>
  <div class="cover-page">
    <NavBar />
    <div class="edit container">
      <van-field
        v-model="companyState.companyName"
        label="公司名称"
        colon
        label-width="80px"
        placeholder="请选择公司"
        @click="selectCompany"
        readonly
      />
      <van-field v-model="deviceId" label="设备编号" colon label-width="80px" disabled />
      <van-field v-model="carNumber" label="车牌号码" colon label-width="80px" maxlength="10" @change="updateCarNumber">
        <template #button>
          <van-button size="small" type="primary" @click="numberCheck">
            切换车牌输入
            <van-icon name="exchange" />
          </van-button>
        </template>
      </van-field>

      <div class="edit__submit">
        <van-button type="primary" size="large" round @click="submitFixed">修改</van-button>
      </div>

      <van-popup :show="isInput" position="bottom" @click-overlay="isInput = false" style="height: 80%">
        <!-- 车牌输入框 -->
        <van-password-input
          :value="carInputContent"
          :focused="showKeyboard"
          @focus="showKeyboard = true"
          :length="7"
          :mask="false"
        />
        <van-button round type="primary" size="large" class="edit__submit" @click="confirmCar">确定</van-button>

        <!-- 车牌输入键盘 -->
        <div class="board">
          <!-- 第一个字省份键盘 -->
          <div class="board__province" v-if="carInputContent.length === 0">
            <div class="board__line">
              <p
                class="board__line__item"
                v-for="(item, index) in provinceAbbr[0]"
                :key="index"
                :data-val="item"
                @click="inputCarNumber"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__line">
              <p
                class="board__line__item"
                v-for="(item, index) in provinceAbbr[1]"
                :key="index"
                :data-val="item"
                @click="inputCarNumber"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__line" style="width: 70%; margin: 0 auto">
              <p
                class="board__line__item"
                v-for="(item, index) in provinceAbbr[2]"
                :key="index"
                :data-val="item"
                @click="inputCarNumber"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__line" style="width: 75%; margin: 0 auto">
              <p
                class="board__line__item"
                v-for="(item, index) in provinceAbbr[3]"
                :key="index"
                :data-val="item"
                @click="inputCarNumber"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__province__delete" @click="delCarNumber">
              <img src="@/assets/delete.png" alt="删除" />
            </div>
          </div>
          <!-- 主要键盘 -->
          <div class="board__main" v-if="carInputContent.length !== 0">
            <div class="board__line">
              <p
                :class="['board__line__item', { board__line__item_disable: carInputContent.length <= 1 }]"
                v-for="(item, index) in keyBoardNumber"
                :key="index"
                :data-val="item"
                @click="inputCarNumberSpec"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__line">
              <p
                class="board__line__item"
                v-for="(item, index) in keyBoardChar[0]"
                :key="index"
                :data-val="item"
                @click="inputCarNumber"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__line" style="width: 70%; margin: 0 auto">
              <p
                class="board__line__item"
                v-for="(item, index) in keyBoardChar[1]"
                :key="index"
                :data-val="item"
                @click="inputCarNumber"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__line" style="width: 75%; margin: 0 auto">
              <p
                class="board__line__item"
                v-for="(item, index) in keyBoardChar[2]"
                :key="index"
                :data-val="item"
                @click="inputCarNumber"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__line" style="width: 75%; margin: 0 auto">
              <p
                :class="['board__line__item', { board__line__item_disable: carInputContent.length <= 1 }]"
                v-for="(item, index) in keyBoardLast"
                :key="index"
                :data-val="item"
                @click="inputCarNumberSpec"
              >
                {{ item }}
              </p>
            </div>
            <div class="board__main__delete" @click="delCarNumber">
              <img src="@/assets/delete.png" alt="删除" />
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { Toast } from 'vant'
import useCarNumberEffect from './useCarNumberEffect'
import http from '@/utils/request'
export default {
  name: 'ChangeNumber',
  setup() {
    const router = useRouter()
    const store = useStore() //引入vuex
    const state = reactive({
      deviceId: store.state.changeNum.deviceId // 获取
    })
    const companyState = reactive({
      pickCompany: false,
      companyName: store.state.changeNum.companyName,
      companyId: store.state.changeNum.companyId
    })
    //车牌module
    const {
      provinceAbbr,
      keyBoardNumber,
      keyBoardLast,
      keyBoardChar,
      carNumber,
      carInputContent,
      isInput,
      confirmCar,
      showKeyboard,
      inputCarNumber,
      numberCheck,
      inputCarNumberSpec,
      delCarNumber
    } = useCarNumberEffect()

    carNumber.value = store.state.changeNum.carNumber // 获取vuex中保存的车牌

    http({
      method: 'post',
      url: 'company/companytree.html'
    }).then(res => {
      companyState.companyData = formatCom(res)
    })

    const selectCompany = () => {
      router.push({
        name: 'SelectCompany',
        params: {
          section: 'changeNum',
          keep: true
        }
      })
    }

    const submitFixed = () => {
      let url
      let params
      url = 'car/edit.html'
      params = {
        carNumber: carNumber.value,
        deviceId: state.deviceId,
        companyId: companyState.companyId,
        type: 2
      }

      http.post(url, params).then(res => {
        if (res.success) {
          Toast(res.message)

          store.commit('search/updateCarInfo', {
            carNumber: carNumber.value,
            deviceId: state.deviceId
          })
        }
      })
    }

    const updateCarNumber = () => {
      store.commit('changeNum/updateState', { carNumber: carNumber })
    }

    return {
      ...toRefs(state),
      companyState,
      submitFixed,
      showKeyboard,
      provinceAbbr,
      keyBoardNumber,
      keyBoardLast,
      keyBoardChar,
      carNumber,
      carInputContent, // 车牌键盘获取的内容
      isInput, // 是否使用车牌键盘输入
      updateCarNumber,
      selectCompany,
      confirmCar,
      inputCarNumber,
      inputCarNumberSpec,
      numberCheck,
      delCarNumber
    }
  }
}
function formatCom(data) {
  const companies = []
  data.forEach(el => {
    const temp = {}
    temp.value = el.id
    temp.text = el.text
    companies.push(temp)
  })
  return companies
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';
.edit {
  height: 100%;
  width: 100%;
  background-color: #fff;
  &__submit {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding: 0 16px;
  }
  .board {
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    background-color: $background-color;
    padding: 10px 0;

    &__province,
    &__main {
      position: relative;
      &__delete {
        width: 50px;
        height: 40px;
        position: absolute;
        border-radius: 8px;
        background-color: #fff;
        text-align: center;
        img {
          width: 20px;
          height: 20px;
          margin-top: 20px;
          transform: translateY(-50%);
        }
      }
    }

    &__province {
      height: 160px;
      &__delete {
        right: 0;
        bottom: 0;
      }
    }

    &__main {
      height: 200px;
      &__delete {
        right: 0;
        bottom: 0;
      }
    }

    &__line {
      display: flex;
      justify-content: center;
      &__item {
        box-sizing: content-box;
        width: 32px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #fff;
        color: $font-color;
        border-radius: 8px;
        margin: 0 5px;
        &_disable {
          color: $notice-font-color;
          background-color: #e5e5e5;
        }
      }
    }
  }
  &__submit {
    width: 200px;
    height: 32px;
    margin-top: 16px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
}
</style>
