<template>
  <div class="upgrade cover-page">
    <NavBar>
      <template #right>
        <div class="icon-area">
          <van-icon name="search" @click="query" />
        </div>
      </template>
    </NavBar>
    <div class="container upgrade__wrap">
      <pull-fresh sourceUrl="bininfo/list2.html" :data="params" @click-item="upgradeOp">
        <template #default="scope">
          <list-row rowTitle="公司名称" :rowContent="scope.item.companyName"></list-row>
          <list-row rowTitle="车辆分类" :rowContent="carDetailUseList[scope.item.useTypeL2]"></list-row>
          <list-row
            rowTitle="硬件版本"
            :rowContent="scope.item.protocolVersion === '2' ? '第二代' : '第一代'"
          ></list-row>
          <list-row rowTitle="上传时间" :rowContent="scope.item.updateDate"></list-row>
          <list-row rowTitle="版本号" :rowContent="scope.item.version"></list-row>
          <list-row rowTitle="包个数" :rowContent="scope.item.packetTotal"></list-row>
          <list-row rowTitle="描述" :rowContent="scope.item.description"></list-row>
        </template>
      </pull-fresh>
      <div class="bottom-btn">
        <van-button type="primary" size="small" class="operate" @click="showDetail">升级日志</van-button>
      </div>
    </div>
    <router-view v-slot="{ Component }">
      <transition :name="animationName">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
import { defineComponent, provide, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import PullFresh from '@/views/components/PullFresh.vue'
import ListRow from '@/views/components/ListRow.vue'
import { carDetailUseList } from '@/utils/types'

import { useAnimation } from '@/hooks/useAnimation'

export default defineComponent({
  name: 'UpGradeRecords',
  components: {
    PullFresh,
    ListRow
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const selectBin = ref({})

    const params = computed(() => store.state.resetId)
    provide('binInfo', selectBin)

    const { animationName } = useAnimation()

    const upgradeOp = item => {
      selectBin.value = item
      router.push({
        name: 'UpgradeOperate'
      })
    }

    const showDetail = () => {
      router.push({
        name: 'UpgradeRequests'
      })
    }

    const query = () => {
      router.push({
        name: 'UpgradeQuery'
      })
    }

    return {
      animationName,
      params,
      upgradeOp,
      showDetail,
      query,
      carDetailUseList
    }
  }
})
</script>
<style lang="scss" scoped>
.upgrade {
  // background-color: #fff;
  // position: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // z-index: 80;
  // display: flex;
  // flex-direction: column;
  &__wrap {
    display: flex;
    flex-direction: column;
    .bottom-btn {
      background: #f1f1f1;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .operate {
        width: 220px;
      }
    }
  }
}
.list-item {
  padding: 8px 4px;
  .item-row {
    width: 100%;
    display: flex;
    font-size: 12px;
    color: #333;
    padding: 2px 0;
    word-break: break-all;
    &__label {
      width: 70px;
      min-width: 70px;
      text-align: right;
    }
    &__content {
      flex: 1;
    }
  }
}
</style>
