export const opCases = {
  all: [
    { key: 'isWaitDetail', value: '' },
    { key: 'orderStatus', value: '' }
  ],
  trans: [{ key: 'orderStatus', value: '0' }],
  normal: [
    { key: 'checkStatus', value: '0' },
    { key: 'orderStatus', value: '1' }
  ],
  abnormal: [{ key: 'isRail', value: '2' }],
  gpsError: [{ key: 'checkStatus', value: '1' }],
  dataError: [{ key: 'checkStatus', value: '2' }],
  deviceOffLine: [{ key: 'checkStatus', value: '6' }],
  systemMisjudgment: [{ key: 'exceptionType', value: '7' }],
  weightError: [{ key: 'exceptionType', value: '8' }],
  blackout: [{ key: 'exceptionType', value: '9' }],
  waitDeal: [{ key: 'isWaitDetail', value: '1' }],
  algorithm: [{ key: 'exceptionType', value: '1' }],
  hwVersion: [{ key: 'exceptionType', value: '2' }],
  MatLab: [{ key: 'isMatlab', value: '1' }],
  secondLoading: [{ key: 'secondRecord', value: '1' }],
  preOrder: [{ key: 'isUnusualOrder', value: '1' }],
  gpsErrorSensorNormal: [
    { key: 'checkStatus', value: '1' },
    { key: 'sensorAbnormal', value: '0' }
  ],
  gpsErrorSensorAbnormal: [
    { key: 'checkStatus', value: '1' },
    { key: 'sensorAbnormal', value: '1' }
  ],
  dataErrorSensorNormal: [
    { key: 'checkStatus', value: '2' },
    { key: 'sensorAbnormal', value: '0' },
    { key: 'isGpsErrorT', value: '0' }
  ],
  dataErrorSensorAbnormal: [
    { key: 'sensorAbnormal', value: '1' },
    { key: 'isFixWeightT', value: '2' },
    { key: 'isGpsErrorT', value: '0' }
  ],
  plateNormal: [
    { key: 'algorithmProcessingIsNormal', value: '1' },
    { key: 'isGpsErrorT', value: '0' }
  ],
  plateHalf: [
    { key: 'orderStatus', value: '0' },
    { key: 'isFixWeightT', value: '4' },
    { key: 'isGpsErrorT', value: '0' }
  ]
}
export const topNavList = [
  { text: '全部', paramKey: 'all', index: 0 },
  { text: '运输中', paramKey: 'trans', index: 1 },
  { text: '待处理队列', paramKey: 'waitDeal', index: 8 },
  { text: '正常卸货', paramKey: 'normal', index: 2 },
  { text: '预审失败[客户]', paramKey: 'preOrder', index: 14 },
  { text: '异常卸货', paramKey: 'abnormal', index: 12 },
  { text: 'GPS异常[传感器正常]', paramKey: 'gpsErrorSensorNormal', index: 15 },
  { text: 'GPS异常[传感器异常]', paramKey: 'gpsErrorSensorAbnormal', index: 16 },
  { text: '数据异常[传感器正常]', paramKey: 'dataErrorSensorNormal', index: 17 },
  { text: '数据异常[传感器异常]', paramKey: 'dataErrorSensorAbnormal', index: 18 },
  { text: '平台处理[运输中]', paramKey: 'plateHalf', index: 21 },
  { text: '平台处理[正常卸货]', paramKey: 'plateNormal', index: 19 },
  { text: '设备离线', paramKey: 'deviceOffLine', index: 5 },
  { text: '二次上货', paramKey: 'secondLoading', index: 13 },
  { text: '平台问题', paramKey: 'systemMisjudgment', index: 6 },
  { text: '磅单问题', paramKey: 'weightError', index: 7 },
  { text: '断电问题', paramKey: 'blackout', index: 20 },
  { text: '算法问题', paramKey: 'algorithm', index: 9 },
  { text: '硬件问题', paramKey: 'hwVersion', index: 10 },
  { text: 'Matlab算法问题', paramKey: 'MatLab', index: 11 }
]
export const questionNameList = {
  1: '算法问题',
  2: '硬件问题',
  3: '司机问题',
  4: '对接问题',
  5: '运维问题',
  6: '安装问题',
  7: '平台问题',
  8: '磅单问题',
  9: '断电问题'
}
export const questionDetailNameList = {
  100: '其他',
  101: '上货问题',
  102: '卸货问题',
  103: '在途问题',
  200: '其他',
  201: '主机问题',
  202: '传感器问题',
  203: 'GPS问题',
  204: '线材问题',
  300: '其他',
  301: '司机问题',
  400: '其他',
  401: '对接问题',
  500: '其他',
  501: '运维问题',
  502: '未升级',
  503: '系数问题',
  601: '安装问题',
  600: '其他',
  701: '未识别异常',
  702: '算法曲线断裂',
  703: '平台卸货又上货',
  700: '其他',
  801: '磅单问题',
  800: '其他',
  901: '断电问题'
}
