import { reactive, onMounted, ref } from 'vue'
import { twoDecimal, secondToDate } from '@/utils/common'
import { http_clean } from '@/utils/request'
import { Toast } from 'vant'
import { questionNameList, questionDetailNameList } from './constTypes'

export default function (searchParams) {
  const state = reactive({
    btnNum: 'all',
    pullLoading: false, // 下拉刷新加载
    orderList: [],
    listLoading: false, // list 的加载状态
    finished: true, // 是否全部加载完成, 默认为 true，阻止自动触发 list 的 loading
    error: false
  })
  const scrollEl = ref()
  // 分页查询
  const pager = reactive({
    currentPage: 1, // 当前滚动位置的对应页数，滚动事件计算得到的
    pageCount: 1, // 分页总页数
    queryPage: 1, // 当前查询页
    pageSize: 10
  })

  onMounted(() => {
    const params = formatParams()
    load(params)
  })

  // 下拉刷新事件，由于下拉刷新直接取参数
  // 数据返回前不清除原有数据，防止页面闪白
  const onRefresh = () => {
    state.pullLoading = true
    // 重置请求页数
    pager.queryPage = 1
    const params = formatParams()
    load(params)
  }

  // 滑动加载事件
  const onLoad = () => {
    if (!state.finished) {
      // 下滑加载则增加一页
      pager.queryPage++
      const params = formatParams()
      load(params)
    }
  }

  // 列表加载事件
  async function load(params) {
    try {
      const data = await http_clean({
        url: 'orderController/findOrderList.html',
        method: 'post',
        data: params
      })
      // 防止请求出错，一直重复请求，设置状态
      if (!data.isSuccess) {
        // list 的 loading 事件会将查询页自增
        // 加载失败需要将查询页自减
        pager.queryPage--
        // 取消加载
        state.pullLoading = false
        state.listLoading = false
        // 加载失败不显示全部加载完成
        state.finished = false
        // 展示加载失败状态
        state.error = true
        Toast(data.message)
        return
      }
      data.exhibitDatas = data.isSuccess ? data.exhibitDatas : []
      for (let item of data.exhibitDatas) {
        item.sendWeight = twoDecimal(item.sendWeight)
        item.loadTime = secondToDate(item.loadTime)
        item.unloadTime = secondToDate(item.unloadTime)
        item.transportTime = secondToDate(item.transportTime)
        item.exceptionType = getExceptionTypeName(item.exceptionType)
        item['programTypeKey'] = item.programType
        item.programType = getProgramTypeName(item.programType)
      }
      pager.pageCount = data.pageCount
      setTimeout(() => {
        // pullLoading 为 true，下拉刷新
        // 需要清空原有数据
        // pullLoading 为 false，是滑动加载事件
        // 直接在数组尾部压入数据就好
        if (state.pullLoading) {
          state.orderList = []
        }
        state.orderList.push(...data.exhibitDatas)
        // 判断是否加载完毕
        state.finished = pager.queryPage >= pager.pageCount
        state.pullLoading = false
        state.listLoading = false
      }, 200)
    } catch (error) {
      pager.queryPage--
      state.pullLoading = false
      state.listLoading = false
      state.finished = false
      state.error = true
    }
  }

  function formatParams() {
    const startDate = new Date(searchParams.startTime).getTime()
    const endDate = new Date(searchParams.endTime).getTime()
    if (endDate < startDate) {
      Toast('结束时间不能早于开始时间')
      return
    }
    const param = {
      pageSize: pager.pageSize,
      nowPage: pager.queryPage,
      parameters: {
        deviceId: searchParams.deviceId,
        companyId: searchParams.companyId ?? '',
        carNumber: searchParams.carNumber,
        startTime: searchParams.startTime,
        endTime: searchParams.endTime,
        dateType: searchParams.dateType ?? '',
        orderStatus: '',
        orderStatus2: searchParams.orderStatus2 ?? '',
        checkStatus: '',
        detailStatus: searchParams.detailStatus ?? '',
        queryCheckStatus: searchParams.queryCheckStatus ?? '',
        isFixWeight: searchParams.isFixWeight ?? '',
        useTypeL2: searchParams.useTypeL2 ?? '',
        isWaitDetail: '',
        exceptionType: '',
        isMatlab: '',
        isRail: '',
        secondRecord: '',
        algorithmProblemTypes: '',
        programType: searchParams.programType ?? '',
        isUnusualOrder: '',
        isGpsError: searchParams.isGpsError ?? '',
        sensorAbnormal: '',
        algorithmProcessingIsNormal: ''
      }
    }

    let gridPager = JSON.stringify(param)
    return { gridPager }
  }
  const scrollTest = e => {
    let distance = e.target.scrollTop + e.target.getBoundingClientRect().height
    const itemEl = document.querySelector('.list-wrap__item')
    if (!itemEl) return
    // dom 高度
    const innerHeigh = itemEl.getBoundingClientRect().height
    // 获取css属性
    const cssComAttrs = document.defaultView.getComputedStyle(itemEl)
    const marginT = parseFloat(cssComAttrs.marginTop)
    const elHeight = innerHeigh + marginT

    pager.currentPage =
      Math.ceil(distance / (elHeight * 10)) >= pager.pageCount ? pager.pageCount : Math.ceil(distance / (elHeight * 10))
  }

  return {
    scrollEl,
    scrollTest,
    state,
    pager,
    onRefresh,
    onLoad
  }
}
//问题分类
function getExceptionTypeName(key) {
  if (key) {
    return questionNameList[key]
  } else {
    return ''
  }
}
//现象分类
function getProgramTypeName(key) {
  if (key) {
    return questionDetailNameList[key]
  } else {
    return ''
  }
}
