import { reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { http_clean } from '@/utils/request'

let echart_Xdata = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32
]
let echart_GPSData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //GPS信噪比
let echart_NorthData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] //北斗信噪比
const option_GPS = ref({
  backgroundColor: '#EFEFEF',
  xAxis: {
    type: 'category',
    data: echart_Xdata
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: echart_GPSData,
      type: 'bar',
      showBackground: true,
      barCategoryGap: '1px',
      label: {
        show: true,
        position: 'top',
        color: '#333'
      },
      itemStyle: {
        color: function (params) {
          const index_color = params.value
          if (index_color < 11) {
            return '#ff0000'
          } else if (index_color > 10 && index_color < 21) {
            return '#fbff00'
          } else {
            return '#17c50b'
          }
        }
      }
    }
  ],
  grid: {
    left: 35,
    right: 30,
    top: 30,
    bottom: 25
  }
})
const option_North = {
  backgroundColor: '#EFEFEF',
  xAxis: {
    type: 'category',
    data: echart_Xdata,
    z: 10
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: echart_NorthData,
      type: 'bar',
      showBackground: true,
      barCategoryGap: '1px',
      label: {
        show: true,
        position: 'top',
        color: '#333'
      },
      itemStyle: {
        color: function (params) {
          const index_color = params.value
          if (index_color < 11) {
            return '#ff0000'
          } else if (index_color > 10 && index_color < 21) {
            return '#fbff00'
          } else {
            return '#17c50b'
          }
        }
      }
    }
  ],
  grid: {
    left: 35,
    right: 30,
    top: 30,
    bottom: 25
  }
}
export default function signalModule() {
  const store = useStore()
  const { deviceId } = store.state.search
  const state = reactive({
    deviceId,
    TestingTimes: 0, // 发出的请求次数
    answerTimes: 0, // 接受到的返回次数
    frequency: '5',
    DetectionTimes: '10',
    protocolVersion: '2',
    currTestTimes: 0,
    isShowStartBtn: true,
    SignalTestingData: {
      code_04D9: '', //GPS定位卫星
      code_04DA: '', //北斗定位卫星
      code_04D0: '', //定位状态
      code_0407: '', //采集时间
      code_0408: '', //GPS时间
      code_0409: '', //GPS经度
      code_040C: '', //GPS速度
      code_04D8: '', //GPS模块
      code_040B: '', //GPS航向
      code_040A: '', //GPS纬度
      code_041D: '', //信号强度
      code_04D7: '', //GPS天线
      code_04DB: '', //GPS可视卫星
      code_04DC: '', //北斗可视卫星
      code_deviceId: '',
      isShowRedTime: false //GPS时间标红
    }
  })
  // 测试定时器
  let requestTimes = null

  //初始化echart图
  const initSignalChart = () => {
    const echart_GPSData = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ] //GPS信噪比
    option_GPS.value.series[0].data = echart_GPSData

    const echart_NorthData = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ] //北斗信噪比
    option_North.series[0].data = echart_NorthData
  }

  // 开始查询
  const requestStart = () => {
    state.answerTimes = 0
    const vesion = 2
    const id = state.deviceId
    state.isShowStartBtn = false

    //开始请求一次
    requestTesting(vesion, id)
    state.TestingTimes = state.TestingTimes + 1

    requestTimes = setInterval(function () {
      state.TestingTimes = state.TestingTimes + 1
      if (!state.isShowStartBtn) {
        requestTesting(vesion, id)
      }
      if (state.TestingTimes >= parseInt(state.DetectionTimes)) {
        clearInterval(requestTimes)
        state.TestingTimes = 0
        state.isShowStartBtn = true
        return
      }
    }, parseInt(state.frequency) * 1000)
  }
  // 停止查询
  const endSubmit = () => {
    state.isShowStartBtn = true
    state.TestingTimes = 0
    clearInterval(requestTimes)
  }

  //请求接口
  function requestTesting(vesion, deviceId) {
    const params = {
      protocolVersion: vesion,
      deviceId: deviceId
    }
    http_clean({
      url: 'devicemanage/sendCodeForCheckGps.html',
      method: 'post',
      data: params
    }).then(data => {
      if (data.success && deviceId == data.data.code_deviceId) {
        echart_GPSData = [] //清空
        echart_NorthData = [] //清空
        state.answerTimes = state.answerTimes + 1
        const dataSource = data.data

        //计算两个时间的分钟数
        if (dataSource.code_0408) {
          const time1 = interval(dataSource.code_0408, dataSource.code_0407)
          const time2 = interval(dataSource.code_0407, dataSource.code_0408)
          if (time1 >= 30) {
            dataSource.isShowRedTime = true
          }
          if (time2 >= 30) {
            dataSource.isShowRedTime = true
          }
        }

        state.SignalTestingData = dataSource
        //GPS信噪比
        if (dataSource.code_04E0) {
          const code_04E0 = dataSource.code_04E0.split(',')
          const code_04E1 = dataSource.code_04E1.split(',')
          const code_04E2 = dataSource.code_04E2.split(',')
          const code_04E3 = dataSource.code_04E3.split(',')
          const code_04E4 = dataSource.code_04E4.split(',')
          const code_04E5 = dataSource.code_04E5.split(',')
          const code_04E6 = dataSource.code_04E6.split(',')
          const code_04E7 = dataSource.code_04E7.split(',')

          echart_GPSData = echart_GPSData.concat(
            code_04E0,
            code_04E1,
            code_04E2,
            code_04E3,
            code_04E4,
            code_04E5,
            code_04E6,
            code_04E7
          )
        }
        //北斗信噪比
        if (dataSource.code_04E8) {
          const code_04E8 = dataSource.code_04E8.split(',')
          const code_04E9 = dataSource.code_04E9.split(',')
          const code_04EA = dataSource.code_04EA.split(',')
          const code_04EB = dataSource.code_04EB.split(',')
          const code_04EC = dataSource.code_04EC.split(',')
          const code_04ED = dataSource.code_04ED.split(',')
          const code_04EE = dataSource.code_04EE.split(',')
          const code_04EF = dataSource.code_04EF.split(',')

          echart_NorthData = echart_NorthData.concat(
            code_04E8,
            code_04E9,
            code_04EA,
            code_04EB,
            code_04EC,
            code_04ED,
            code_04EE,
            code_04EF
          )
        }

        option_GPS.value.series[0].data = echart_GPSData

        option_North.series[0].data = echart_NorthData
      }
    })
  }
  function interval(faultDate, completeTime) {
    const faultDate_time = faultDate.replace(/-/g, '/') //必须把日期'-'转为'/'
    const faultDate_timestamp = new Date(faultDate_time).getTime()

    const completeTime_time = completeTime.replace(/-/g, '/') //必须把日期'-'转为'/'
    const completeTime_timestamp = new Date(completeTime_time).getTime()

    const usedTime = completeTime_timestamp - faultDate_timestamp //两个时间戳相差的毫秒数
    //计算出小时数
    const leave1 = usedTime % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
    //计算相差分钟数
    const leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
    const minutes = Math.floor(leave2 / 1000) // 秒数

    return minutes
  }
  return {
    state,
    requestTimes,
    option_GPS,
    option_North,
    functions: {
      initSignalChart,
      requestStart,
      endSubmit
    }
  }
}
