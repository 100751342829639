import { reactive, computed, watch } from 'vue'
import http from '@/utils/http'
import { http_clean } from '@/utils/request'
import { carDetailUseList } from '@/utils/types'
import { secondToDate, formatTimeStr, getMilliSecond, getTimeDiff } from '@/utils/common'
import { Toast, Dialog } from 'vant'

const section = () => {
  const state = reactive({
    orderId: '',
    sensorModuleList: [],
    renderReasonSensors: [],
    /**
     * 处理问题提交模块:传感器的渲染
     */
    sensorSelectAll: {
      faultTypeName: '传感器问题',
      programType: '202',
      text: '传感器问题',
      selected: false
    },
    GPSSelectAll: {
      faultTypeName: 'GPS问题',
      programType: '203',
      text: 'GPS问题',
      selected: false
    },
    GPSProblemList: {
      startTime: '',
      endTime: '',
      operator: '',
      operatingTime: '',
      gpsProblem: '',
      selected: false
    },
    // GPS 问题选项
    GPSModuleList: [
      {
        faultTypeName: 'GPS问题',
        programType: '203',
        text: '无GPS',
        code: 'GP1',
        selected: false,
        isGpsError: '3'
      },
      {
        faultTypeName: 'GPS问题',
        programType: '203',
        text: '停车速度平直',
        code: 'GP2',
        selected: false,
        isGpsError: '4'
      },
      {
        faultTypeName: 'GPS问题',
        programType: '203',
        text: '大段丢失',
        code: 'GP3',
        selected: false,
        isGpsError: '5'
      },
      {
        faultTypeName: 'GPS问题',
        programType: '203',
        text: '偶尔丢失',
        code: 'GP4',
        selected: false,
        isGpsError: '6'
      }
    ],
    deviceOffline: {
      faultTypeName: '主机问题',
      programType: '201',
      text: '离线',
      selected: false
    },
    deviceContact: {
      faultTypeName: '主机问题',
      programType: '201',
      text: '接电问题',
      selected: false
    },
    deviceRepairedIspush: {
      text: '限制进厂',
      selected: false
    },
    // 硬件问题的其他选项
    deviceOther: {
      text: '其他',
      faultTypeName: '其他',
      programType: '200',
      selected: false
    },
    // 硬件问题的其他的问题描述
    hardwareIssueDescription: '',
    // 平台的问题的其他的描述
    platformIssueDescription: '',
    algorithmQuestionList: [
      {
        exceptionType: '1',
        programType: '101',
        faultTypeName: '算法问题',
        text: '上货问题',
        selected: false
      },
      {
        exceptionType: '1',
        programType: '103',
        faultTypeName: '算法问题',
        text: '在途问题',
        selected: false
      },
      {
        exceptionType: '1',
        programType: '102',
        faultTypeName: '算法问题',
        text: '卸货问题',
        selected: false
      },
      {
        exceptionType: '7',
        programType: '701',
        faultTypeName: '平台问题',
        text: '未识别异常',
        selected: false
      },
      {
        exceptionType: '7',
        programType: '702',
        faultTypeName: '平台问题',
        text: '算法曲线断裂',
        selected: false
      },
      {
        exceptionType: '7',
        programType: '703',
        faultTypeName: '平台问题',
        text: '平台卸货又上货',
        selected: false
      },
      {
        exceptionType: '7',
        programType: '700',
        faultTypeName: '平台问题',
        text: '其他',
        selected: false
      },
      {
        exceptionType: '5',
        programType: '502',
        faultTypeName: '运维问题',
        text: '未升级',
        selected: false
      },
      {
        exceptionType: '5',
        programType: '503',
        faultTypeName: '运维问题',
        text: '系数问题',
        selected: false
      },
      {
        exceptionType: '8',
        programType: '801',
        faultTypeName: '磅单问题',
        text: '磅单问题',
        selected: false
      },
      {
        exceptionType: '9',
        programType: '901',
        faultTypeName: '断电问题',
        text: '断电问题',
        selected: false
      }
    ],
    deviceSensorArr: [
      { id: 1, text: '通道1', checked: false },
      { id: 2, text: '通道2', checked: false },
      { id: 3, text: '通道3', checked: false },
      { id: 4, text: '通道4', checked: false },
      { id: 5, text: '通道5', checked: false },
      { id: 6, text: '通道6', checked: false },
      { id: 7, text: '通道7', checked: false },
      { id: 8, text: '通道8', checked: false },
      { id: 9, text: '通道9', checked: false },
      { id: 10, text: '通道10', checked: false },
      { id: 11, text: '通道11', checked: false },
      { id: 12, text: '通道12', checked: false },
      { id: 13, text: '通道13', checked: false },
      { id: 14, text: '通道14', checked: false },
      { id: 15, text: '通道15', checked: false },
      { id: 16, text: '通道16', checked: false }
    ],
    // 传感器全部平直,更新至车辆管理表
    isAllStraight: false,
    sensorAxleFront: [], //通道前
    sensorAxleAfter: [], //通道后
    installArray: [], //安装位置
    checkChannel: [], //选中通道数组
    ChannelLen: '', //原始轴的个数
    deviceRepairedIspushFlag: false, // 是否显示取消入场限制
    // 获取的订单信息会全部放入baseInfo
    baseInfo: {
      carNumber: '', // 车牌号码
      companyName: '', // 公司名称
      inFactory: '', // 1  显示提货厂区， 字段为 factoryName
      orderStatus: '',
      checkStatus: '',
      isGpsError: '',
      detailStatus: '', //  0:待处理  1:人工处理  2:算法处理  3:无异常
      sendWeight: '', // 磅单重量
      emptyLoadTime: '', // 进厂时间 订单开始时间:往前推半个小时
      fullLoadTime: '', // 离厂时间
      exceptionRemark: '', // 客户推送
      remark: '', // 判断信息
      repairedUser: '', // 维修人
      lastServiceTime: '', // 最近维修
      repairedRecords: '' // 维修信息
    },
    versionMessage: {
      name: '',
      alarmType: '',
      runStatus: '',
      carUploadDate: '',
      gpsUploadDate: ''
    },
    hideOrshowline: [],
    deviceBaseInfo: {
      companyName: '',
      carNumber: '',
      deviceId: '',
      hwVersion: '',
      useTypeL1: '',
      useTypeL2: '',
      userTypeName: '',
      sensorType: '',
      sensorTypeName: '',
      productNo: '',
      iccid: '',
      driverName: '',
      phone: '',
      inputDate: '',
      installTime: '',
      userNameForCreate: '',
      sensorChannel: [],
      coef: [],
      coefT: [],
      version: '',
      repaired: {},
      logForCoef: {},
      upgradeInfo: {}
    },
    isShowGPSAlert: false,
    isShowOfflineAlert: false,
    questionRecordInfo: [],
    questionRecordTotal: null,
    dataFixedCount: null,
    alertTotalCount: null,
    dataFixInfo: {},
    alertDataInfo: {},
    repairedDeviceInfo: {},
    deviceRealTimeDataInfo: {},
    offlineDiffTime: '',
    weightCount: 0,
    weightData: {},
    renewData: null
  })

  function getDeviceInfoData(deviceId, factoryId) {
    state.deviceBaseInfo.deviceId = deviceId
    getDeviceBasicInfo(deviceId, factoryId)
    getRealTime(deviceId)
  }

  //设备基本信息
  function getDeviceBasicInfo(deviceId, factoryId) {
    http_clean({
      url: 'system/getDeviceBaseInfoByDeviceId.html',
      method: 'post',
      data: { deviceId, factoryId }
    }).then(data => {
      if (data.success && data.data) {
        for (let item in state.deviceBaseInfo) {
          if (item === 'coef' || item === 'coefT' || item === 'sensorChannel') {
            state.deviceBaseInfo[item] = data.data[item] || []
          } else {
            state.deviceBaseInfo[item] = data.data[item] || ''
          }
        }
        state.deviceBaseInfo.userTypeName = getUseTypeNameL2(state.deviceBaseInfo.useTypeL2)
        if (state.deviceBaseInfo.sensorChannel && state.deviceBaseInfo.sensorChannel.length > 0) {
          let temArr = []
          for (let j = 0; j < state.deviceBaseInfo.sensorChannel.length; j++) {
            let obj = {
              value: state.deviceBaseInfo.sensorChannel[j],
              flag: false,
              isFront: '0'
            }
            if (state.deviceBaseInfo.coef) {
              if (state.deviceBaseInfo.coef[+state.deviceBaseInfo.sensorChannel[j] - 1]) {
                obj.flag = true
              }
            }
            if (data.data.sensorAxle && data.data.sensorAxle.length > 0) {
              obj.isFront = data.data.sensorAxle[j]
            }
            temArr.push(obj)
          }

          state.deviceBaseInfo.sensorChannelT = temArr
          loadSensorModule(state.deviceBaseInfo.sensorChannelT)
        } else {
          loadSensorModule([])
        }
        getEditDeviceInfo(deviceId) //编辑设备信息
        if (state.deviceBaseInfo.coef) {
          let coefArr = []
          for (let i = 0; i < state.deviceBaseInfo.coef.length; i++) {
            let _item = state.deviceBaseInfo.coef[i]
            if (_item) {
              let obj = {
                value: _item,
                index: i + 1
              }
              coefArr.push(obj)
            }
          }
          state.deviceBaseInfo.coefT = coefArr
        } else {
          state.deviceBaseInfo.coefT = []
        }
        if (state.deviceBaseInfo.inputDate) {
          // 获取时间查，天 时 分 秒
          state.deviceBaseInfo.installTime = secondToDate(
            getTimeDiff(new Date(), state.deviceBaseInfo.inputDate) / 1000
          )
        }
      }
    })
  }
  // 根据接口获取的传感器信息，初始化传感器数据
  function loadSensorModule(data) {
    state.sensorModuleList = []
    for (let item of data) {
      let obj = {
        flag: item.flag,
        value: item.value,
        text: '传感器' + item.value,
        faultTypeName: '传感器问题',
        programType: '202',
        startTime: '',
        endTime: '',
        operator: '',
        operatingTime: '',
        sensorProblem: '', // 问题选中项
        isFront: item.isFront,
        child: [
          {
            text: '正负52万',
            code: 'SP1',
            value: `传感器${item.value}-正负52万`,
            selected: false
          },
          {
            text: '偶尔52万',
            code: 'SP1-1',
            value: `传感器${item.value}-偶尔52万`,
            selected: false
          },
          {
            text: '经常52万',
            code: 'SP1-2',
            value: `传感器${item.value}-经常52万`,
            selected: false
          },
          {
            text: '乱跳',
            code: 'SP2',
            value: `传感器${item.value}-乱跳`,
            selected: false
          },
          {
            text: '形变小',
            code: 'SP3',
            value: `传感器${item.value}-形变小`,
            selected: false
          },
          {
            text: '方向反',
            code: 'SP4',
            value: `传感器${item.value}-方向反`,
            selected: false
          },
          {
            text: '平直',
            code: 'SP5',
            value: `传感器${item.value}-平直`,
            selected: false
          }
        ]
      }
      state.sensorModuleList.push(obj)
      state.renderReasonSensors.push({ ...obj })
    }
  }
  function getEditDeviceInfo(deviceId) {
    clearEditDeviceInfo()
    http_clean({
      url: 'car/getCarInfoByDeviceId.html',
      method: 'post',
      data: { deviceId: deviceId }
    }).then(data => {
      if (data.success) {
        let result = data.data
        state.isAllStraight = result.isAllStraight ? true : false
        let resultChannel = []
        if (result.sensorChannel) {
          for (let item of result.sensorChannel) {
            if (item && item !== 'null') {
              resultChannel.push(item)
            }
          }
          result.sensorChannel = resultChannel
        }
        for (let item of state.deviceSensorArr) {
          item.checked = false
        }
        if (result.sensorChannel.length > 0) {
          for (let i = 0; i < result.sensorChannel.length; i++) {
            state.deviceSensorArr[result.sensorChannel[i] - 1].checked = true
          }
        }
        if (result.sensorProblemList) {
          if (result.sensorProblemList.length > 0) {
            for (let sensor of result.sensorProblemList) {
              let sensorItem = sensor.sensorProblem.split('-')
              for (let item of state.sensorModuleList) {
                if (item.text == sensorItem[0]) {
                  item.startTime = sensor.startTime
                  item.endTime = sensor.endTime
                  item.operator = sensor.operator
                  item.operatingTime = sensor.operatingTime
                  item.sensorProblem = sensor.sensorProblem
                  for (let lis of item.child) {
                    if (lis.text == sensorItem[1]) {
                      lis.selected = true
                    } else {
                      lis.selected = false
                    }
                  }
                }
              }
              state.renderReasonSensors = deepClone(state.sensorModuleList)
            }
          }
        }
        if (result.gpsProblemEntity) {
          let gps = result.gpsProblemEntity
          state.GPSProblemList.startTime = gps.startTime
          state.GPSProblemList.endTime = gps.endTime
          state.GPSProblemList.operator = gps.operator
          state.GPSProblemList.operatingTime = gps.operatingTime
          state.GPSProblemList.gpsProblem = gps.gpsProblem
          for (let item of state.GPSModuleList) {
            if (item.text == gps.gpsProblem) {
              item.selected = true
              state.GpsSeleted = [item.text]
              state.GPSProblemList.selected = true
            } else {
              item.selected = false
            }
          }
        }

        state.ChannelLen = data.data.carAxleAmount
        //选中通道值
        showLocation(data.data.sensorChannel, data.data.sensorPosition, data.data.carAxleAmount)

        let sensor1, sensor2
        state.sensorAxleFront = []
        state.sensorAxleAfter = []
        const AxleFrontList = JSON.parse(JSON.stringify(state.sensorAxleFront))
        const AxleAfterList = JSON.parse(JSON.stringify(state.sensorAxleAfter))
        // this.sensorAxleAfter = this.deepClone(this.sensorAxleFront); //深拷贝

        for (let j = 0; j < data.data.sensorChannel.length; j++) {
          if (data.data.sensorAxle == null || data.data.sensorAxle.length == 0) {
            sensor1 = {
              mark: data.data.sensorChannel[j],
              text: data.data.sensorChannel[j],
              checked: false,
              isDisable: false
            }

            AxleFrontList.push(sensor1)
            AxleAfterList.push(sensor1)
          } else {
            for (let k = 0; k < data.data.sensorAxle.length; k++) {
              if (data.data.sensorAxle[k] == 0 && k == j) {
                sensor1 = {
                  mark: data.data.sensorChannel[j],
                  text: data.data.sensorChannel[j],
                  checked: false,
                  isDisable: false
                }
                AxleFrontList.push(sensor1)
                AxleAfterList.push(sensor1)
              } else if (data.data.sensorAxle[k] == 1 && k == j) {
                sensor1 = {
                  mark: data.data.sensorChannel[j],
                  text: data.data.sensorChannel[j],
                  checked: true,
                  isDisable: false
                }
                sensor2 = {
                  mark: data.data.sensorChannel[j],
                  text: data.data.sensorChannel[j],
                  checked: false,
                  isDisable: true
                }
                AxleFrontList.push(sensor1)
                AxleAfterList.push(sensor2)
              } else if (data.data.sensorAxle[k] == 2 && k == j) {
                sensor1 = {
                  mark: data.data.sensorChannel[j],
                  text: data.data.sensorChannel[j],
                  checked: false,
                  isDisable: true
                }
                sensor2 = {
                  mark: data.data.sensorChannel[j],
                  text: data.data.sensorChannel[j],
                  checked: true,
                  isDisable: false
                }
                AxleFrontList.push(sensor1)
                AxleAfterList.push(sensor2)
              }
            }
          }
          state.sensorAxleFront = deepClone(AxleFrontList) //深拷贝
          state.sensorAxleAfter = deepClone(AxleAfterList) //深拷贝
        }
      } else {
        Toast(data.message)
      }
    })
  }
  //显示安装位置
  function showLocation(sensorChannel, value, Lens) {
    const installArray = [
      {
        index: 1,
        positionLMR: [
          {
            position: 'L',
            sensorChannel: []
          },
          {
            position: 'M',
            sensorChannel: []
          },
          {
            position: 'R',
            sensorChannel: []
          }
        ]
      },
      {
        index: 2,
        positionLMR: [
          {
            position: 'L',
            sensorChannel: []
          },
          {
            position: 'M',
            sensorChannel: []
          },
          {
            position: 'R',
            sensorChannel: []
          }
        ]
      },
      {
        index: 3,
        positionLMR: [
          {
            position: 'L',
            sensorChannel: []
          },
          {
            position: 'M',
            sensorChannel: []
          },
          {
            position: 'R',
            sensorChannel: []
          }
        ]
      },
      {
        index: 4,
        positionLMR: [
          {
            position: 'L',
            sensorChannel: []
          },
          {
            position: 'M',
            sensorChannel: []
          },
          {
            position: 'R',
            sensorChannel: []
          }
        ]
      },
      {
        index: 5,
        positionLMR: [
          {
            position: 'L',
            sensorChannel: []
          },
          {
            position: 'M',
            sensorChannel: []
          },
          {
            position: 'R',
            sensorChannel: []
          }
        ]
      },
      {
        index: 6,
        positionLMR: [
          {
            position: 'L',
            sensorChannel: []
          },
          {
            position: 'M',
            sensorChannel: []
          },
          {
            position: 'R',
            sensorChannel: []
          }
        ]
      }
    ] //安装位置
    let sensor
    state.checkChannel = []
    if (sensorChannel != 0) {
      for (let i = 0; i < sensorChannel.length; i++) {
        sensor = {
          mark: sensorChannel[i],
          text: sensorChannel[i],
          checked: false,
          isDisable: false
        }
        state.checkChannel.push(sensor)
        state.checkChannel.sort(compare('mark'))
      }
    }
    let len
    if (value != null && value.length != 0) {
      len = value[value.length - 1].index
    } else {
      if (Lens == '') {
        Lens = 0
      }
      len = Lens
    }
    if (value != 0 && value != null) {
      for (let j = 0; j < installArray.length; j++) {
        for (let k = 0; k < value.length; k++) {
          if (installArray[j].index == value[k].index) {
            for (let f = 0; f < value[k].positionLMR.length; f++) {
              if (value[k].positionLMR[f].position == 'L') {
                installArray[j].positionLMR[0].sensorChannel = value[k].positionLMR[f].sensorChannel
                continue
              }
              if (value[k].positionLMR[f].position == 'M') {
                installArray[j].positionLMR[1].sensorChannel = value[k].positionLMR[f].sensorChannel
                continue
              }
              if (value[k].positionLMR[f].position == 'R') {
                installArray[j].positionLMR[2].sensorChannel = value[k].positionLMR[f].sensorChannel
                continue
              }
            }
            break
          }
        }
      }
      if (len > Lens || len == Lens) {
        if (len != 0) {
          installArray.splice(len, 6 - len)
        }
      } else {
        if (len != 0) {
          installArray.splice(Lens, 6 - Lens)
        }
      }

      state.installArray = deepClone(installArray)
    } else {
      if (len != 0) {
        installArray.splice(len, 6 - len)
      }
      state.installArray = deepClone(installArray)
    }

    showCheck(state.checkChannel)
  }
  function showCheck(value) {
    let showCheckList = []
    for (let a = 0; a < state.installArray.length; a++) {
      for (let b = 0; b < state.installArray[a].positionLMR.length; b++) {
        for (let c = 0; c < state.installArray[a].positionLMR[b].sensorChannel.length; c++) {
          showCheckList.push(state.installArray[a].positionLMR[b].sensorChannel[c])
        }
      }
    }

    for (let i = 0; i < value.length; i++) {
      for (let j = 0; j < showCheckList.length; j++) {
        if (value[i].mark == showCheckList[j]) {
          value[i].isDisable = true
          break
        }
      }
    }
    state.checkChannel = deepClone(value)
  }
  function clearEditDeviceInfo() {
    state.GPSSelectAll.selected = false
    state.sensorSelectAll.selected = false
    state.deviceOffline.selected = false
    state.deviceContact.selected = false
    state.deviceRepairedIspush.selected = false
    state.deviceOther.selected = false
    state.hardwareIssueDescription = ''
    state.platformIssueDescription = ''

    for (let item of state.GPSModuleList) {
      item.selected = false
    }
    for (let item of state.sensorModuleList) {
      item.startTime = ''
      item.endTime = ''
      item.operator = ''
      item.operatingTime = ''
      item.sensorProblem = ''
      for (let lis of item.child) {
        lis.selected = false
      }
    }
    for (let item of state.deviceSensorArr) {
      item.checked = false
    }
    for (let item of state.algorithmQuestionList) {
      item.selected = false
    }
    state.GPSProblemList = {
      startTime: '',
      endTime: '',
      operator: '',
      operatingTime: '',
      gpsProblem: '',
      selected: false
    }
  }
  function getRealTime(deviceId) {
    http_clean({
      url: 'system/getRealTimeData.html',
      method: 'post',
      data: { deviceId }
    }).then(result => {
      if (!result.success) return
      const { data } = result
      state.versionMessage.name = data.version
      state.versionMessage.runStatus = data.runStatus
      state.versionMessage.carUploadDate = data.carUploadDate
      state.versionMessage.gpsUploadDate = data.gpsUploadDate
      if (data.alarmType == '1' || data.alarmType == '2') {
        state.versionMessage.alarmType = '[断电中]'
      } else if (data.alarmType == '0' || !data.alarmType) {
        state.versionMessage.alarmType = '[未知原因]'
      } else if (data.alarmType == '3') {
        state.versionMessage.alarmType = '[异常断电]'
      }
      if (state.versionMessage.carUploadDate && state.versionMessage.gpsUploadDate) {
        // 获取时间差绝对值 单位毫秒
        let abs = Math.abs(getTimeDiff(state.versionMessage.carUploadDate, state.versionMessage.gpsUploadDate))
        abs >= 30 * 60 * 1000 ? (state.versionMessage.isShowGPS = true) : (state.versionMessage.isShowGPS = false)
      }
      let coefArr = []
      if (!result.data.coef) return
      for (let i = 0; i < result.data.coef.length; i++) {
        let item = result.data.coef[i]
        if (item) {
          let vname = '通道' + (i + 1)
          coefArr.push(vname + ':' + item)
          state.hideOrshowline.push(vname)
        }
      }
      let resultString = ''
      for (let _i = 0; _i < coefArr.length; _i++) {
        if (_i < coefArr.length - 1) {
          resultString += coefArr[_i] + ','
        } else {
          resultString += coefArr[_i]
        }
      }
      state.versionMessage.versionString = resultString
    })
  }
  // form 相关功能
  const formFields = reactive({
    startTime_problem: '',
    endTime_problem: '',
    zeroBeforeDate: '',
    upStartDate: '',
    upEndDate: '',
    downStartDate: '',
    downEndDate: '',
    zeroAfterDate: '',
    startWeight: '',
    weight: '',
    endWeight: '',
    isWave: false,
    waveWeight: '',
    isDiagonal: false,
    changeStatus: false
  })
  const pickerState = reactive({
    datePickerShow: false,
    pickedDate: new Date(),
    dateKey: '',
    numKeyShow: false, // 数字输入
    numInputKey: ''
  })
  const operateState = reactive({
    id: '', // 订单ID
    deviceId: '',
    deviceRepairedIspushFlag: false,
    refreshFunction: null
  })
  const pickTime = key => {
    pickerState.pickedDate = new Date()
    if (formFields[key]) pickerState.pickedDate = new Date(formFields[key])
    pickerState.dateKey = key
    pickerState.datePickerShow = true
  }
  const confirmDate = date => {
    pickerState.datePickerShow = false
    const key = pickerState.dateKey
    formFields[key] = formatTimeStr(date)
    pickerState.dateKey = ''
  }
  const cancelDate = () => {
    pickerState.datePickerShow = false
    pickerState.dateKey = ''
  }
  const showNumInput = key => {
    pickerState.numInputKey = key
    pickerState.numKeyShow = true
  }
  // 数字输入
  const onNumInput = val => {
    const key = pickerState.numInputKey
    formFields[key] = formFields[key] + val
  }
  // 删除数字
  const onNumDelete = () => {
    const key = pickerState.numInputKey
    if (formFields[key] === '') return
    formFields[key] = formFields[key].substring(0, formFields[key].length - 1)
  }
  //GPS已处理
  const GPSDealWith = () => {
    let reqdata = {
      id: operateState.id,
      exceptionType: '2',
      programType: '203',
      detailStatus: '1',
      gpsFlag: '1'
    }
    http({
      loadText: '请求发送中...',
      url: 'orderController/updateOrderStatus.html',
      method: 'post',
      data: reqdata
    }).then(data => {
      Toast(data.message)
      if (data.success) {
        const { refreshFunction } = operateState
        refreshFunction(operateState.id)
      }
    })
  }
  //订单详情  重新审核
  const reserveOrder = () => {
    let changeStatus = formFields.changeStatus
    Dialog.confirm({
      title: '提示',
      message: '您确定重新审核吗？',
      confirmButtonColor: '#1989fa'
    }).then(() => {
      let reqdata = {
        id: operateState.id,
        changeStatus
      }
      http({
        loadText: '请求发送中...',
        url: 'orderController/reviewOrder.html',
        method: 'post',
        data: reqdata
      }).then(res => {
        Toast(res.message)
        if (res.success) {
          const { refreshFunction } = operateState
          refreshFunction(operateState.id)
        }
      })
    })
  }

  //手动执行算法
  const runMatlab = () => {
    if (formFields.zeroBeforeDate && formFields.zeroAfterDate) {
      if (formFields.upEndDate) {
        if (getTimeDiff(formFields.zeroBeforeDate, formFields.upEndDate) > 0) {
          Toast('上货前置零时间不能大于上货结束时间')
          return
        }
        if (getTimeDiff(formFields.upEndDate, formFields.zeroAfterDate) > 0) {
          Toast('上货结束时间不能大于卸货后置零时间')
          return
        }
      }
      if (getTimeDiff(formFields.zeroBeforeDate, formFields.zeroAfterDate) > 0) {
        Toast('上货前置零时间不能大于卸货后置零时间')
        return
      }
      if (getTimeDiff(new Date(), formFields.zeroAfterDate) < 0) {
        Toast('卸货后置零时间不能大于当前时间')
        return
      }
    } else {
      Toast('上货前置零时间,卸货后置零时间不能为空')
      return
    }
    let reqdata = {
      deviceId: operateState.deviceId,
      startTime: formatTimeStr(formFields.zeroBeforeDate),
      endTime: formatTimeStr(formFields.zeroAfterDate),
      fullLoadTime: formatTimeStr(formFields.upEndDate)
    }
    Dialog.confirm({
      title: '提示',
      message: '您确定要执行算法？',
      confirmButtonColor: '#1989fa'
    }).then(() => {
      http({
        loadText: '请求发送中...',
        url: 'orderController/fixWeightByHand.html',
        method: 'post',
        data: reqdata
      }).then(data => {
        Toast(data.message)
        if (data.success) {
          const { refreshFunction } = operateState
          refreshFunction(operateState.id)
        }
      })
    })
  }
  //取消进厂限制
  const cancelDeviceIspush = () => {
    if (state.deviceBaseInfo.repaired.restrictTime) {
      http({
        loadText: '请求发送中...',
        url: 'deviceRepaired/removeRestrictions.html',
        method: 'post',
        data: { id: state.deviceBaseInfo.repaired.id }
      }).then(data => {
        Toast(data.message)
      })
    }
    http({
      loadText: '请求发送中...',
      url: 'deviceRepaired/updateDeviceStatus.html',
      method: 'post',
      data: { deviceId: state.deviceBaseInfo.deviceId }
    }).then(data => {
      Toast(data.message)
      initIspushData()
    })
  }
  //初始化是否进场限制读取
  function initIspushData() {
    const { deviceId } = state.deviceBaseInfo
    http_clean({
      url: 'deviceRepaired/checkDeviceStatus.html',
      method: 'post',
      data: { deviceId }
    }).then(data => {
      if (data.success) {
        data.data.code ? (state.deviceRepairedIspushFlag = true) : (state.deviceRepairedIspushFlag = false)
      }
    })
  }
  //待处理
  const waitDealWith = () => {
    let mess = ''
    if (state.baseInfo.isWaitDetail == '1') {
      mess = '取消'
    }
    Dialog.confirm({
      title: '提示',
      message: '确定' + mess + '数据待处理吗？',
      confirmButtonColor: '#1989fa'
    }).then(() => {
      let reqdata = {
        id: state.orderId,
        waitDetail: state.baseInfo.isWaitDetail ? '0' : '1'
      }
      http({
        loadText: '请求发送中...',
        url: 'orderController/editWaitDetailOrder.html',
        method: 'post',
        data: reqdata
      }).then(res => {
        if (res.success) {
          const getOrderInfo = state.renewData
          getOrderInfo(state.orderId)
        }
      })
    })
  }
  //数据优化提交
  const makeData = () => {
    let exceptionType, programType
    if (state.sensorSelectAll.selected && !exceptionType && !programType) {
      exceptionType = '2'
      programType = '202'
    }
    if (state.GPSSelectAll.selected && !exceptionType && !programType) {
      exceptionType = '2'
      programType = '203'
    }
    if (state.deviceOffline.selected && !exceptionType && !programType) {
      exceptionType = '2'
      programType = '201'
    }
    if (!exceptionType && !programType) {
      for (let item of state.algorithmQuestionList) {
        if (item.selected) {
          exceptionType = item.exceptionType
          programType = item.programType
        }
      }
    }
    if (!exceptionType || !programType) {
      Toast('请选择问题分类')
      return
    }
    if (exceptionType == '1') {
      http({
        loadText: '请求发送中...',
        url: 'orderController/editWaitDetailOrder.html',
        method: 'post',
        data: {
          id: state.orderId,
          isMatlab: '1'
        }
      })
    }
    const deviceId = state.deviceBaseInfo.deviceId
    const upStartDate = formFields.upStartDate
    const upEndDate = formFields.upEndDate
    const weight = formFields.weight
    const downStartDate = formFields.downStartDate
    const downEndDate = formFields.downEndDate
    const zeroBeforeDate = formFields.zeroBeforeDate
    const zeroAfterDate = formFields.zeroAfterDate
    const startWeight = formFields.startWeight
    const endWeight = formFields.endWeight
    if (deviceId == '') {
      Toast('请选择车牌号')
      return
    }

    const isWave = formFields.isWave //返回 true/false
    const isDiagonal = formFields.isDiagonal //上卸货曲线,返回 true/false
    if (zeroBeforeDate && upStartDate) {
      if (getTimeDiff(zeroBeforeDate, upStartDate) > 0) {
        Toast('上货前置零时间不能大于上货时间')
        return
      }
    }
    if (upStartDate && upEndDate) {
      if (getTimeDiff(upStartDate, upEndDate) > 0) {
        Toast('上货开始时间不能大于上货结束时间')
        return
      }
    }
    if (upEndDate && downStartDate) {
      if (getTimeDiff(upEndDate, downStartDate) > 0) {
        Toast('上货结束时间不能大于卸货开始时间')
        return
      }
    }
    if (downStartDate && downEndDate) {
      if (getTimeDiff(downStartDate, downEndDate) > 0) {
        Toast('卸货开始时间不能大于卸货结束时间')
        return
      }
    }
    if (downEndDate && zeroAfterDate) {
      if (getTimeDiff(downEndDate, zeroAfterDate) > 0) {
        Toast('卸货结束时间不能大于卸货后置零结束时间')
        return
      }
    }
    let isTime = timeInProblemStartEnd()
    if (!isTime) return
    Dialog.confirm({
      title: '提示',
      message: '是否确认所有时间段没问题，然后开始进行数据替换吗？',
      confirmButtonColor: '#1989fa'
    }).then(() => {
      http({
        loadText: '请求发送中...',
        type: 'POST',
        url: 'system/makedata.html',
        data: {
          deviceId: deviceId,
          upStartDate: upStartDate,
          upEndDate: upEndDate,
          weight: weight,
          downStartDate: downStartDate,
          downEndDate: downEndDate,
          isWave: isWave,
          zeroBeforeDate: zeroBeforeDate,
          zeroAfterDate: zeroAfterDate,
          isDiagonal: isDiagonal,
          startWeight: startWeight,
          endWeight: endWeight,
          waveWeight: formFields.waveWeight,
          exceptionType: exceptionType,
          programType: programType,
          orderId: state.orderId
        }
      }).then(result => {
        Toast(result.massage)
      })
    })
  }

  //撤回
  const recall = () => {
    const deviceId = state.deviceBaseInfo.deviceId
    const zeroBeforeDate = formFields.zeroBeforeDate
    const zeroAfterDate = formFields.zeroAfterDate
    if (deviceId == '') {
      Toast('请选择车牌号')
      return
    }
    if (!zeroBeforeDate) {
      Toast('上货前置零起始时间为空')
      return
    }
    if (!zeroAfterDate) {
      Toast('卸货后置零结束间为空')
      return
    }
    Dialog.confirm({
      title: '提示',
      message: '是否确认所有时间段没问题,然后开始撤销数据吗？',
      confirmButtonColor: '#1989fa'
    }).then(() => {
      http({
        loadText: '请求发送中...',
        type: 'POST',
        url: '/system/recall.html',
        data: {
          deviceId: deviceId,
          upStartDate: zeroBeforeDate,
          downEndDate: zeroAfterDate
        }
      }).then(result => {
        const getOrderInfo = operateState.refreshFunction
        getOrderInfo(state.orderId)
        Toast(result.message)
      })
    })
  }

  //订单无异常[算法处理]
  const dealOrderAlgorithm = status => {
    //		this.jsonApiService.confirm_message('确定订单处理为无异常？').then(()=>{
    let reqdata = {
      id: state.orderId,
      detailStatus: status
    }
    http({
      loadText: '请求发送中...',
      url: 'orderController/updateOrderStatus.html',
      method: 'post',
      data: reqdata
    }).then(data => {
      Toast(data.message)
      if (data.success) {
        const getOrderInfo = operateState.refreshFunction
        getOrderInfo(state.orderId)
      }
    })
  }
  // 传感器问题选择
  const sensorLisSelected = (outerObj, index) => {
    outerObj.child.forEach((el, i) => {
      if (i !== index) el.selected = false
    })
  }
  // GPS问题选择
  const GPSLisSelected = index => {
    state.GPSModuleList.forEach((el, i) => {
      if (i !== index) el.selected = false
    })
  }

  const deviceOtherClick = () => {
    if (!state.deviceOther.selected) {
      state.hardwareIssueDescription = ''
    }
  }
  // isMatlib : 是否是matlib点击事件
  const algorithmLisSelected = text => {
    for (let lis of state.algorithmQuestionList) {
      if (lis.text !== text) lis.selected = false
    }
    // 如果选择的不是其他
    if (text !== '其他') {
      state.platformIssueDescription = ''
    }
  }
  watch(
    () => state.sensorModuleList,
    newData => {
      // 将所有传感器的 child，拼接成一个数组
      const newState = deepClone(newData).reduce((acc, current) => acc.concat(current.child), [])
      // 存在选中的，selectAll 设为 true
      state.sensorSelectAll.selected = newState.findIndex(el => el.selected) >= 0
    },
    { deep: true }
  )
  watch(
    () => state.GPSModuleList,
    newData => {
      state.GPSSelectAll.selected = newData.findIndex(el => el.selected) >= 0
    },
    { deep: true }
  )
  //添加设备维修
  const addDeviceRepaired = () => {
    let data = []
    let sensorProblem = '',
      gpsProblem = '',
      sensorFlag,
      gpsFlag
    if (state.sensorSelectAll.selected) {
      sensorFlag = 1
      for (let item of state.sensorModuleList) {
        for (let lis of item.child) {
          if (lis.selected) {
            if (sensorProblem) {
              sensorProblem += ',' + item.text + '-' + lis.text
            } else {
              sensorProblem += item.text + '-' + lis.text
            }
          }
        }
      }
    } else {
      sensorFlag = 0
    }
    if (state.GPSSelectAll.selected) {
      gpsFlag = 1
      for (let item of state.GPSModuleList) {
        if (item.selected) {
          gpsProblem = item.text
        }
      }
    } else {
      gpsFlag = 0
    }
    if (sensorProblem) {
      let sensorObj = {
        programType: '202',
        deviceId: state.deviceBaseInfo.deviceId,
        carNumber: state.deviceBaseInfo.carNumber,
        faultTypeName: '传感器问题',
        faultTime: formFields.startTime_problem,
        faultPhenomenon: sensorProblem.split(','),
        isPush: state.deviceRepairedIspush.selected ? '1' : ''
      }
      data.push(sensorObj)
    }
    if (gpsProblem) {
      let gpsObj = {
        programType: '203',
        deviceId: state.deviceBaseInfo.deviceId,
        carNumber: state.deviceBaseInfo.carNumber,
        faultTypeName: 'GPS问题',
        faultTime: formFields.startTime_problem,
        faultPhenomenon: gpsProblem.split(','),
        isPush: state.deviceRepairedIspush.selected ? '1' : ''
      }
      data.push(gpsObj)
    }
    // 因为离线和接电问题是同一种类型的问题，所以在这里进行合并处理 faultPhenomenon: ['离线', '接电问题']
    if (state.deviceOffline.selected || state.deviceContact.selected) {
      const offlineObj = {
        programType: state.deviceOffline.programType,
        deviceId: state.deviceBaseInfo.deviceId,
        carNumber: state.deviceBaseInfo.carNumber,
        faultTypeName: '主机问题',
        faultTime: formFields.startTime_problem,
        faultPhenomenon: [],
        isPush: state.deviceRepairedIspush.selected ? '1' : ''
      }
      if (state.deviceOffline.selected) {
        offlineObj.faultPhenomenon.push(state.deviceOffline.text)
      }
      if (state.deviceContact.selected) {
        offlineObj.faultPhenomenon.push(state.deviceContact.text)
      }
      data.push(offlineObj)
    }
    // 其他
    if (state.deviceOther.selected) {
      let deviceOtherObj = {
        programType: state.deviceOther.programType,
        deviceId: state.deviceBaseInfo.deviceId,
        carNumber: state.deviceBaseInfo.carNumber,
        faultTypeName: '其他',
        faultTime: formFields.startTime_problem,
        faultPhenomenon: state.hardwareIssueDescription.split(';;;'), // 形成一个数组
        isPush: state.deviceRepairedIspush.selected ? '1' : ''
      }
      data.push(deviceOtherObj)
    }
    if (
      !sensorProblem &&
      !gpsProblem &&
      !state.deviceOffline.selected &&
      !state.deviceContact.selected &&
      !state.deviceOther.selected
    ) {
      Toast('请选择故障类型')
      return
    }
    let reqdata = { list: JSON.stringify(data), sensor: sensorFlag, gps: gpsFlag }

    // 限制车辆的“开始时间” 要小于“ 维修时间”，做个弹框，提示内容：“ 粤B33322 于[xxxx-xx-xx xx:xx:xx]已维修，请与[xxx]（维修人） 核实维修情况 ”，是，继续录入维修，否，取消该次操作
    let selectTime = new Date(formFields.startTime_problem).getTime()
    let lastServiceTime = state.baseInfo.lastServiceTime
    let lastTime = new Date(lastServiceTime).getTime()
    // 点击了限制进厂 并且 选择的时间小于最后一次维修的时间 就进行提示
    if (state.deviceRepairedIspush.selected && selectTime < lastTime) {
      let carNumber = state.baseInfo.carNumber
      let repairedUser = state.baseInfo.repairedUser
      // 提示
      Dialog.confirm({
        title: '提示',
        message: carNumber + '于 [' + lastServiceTime + '] 已维修，请与 [' + repairedUser + ']（维修人）核实维修情况',
        confirmButtonColor: '#1989fa',
        confirmButtonText: '继续录入'
      }).then(() => {
        http({
          loadText: '请求发送中...',
          url: 'deviceRepaired/insertDeviceRepairedBatch.html',
          method: 'post',
          data: reqdata
        }).then(data => {
          Toast(data.message)
          if (data.success) {
            initIspushData()
          }
        })
      })
    } else {
      http({
        loadText: '请求发送中...',
        url: 'deviceRepaired/validateRecord.html',
        method: 'post',
        data: {
          deviceId: state.baseInfo.deviceId
        }
      }).then(result => {
        if (result.valid) {
          Dialog.confirm({
            confirmButtonColor: '#1989fa',
            message: result.message + '确认要继续提交?'
          }).then(() => {
            http({
              loadText: '请求发送中...',
              url: 'deviceRepaired/insertDeviceRepairedBatch.html',
              method: 'post',
              data: reqdata
            }).then(data => {
              Toast(data.message)
              if (data.success) {
                initIspushData()
              }
            })
          })
        } else {
          http({
            loadText: '请求发送中...',
            url: 'deviceRepaired/insertDeviceRepairedBatch.html',
            method: 'post',
            data: reqdata
          }).then(data => {
            Toast(data.message)
            if (data.success) {
              initIspushData()
            }
          })
        }
      })
    }
  }
  //标记GPS问题
  const makerGPS = () => {
    let exceptionType, programType, isGpsError
    if (state.GPSSelectAll.selected) {
      for (let item of state.GPSModuleList) {
        if (item.selected) {
          exceptionType = item.exceptionType
          programType = item.programType
          isGpsError = item.isGpsError
        }
      }
    } else {
      Toast('请选择GPS问题')
      return
    }
    if (!isGpsError) {
      Toast('请选择GPS问题分类')
      return
    }
    let reqdata = {
      id: state.orderId,
      exceptionType: exceptionType,
      programType: programType,
      isGpsError: isGpsError
    }
    http({
      loadText: '请求发送中...',
      url: 'orderController/editWaitDetailOrder.html',
      method: 'post',
      data: reqdata
    }).then(res => {
      Toast(res.message)
    })
  }
  //更新设备状态,更新至车辆管理表
  const updateDeviceStatus = () => {
    let sensorProblem = '',
      gpsProblem = '',
      sensorFlag,
      gpsFlag
    for (let item of state.sensorModuleList) {
      for (let lis of item.child) {
        if (lis.selected) {
          if (sensorProblem) {
            sensorProblem += ',' + item.text + '-' + lis.text
          } else {
            sensorProblem += item.text + '-' + lis.text
          }
        }
      }
    }
    for (let item of state.GPSModuleList) {
      if (item.selected) {
        gpsProblem = item.text
      }
    }
    sensorFlag = state.sensorSelectAll.selected ? 1 : 0
    gpsFlag = state.GPSSelectAll.selected ? 1 : 0
    if (!sensorFlag && !gpsFlag && !state.isAllStraight) {
      Toast('请选择问题分类')
      return
    }
    // 如果勾选GPS问题,标记
    if (gpsFlag && gpsProblem) {
      makerGPS()
    }
    let reqdata = {
      isAllStraight: state.isAllStraight ? 1 : 0,
      sensor: sensorFlag,
      gps: gpsFlag,
      deviceId: state.deviceBaseInfo.deviceId,
      sensorProblem: sensorProblem,
      gpsProblem: gpsProblem,
      startDate: formFields.startTime_problem,
      endDate: formFields.endTime_problem
    }
    http({
      loadText: '请求发送中...',
      url: 'car/updateCarStatus.html',
      method: 'post',
      data: reqdata
    }).then(data => {
      Toast(data.message)
      const getOrderInfo = state.renewData
      getOrderInfo(state.orderId)
    })
  }

  /*
    工具函数
   */

  //判断时间是否在问题记录时间区间内
  function timeInProblemStartEnd() {
    let result = true
    if (!formFields.startTime_problem || !formFields.endTime_problem) {
      Toast('问题记录起始时间不能为空')
      result = false
      return result
    }
    let stNum_pb = getMilliSecond(formFields.startTime_problem)
    let etNum_pb = getMilliSecond(formFields.endTime_problem)
    if (!stNum_pb || !etNum_pb) {
      Toast('问题记录起始时间格式不正确')
      result = false
      return result
    }

    const checkArr = [
      {
        name: '上货前置零起始时间',
        time: formFields.zeroBeforeDate
      },
      {
        name: '上货点起始时间',
        time: formFields.upStartDate
      },
      {
        name: '上货点结束时间',
        time: formFields.upEndDate
      },
      {
        name: '卸货点起始时间',
        time: formFields.downStartDate
      },
      {
        name: '卸货点结束时间',
        time: formFields.downEndDate
      },
      {
        name: '卸货后置零结束时间',
        time: formFields.zeroAfterDate
      }
    ]

    for (let item of checkArr) {
      if (item.time) {
        let _tn = getMilliSecond(item.time)
        if (_tn < stNum_pb || _tn > etNum_pb) {
          Toast(item.name + '需要在问题起始结束时间内')
          result = false
          break
        }
      }
    }
    return result
  }
  //更新订单状态
  const updateOrderStatus = () => {
    let exceptionType, programType
    if (state.sensorSelectAll.selected && !exceptionType && !programType) {
      exceptionType = '2'
      programType = '202'
    }
    if (state.GPSSelectAll.selected && !exceptionType && !programType) {
      exceptionType = '2'
      programType = '203'
    }
    if ((state.deviceOffline.selected || state.deviceContact.selected) && !exceptionType && !programType) {
      exceptionType = '2'
      programType = '201'
    }
    // 硬件问题的其他选项
    if (state.deviceOther.selected && !exceptionType && !programType) {
      exceptionType = '2'
      programType = '200'
    }
    if (!exceptionType && !programType) {
      for (let item of state.algorithmQuestionList) {
        if (item.selected) {
          exceptionType = item.exceptionType
          programType = item.programType
          //					checkStatus = item.checkStatus;
        }
      }
    }
    if (!exceptionType || !programType) {
      Toast('请选择问题分类')
      return
    }
    if (exceptionType == '1') {
      http_clean('orderController/editWaitDetailOrder.html', { id: state.baseInfo.id, isMatlab: '1' })
    }
    let reqdata = {
      id: state.baseInfo.id,
      exceptionType: exceptionType,
      programType: programType,
      detailStatus: '1',
      hardwareIssueDescription: state.hardwareIssueDescription,
      platformIssueDescription: state.platformIssueDescription
    }
    http_clean({
      url: 'orderController/updateOrderStatus.html',
      method: 'post',
      data: reqdata
    }).then(data => {
      Toast(data.message)
      if (data.success) {
        const getOrderInfo = state.renewData
        getOrderInfo(state.orderId)
      }
    })
  }
  return {
    state,
    getDeviceInfoData,
    initIspushData,
    baseFuncs: {
      cancelDeviceIspush,
      waitDealWith
    },
    getRealTime,
    formFields,
    pickerState,
    operateState,
    formFuncs: {
      pickTime,
      confirmDate,
      cancelDate,
      showNumInput,
      onNumInput,
      onNumDelete,
      GPSDealWith,
      reserveOrder,
      runMatlab,
      makeData,
      recall,
      dealOrderAlgorithm,
      sensorLisSelected,
      GPSLisSelected,
      deviceOtherClick,
      algorithmLisSelected,
      updateDeviceStatus,
      addDeviceRepaired,
      makerGPS,
      updateOrderStatus
    },
    matlabProbs: computed(() => state.algorithmQuestionList.filter(el => el.exceptionType === '1')),
    plateProbs: computed(() => state.algorithmQuestionList.filter(el => el.exceptionType !== '1'))
  }
}
//根据编码映射对应车辆类型名称
function getUseTypeNameL2(key) {
  if (key) {
    return carDetailUseList[key]
  } else {
    return ''
  }
}
//深拷贝浅拷贝
function deepClone(obj) {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
function compare(property) {
  return function (a, b) {
    const value1 = a[property]
    const value2 = b[property]
    return value1 - value2
  }
}
export default section
