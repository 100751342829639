<template>
  <div class="cover-page">
    <NavBar />
    <div class="container">
      <div class="card">
        <div class="option line">
          <div class="option__label">车牌号码：</div>
          <div class="option__content">{{ baseState.carNumber }}</div>
        </div>

        <div class="option line">
          <div class="option__label">设备ID：</div>
          <div class="option__content">
            {{ baseState.deviceId }}&nbsp;&nbsp;
            <span :class="['online-tag', baseState.runStatus === '1' ? 'green' : 'red']">
              {{ baseState.runStatus === '1' ? '在线' : '离线' }}
            </span>
          </div>

          <div class="option__operate">
            <van-button type="primary" size="small" @click="restartDevice" :disabled="isRequesting">重启</van-button>
          </div>
        </div>

        <div class="option line">
          <div class="option__label">传感器：</div>
          <div class="option__content" id="sensorRender">
            <div v-for="(item, i) in sensorObj" :key="i" style="margin-right: 20px; display: flex; align-items: center">
              <NewBadge :tag="item.tag" :bg="item.active ? 'background-color:#07c160' : 'background-color:#777'">
                <template #content>{{ item.sensor }}</template>
              </NewBadge>
              <span :style="item.text == '正常' ? 'color:#07c160' : 'color:#ee0a24'">
                {{ item.text }}
              </span>
            </div>
          </div>
        </div>

        <div class="option line">
          <div class="option__label">新主机ID：</div>
          <div class="option__content">
            <input
              type="text"
              class="freqInput"
              v-model="listState.searchKey"
              placeholder="请输入新的主机ID，不少于三位"
              @input="listState.searchKey = listState.searchKey.replace(/[^\d]+/g, '')"
            />
          </div>
          <div class="option__operate">
            <van-button type="primary" size="small" @click="fetchList(true)" :disabled="isRequesting">搜索</van-button>
          </div>
        </div>
      </div>

      <div class="device-list">
        <div class="choseDevice">
          <div class="choseDevice-text">
            <input type="text" v-model="newDeviceId" />
          </div>
          <van-button type="primary" size="small" @click="confirmDevice" :disabled="isRequesting">重置</van-button>
        </div>
        <van-list
          v-model:loading="listState.loading"
          :finished="listState.finished"
          finished-text="没有更多了"
          v-model:error="listState.error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
          class="flex-main overflow-scroll pd8"
          v-show="listState.list.length > 0"
        >
          <div v-for="item in listState.list" :key="item" class="text-cell" @click="selectDevice(item.deviceId)">
            <van-row>
              <van-col span="12">
                设备ID:
                <span>{{ item.deviceId }}</span>
              </van-col>
              <van-col span="12">
                车辆状态:
                <span :class="['online-tag', item.runStatus === '0' ? 'red' : 'green']">
                  {{ item.runStatus == '0' ? '离线' : '在线' }}
                </span>
              </van-col>
            </van-row>
            <van-row class="cell-bottom">
              <van-col span="12">重量更新: {{ item.carUploadDate }}</van-col>
              <van-col span="12">GPS更新: {{ item.gpsUploadDate }}</van-col>
            </van-row>
            <div
              :class="[
                'cell-checkbox',
                'position-absolute',
                { 'cell-checkbox_checked': newDeviceId === item.deviceId }
              ]"
            >
              <van-icon name="success" />
            </div>
          </div>
        </van-list>
      </div>

      <div class="card">
        <div class="option line">
          <div class="option__label">上传频率：</div>
          <div class="option__content">
            <input type="text" class="freqInput" v-model="baseState.freqNum" readonly />
          </div>
          <div class="option__operate">
            <div class="status" v-show="statusIcon">
              <van-icon name="success" size="0.6rem" color="#07c160" v-show="autoResetState.frequencyStatus === 1" />
              <van-icon name="cross" size="0.6rem" color="#ee0a24" v-show="autoResetState.frequencyStatus === 2" />
              <van-loading type="spinner" size="0.6rem" v-show="autoResetState.frequencyStatus === 3" />
            </div>
            <van-button type="primary" size="small" @click="freqClick" :disabled="isRequesting">设置</van-button>
          </div>
        </div>

        <div class="option line">
          <div class="option__label">硬件版本：</div>
          <div class="option__content long-text">{{ baseState.hwVersion }}</div>
          <div class="option__operate">
            <div class="status" v-show="statusIcon">
              <van-icon name="success" size="0.6rem" color="#07c160" v-show="autoResetState.hwVersionStatus === 1" />
              <van-icon name="cross" size="0.6rem" color="#ee0a24" v-show="autoResetState.hwVersionStatus === 2" />
              <van-loading type="spinner" size="0.6rem" v-show="autoResetState.hwVersionStatus === 3" />
            </div>
            <van-button type="primary" size="small" @click="getVersion" :disabled="isRequesting">获取</van-button>
          </div>
        </div>

        <div class="option line">
          <div class="option__label">当前系数：</div>
          <div class="option__content long-text" @click="showContent(coefficient, '当前系数')">
            {{ coefficient }}
          </div>
          <div class="option__operate">
            <div class="status" v-show="statusIcon">
              <van-icon name="success" size="0.6rem" color="#07c160" v-show="autoResetState.coefStatus === 1" />
              <van-icon name="cross" size="0.6rem" color="#ee0a24" v-show="autoResetState.coefStatus === 2" />
              <van-loading type="spinner" size="0.6rem" v-show="autoResetState.coefStatus === 3" />
            </div>
            <van-button type="primary" size="small" @click="resetParameter" :disabled="isRequesting">参数</van-button>
          </div>
        </div>

        <div class="option line">
          <div class="option__label">算法版本：</div>
          <div class="option__content long-text" @click="showContent(baseState.version, '算法版本')">
            {{ baseState.version }}
          </div>
          <div class="option__operate">
            <div class="status" v-show="statusIcon">
              <van-icon name="success" size="0.6rem" color="#07c160" v-show="autoResetState.binStatus === 1" />
              <van-icon name="cross" size="0.6rem" color="#ee0a24" v-show="autoResetState.binStatus === 2" />
              <van-loading type="spinner" size="0.6rem" v-show="autoResetState.binStatus === 3" />
            </div>
            <van-button type="primary" size="small" @click="upGrade" :disabled="isRequesting">升级</van-button>
          </div>
        </div>
        <div class="bin-content" v-show="autoResetState.binContent || autoResetState.errMsg">
          <span>{{ autoResetState.step }}:</span>
          {{ autoResetState.errMsg || autoResetState.binContent }}
        </div>
      </div>
    </div>
    <router-view v-slot="{ Component }">
      <transition :name="animationName">
        <component :is="Component" />
      </transition>
    </router-view>

    <van-dialog
      v-model:show="showFreqInput"
      :close-on-click-overlay="true"
      title="上传频率（秒）"
      confirm-button-color="#1989fa"
      @confirm="setFreq"
    >
      <div class="set-freq-div">
        <input
          type="number"
          step="1"
          v-model="freqNumber"
          class="set-freq-input"
          ref="setFreqInput"
          @input="freqNumInput"
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, reactive, computed, provide, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog, Toast } from 'vant'
import http from '@/utils/http'
import { http_clean } from '@/utils/request'
import { useAnimation } from '@/hooks/useAnimation'
import NewBadge from '@/views/components/NewBadge.vue'

/**
 * 下拉列表查询
 * @param {String} fetchUrl 列表查询 URL
 * @returns { listState, onLoad }
 */
function useList(fetchUrl) {
  const listState = reactive({
    list: [],
    loading: false,
    finished: false,
    error: false,
    searchKey: ''
  })
  // 页脚信息，判断是否加载完成
  const pager = {
    pageCount: 1,
    pageSize: 10,
    nowPage: 0
  }
  // list 的加载事件
  const onLoad = () => {
    // 拦截异常，无搜索内容不查询
    if (listState.searchKey === '') {
      listState.loading = false
      return
    }
    // 接口判断是否加载完成，这里根据完成状态确定是否加载
    if (!listState.finished) fetchList()
  }
  // 数据查询
  const fetchList = (isReset = false) => {
    if (isReset && listState.searchKey.length < 3) {
      Toast('新的主机ID不能少于三位')
      return
    }
    pager.nowPage++
    const params = JSON.stringify({
      searchKey: listState.searchKey,
      useTypeL2: '',
      company: '',
      online: '0',
      sort: '',
      useTypeL1: ''
    })
    const gridPager = `{"pageSize":"${pager.pageSize}","nowPage":"${pager.nowPage}","parameters":${params}}`
    http({
      url: fetchUrl,
      method: 'post',
      data: {
        gridPager
      }
    }).then(res => {
      if (res.isSuccess) {
        const { pageCount } = res
        pager.pageCount = pageCount
        if (isReset) listState.list = []
        listState.list = listState.list.concat(res.exhibitDatas)
        // 加载状态结束
        listState.loading = false
        // 存在总共 0 页的情况，所以判断 >=
        if (pager.nowPage >= pager.pageCount) listState.finished = true
      } else {
        // 加载状态结束
        listState.loading = false
        listState.error = true
      }
    })
  }

  return {
    listState,
    onLoad,
    fetchList
  }
}
// 获取 vuex 中保存的状态
function initState(state) {
  const store = useStore()
  const { deviceId, carNumber, runStatus, version, hwVersion, coef } = store.state.search
  state.deviceId = deviceId
  state.runStatus = runStatus
  state.carNumber = carNumber
  state.version = version
  state.hwVersion = hwVersion
  state.coef = coef

  provide('deviceId', deviceId)
  provide('runStatus', runStatus)
}
/**
 * 初始化页面展示的数据
 */
function useState() {
  const baseState = reactive({
    deviceId: '',
    carNumber: '',
    version: '',
    hwVersion: '',
    runStatus: '',
    coef: '',
    freqNum: '', // 上传频率 接口返回，默认为空
    selectedDevice: '' // 选中的新设备ID
  })
  initState(baseState)
  return {
    baseState
  }
}
/**
 * 自动重置 ID 功能
 * @param { Object } state 页面初始数据
 */
function useAutoReset(state) {
  // 保存 设备ID 和 车牌号
  const { deviceId, carNumber } = state
  // 新的设备ID
  const newDeviceId = ref('')
  // 是否在轮询，轮询中禁止按钮操作
  const isRequesting = ref(false)
  // 展示状态标识
  const statusIcon = ref(false)

  const autoResetState = reactive({
    frequencyStatus: 3,
    hwVersionStatus: 3,
    coefStatus: 3,
    binStatus: 3,
    binContent: '', // 升级信息
    step: '',
    errMsg: '' // 失败信息
  })

  const loopQueue = [
    { key: 'frequencyStatus', text: '上传频率' },
    { key: 'hwVersionStatus', text: '硬件版本' },
    { key: 'coefStatus', text: '当前系数' },
    { key: 'binStatus', text: '算法版本' }
  ]
  // TODO 修改轮询时间常量
  const TIME_COUNT = 9 * 60 // 轮询超时秒数

  // 保存定时器
  let requestTimer = null
  // 卸载前销毁
  onBeforeUnmount(() => {
    requestTimer && clearInterval(requestTimer)
    requestTimer = null
  })

  // 列表选中 写入设备ID
  const selectDevice = id => {
    if (isRequesting.value) {
      return
    }
    newDeviceId.value = id
  }

  // 确认重置设备ID
  const confirmDevice = () => {
    if (!newDeviceId.value) {
      Toast('请选择需要重置的设备')
      return
    }
    Dialog.confirm({
      title: '提示',
      message: '确定重置ID？',
      confirmButtonColor: '#1989fa'
    })
      .then(() => {
        statusIcon.value = true
        http_clean({
          url: 'system/uploadDeviceIdAuto.html',
          method: 'post',
          data: {
            carNumber,
            deviceId,
            newDeviceId: newDeviceId.value
          }
        })

        // 提示清空，只有进行到算法版本才展示
        autoResetState.binContent = ''
        // 清空错误提示
        autoResetState.errMsg = ''
        // 首次重置状态为第一步
        autoResetState.step = ''

        // 所以状态为 loading
        loopQueue.forEach(step => {
          autoResetState[step.key] = 3
        })

        // 记录轮询状态
        isRequesting.value = true

        let timeCount = 0

        let hasFailed = { status: false }

        let allSuccess = { status: false }

        // 定时器中只负责清除定时器操作，所有失败提示在请求中处理
        requestTimer = setInterval(() => {
          // 超时或者请求失败, 和 请求全部成功 不需要再轮询
          // 没有失败请求，没有全部成功，继续请求
          if (!hasFailed.status && !allSuccess.status) {
            updateAuto(deviceId, timeCount, hasFailed, allSuccess)
          }
          // 超时清空定时器
          if (timeCount > TIME_COUNT * 1000 || hasFailed.status || allSuccess.status) {
            isRequesting.value = false
            clearInterval(requestTimer)
            requestTimer = null
            return
          }
          timeCount += 5 * 1000
        }, 5000)
      })
      .catch(() => {})
  }

  function updateAuto(deviceId, timeCount, hasFailed, allSuccess) {
    http_clean({
      url: 'system/getStatusByUpLoadDeviceIdAuto.html',
      method: 'get',
      params: { deviceId }
    })
      .then(res => {
        const { data: result } = res
        let code,
          message = '请求失败'
        if (result) {
          code = result.code
          message = result.message || '请求失败'
        }
        // 记录 loading 的状态
        // 超时后需要从 loading 的位置开始设置状态为失败
        let loadingIndex = loopQueue.length

        // 重新写入硬件版本和算法版本
        state.hwVersion = result.hwVersion ? result.hwVersion : state.hwVersion
        state.version = result.binVersion ? result.binVersion : state.version

        // 无 code 或 code 为0，即下发请求失败退出轮询 code(0失败 1成功)
        if (!code || code === 0) {
          autoResetState.binContent = ''
          loopQueue.forEach(step => {
            if (autoResetState[step.key] === 3) {
              hasFailed.status === false && (autoResetState.step = step.text)
              hasFailed.status = true
            }
            // 循环修改状态
            if (hasFailed.status) {
              autoResetState[step.key] = 2
            }
          })
          // 无论状态如何，请求失败了不再继续
          hasFailed.status = true
          autoResetState.errMsg = message
          Toast(message)
          return
        }

        // 默认全部成功，如果有失败或加载中 置为 false
        let successFlag = true
        autoResetState.binContent = result.binContent

        // 遍历有三种情况 1成功 2失败 3执行中
        // 成功需要继续遍历
        // 如果存在 loading 后续全为 loading
        // 因为默认是 loading 状态，所以遇到 loading 状态就可以跳出循环了
        // 如果存在失败后续全为失败
        for (let i = 0; i < loopQueue.length; i++) {
          const key = loopQueue[i].key
          const text = loopQueue[i].text
          // 将接口返回状态记录下来
          autoResetState[key] = result[key]
          // 如果前面已经有失败，就为失败 continue
          // 前面有失败，loading 变为失败，所以需要前置判断
          if (hasFailed.status) {
            // 由于前面存在失败，需要修改状态
            // 这里重置状态为失败
            autoResetState[key] = 2
            continue
          }
          // 如果是 loading，后续全为 loading
          // 状态默认是 loading 所以不需要继续循环
          if (result[key] === 3) {
            loadingIndex = i
            successFlag = false
            // 记录当前的操作步骤，请求发送失败时候用
            autoResetState.step = text
            break
          }
          if (result[key] === 2) {
            //存在失败后续全部失败
            hasFailed.status = true
            autoResetState.step = text
            autoResetState.errMsg = message
            successFlag = false
            Toast(message)
          }
        }
        allSuccess.status = successFlag

        // 循环后判断超时 超时所有 loading 变为失败
        if (timeCount > TIME_COUNT * 1000) {
          autoResetState.step = loopQueue[loadingIndex].text
          autoResetState.errMsg = '重置ID操作超时'
          for (loadingIndex; loadingIndex < loopQueue.length; loadingIndex++) {
            const key = loopQueue[loadingIndex].key
            autoResetState[key] = 2
          }
        }
      })
      .catch(() => {
        // 这里使用上次请求记录的步骤
        autoResetState.errMsg = '发送请求失败'
        loopQueue.forEach(step => {
          autoResetState[step.key] = autoResetState[step.key] !== 1 ? 2 : autoResetState[step.key]
        })
        // 请求失败也是失败状态
        hasFailed.status = true
      })
  }
  return {
    autoResetState,
    newDeviceId,
    isRequesting,
    statusIcon,
    selectDevice,
    confirmDevice
  }
}

function useResetId() {
  const store = useStore()
  const { baseState } = useState()
  const { listState, onLoad, fetchList } = useList('system/realTimeData.html')
  // 存入查询设备升级的 companyId
  store.commit('resetId/updateState', { companyId: store.state.search.companyId })

  const coefficient = computed(() => {
    return store.getters['search/coefficient']
  })

  return {
    baseState,
    listState,
    onLoad,
    coefficient,
    fetchList
  }
}

export default defineComponent({
  name: 'ResetID',
  components: {
    [Dialog.Component.name]: Dialog.Component,
    NewBadge
  },
  setup() {
    const router = useRouter()
    const { animationName } = useAnimation()

    const { baseState, listState, onLoad, coefficient, fetchList } = useResetId()
    const { autoResetState, newDeviceId, isRequesting, statusIcon, selectDevice, confirmDevice } =
      useAutoReset(baseState)

    const store = useStore()

    onMounted(() => {
      http_clean({
        url: 'devicemanage/sendCodeForReadTimePeriod.html',
        method: 'post',
        data: {
          deviceId: baseState.deviceId,
          protocolVersion: 2
        }
      }).then(res => {
        if (res.success) {
          baseState.freqNum = res.data
        } else {
          baseState.freqNum = 10
        }
      })
    })

    const restartDevice = () => {
      Dialog.confirm({
        title: '提示',
        message: '确定重启设备？',
        confirmButtonColor: '#1989fa'
      })
        .then(() => {
          http({
            url: 'devicemanage/sendCodeForWriteRestart.html',
            method: 'post',
            data: {
              deviceId: baseState.deviceId,
              protocolVersion: 2
            }
          }).then(res => {
            if (res.success) {
              setTimeout(() => {
                Toast(res.message)
              }, 500)
            }
          })
        })
        .catch(() => {})
    }
    // 获取硬件版本
    const getVersion = () => {
      http({
        url: 'devicemanage/sendCodeForReadDeviceVersion.html',
        method: 'post',
        loadText: '等待数据返回...',
        data: {
          deviceId: baseState.deviceId,
          protocolVersion: 2
        }
      }).then(res => {
        if (res.success) {
          let temData = res.data.split(',')
          if (temData) {
            baseState.hwVersion = temData[0] ? temData[0] : ''
          }
        }
        setTimeout(() => {
          Toast(res.message)
        }, 500)
      })
    }
    // 重置参数
    const resetParameter = () => {
      Dialog.confirm({
        title: '提示',
        message: '是否设置原始系数？',
        confirmButtonColor: '#1989fa'
      })
        .then(() => {
          const { coef, runStatus } = baseState
          const online = runStatus === '0' ? 'offline' : 'online'
          const parameters = {
            deviceId: baseState.deviceId,
            protocolVersion: 2,
            online
          }
          coef.forEach((el, i) => {
            const para = 'channel' + (i + 1)
            parameters[para] = el.toString()
          })
          http({
            url: 'system/remotetuning.html',
            method: 'post',
            loadText: '正在重置中...',
            data: parameters
          }).then(res => {
            setTimeout(() => {
              Toast(res.message)
            }, 500)
          })
        })
        .catch(() => {
          setTimeout(() => {
            router.push({
              name: 'ResetChannel'
            })
          }, 100)
        })
    }

    const upGrade = () => {
      Dialog.confirm({
        title: '提示',
        message: '是否升级原始程序？',
        confirmButtonColor: '#1989fa'
      })
        .then(() => {
          http({
            method: 'post',
            url: 'system/upGradeByDeviceId.html',
            loadText: '正在升级中...',
            data: {
              deviceId: baseState.deviceId
            }
          }).then(res => {
            Toast(res.message)
          })
        })
        .catch(() => {
          setTimeout(() => {
            router.push({
              name: 'UpGradeRecords'
            })
          }, 100)
        })
    }

    const { freqNumber, showFreqInput, setFreqInput, freqClick, freqNumInput, setFreq } = useSetFreq(baseState)

    const showContent = (content, title) => {
      Dialog.alert({
        title,
        message: content,
        confirmButtonColor: '#1989fa'
      })
    }

    return {
      animationName,
      baseState,
      listState,
      onLoad,
      coefficient,
      restartDevice,
      getVersion,
      resetParameter,

      upGrade,
      fetchList,
      sensorObj: computed(() => store.state.search.sensorList),

      freqNumber,
      showFreqInput,
      setFreqInput,
      freqClick,
      freqNumInput,
      setFreq,

      autoResetState,
      newDeviceId,
      isRequesting,
      statusIcon,
      selectDevice,
      confirmDevice,

      showContent
    }
  }
})

function useSetFreq(state) {
  const freqNumber = ref('')

  const showFreqInput = ref(false)
  const setFreqInput = ref()

  const freqClick = () => {
    freqNumber.value = state.freqNum
    showFreqInput.value = true

    setTimeout(() => {
      setFreqInput.value.focus()
    }, 50)
  }

  const freqNumInput = () => {
    freqNumber.value = freqNumber.value.replace(/\D+/g, '')
  }

  const setFreq = () => {
    const freq = Number(freqNumber.value)
    if (!freq || freq > 60 || freq <= 0) {
      Toast('上传频率范围是大于0小于或等于60')
      return false
    }
    http({
      loadText: '请求发送中...',
      url: 'devicemanage/sendCodeForWriteTimePeriod.html',
      method: 'post',
      data: {
        protocolVersion: 2,
        time: freqNumber.value,
        deviceId: state.deviceId
      }
    }).then(res => {
      state.freqNum = freqNumber.value
      Toast(res.message)
    })
  }
  return {
    freqNumber,
    showFreqInput,
    setFreqInput,
    freqClick,
    freqNumInput,
    setFreq
  }
}
</script>
<style lang="scss" scoped>
@import '@/style/mixins.scss';

.card {
  border-radius: 8px;
  padding: 8px;
}
.bin-content {
  padding: 4px 8px;
  text-align: center;
  font-size: 12px;
  color: #969799;
}
.device-list {
  height: 240px;
  display: flex;
  flex-direction: column;
}
.option {
  padding: 8px 8px;
  height: 26px;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #455a64;
  &__label {
    min-width: 60px;
  }
  &__content {
    flex: 1;
    word-break: break-word;

    .online-tag {
      font-size: 12px;
    }
    .freqInput {
      width: 100%;
      border: none;
    }
  }
  .long-text {
    @include ellipsis;
  }
  #sensorRender {
    display: inline-flex;
    flex-wrap: wrap;
  }
  &__operate {
    padding: 0 8px;
    min-width: 40px;
    display: inline-flex;
    align-items: center;
    .status {
      margin-right: 4px;
    }
    :deep(.van-button--small) {
      height: 24px;
    }
  }
}

.cell-bottom {
  font-size: 10px;
}
.cell-checkbox {
  right: 0;
  top: 16px;
  height: 1em;
  font-size: 20px;
  line-height: 1em;
  user-select: none;
}
.cell-checkbox .van-icon {
  color: #fff;
  border: 1px solid #c8c9cc;
}
.cell-checkbox_checked .van-icon {
  background-color: #1989fa;
  border-color: #1989fa;
}
.cell-checkbox .van-icon {
  border-radius: 100%;
}
.choseDevice {
  width: 100%;
  padding: 4px 12px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &-text {
    box-sizing: border-box;
    width: 80%;
    padding: 0 12px;
    input {
      width: 100%;
      border: 1px solid #c8c9cc;
      border-radius: 4px;
      height: 22px;
      padding: 0 4px;
    }
  }
  :deep(.van-button--small) {
    height: 24px;
    width: 50px;
  }
}
.set-freq-div {
  margin: 16px 32px;
  border-bottom: 1px solid #ebedf0;
  .set-freq-input {
    border: none;
    width: 100%;
    margin: 0;
    padding: 0 8px;
  }
}
</style>
