<template>
  <div class="cover-page">
    <NavBar />
    <div class="container" ref="container">
      <div class="sub-container" ref="subContainer">
        <van-row>
          <van-col span="24" v-for="(item, i) in params" :key="i">
            <div class="channel" v-if="item.show">
              <div class="channel__axis">
                {{ item.axis === '1' ? '前轴' : item.axis === '2' ? '后轴' : item.axis === '3' ? '不确定' : '' }}
              </div>
              <div class="channel__title">通道{{ i + 1 }}:</div>
              <div class="channel__value" v-if="item.show">
                <input
                  type="text"
                  v-model="params[i].value"
                  :data-index="i"
                  min="0"
                  readonly
                  @touchstart.stop="inputChannel(i, $event)"
                  :class="{ active: indexKey === i }"
                />
              </div>
            </div>
          </van-col>
        </van-row>
        <div class="bottom-btn">
          <van-button type="primary" @click="resetChannel">重置</van-button>
          <van-button type="primary" @click="submitChannel">提交</van-button>
        </div>
        <van-number-keyboard
          ref="inputKeyboard"
          :show="show"
          extra-key="."
          @blur="closeInput"
          @input="onInput"
          @delete="onDelete"
          close-button-text="完成"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, inject, onMounted, ref } from 'vue'
import http from '@/utils/http'
import { Toast } from 'vant'

export default defineComponent({
  name: 'ResetChannel',
  setup() {
    const deviceId = inject('deviceId')
    const runStatus = inject('runStatus')
    const params = ref([])
    onMounted(() => {
      resetChannel()
      originHeight = container.value.clientHeight
      container.value.style.maxHeight = originHeight + 'px'
    })
    const inputKeyboard = ref()
    const container = ref()
    const subContainer = ref()
    let originHeight = 0

    // const inputTest = index => {
    //   const value = params.value[index]
    //   // 检测输入是否符合数字规则
    //   const reg = /(^0{1}\.{1}\d+$)|(^[1-9]{1}[0-9]+\.{1}\d+$)|(^[1-9][0-9]*$)|(^0{1}$)/
    //   const result = reg.test(value)
    // }
    const submitChannel = () => {
      const parameters = {
        deviceId,
        protocolVersion: 2,
        online: runStatus === '0' ? 'offline' : 'online'
      }
      params.value.forEach((el, i) => {
        const para = 'channel' + (i + 1)
        parameters[para] = el.value === '' ? '0' : el.value
      })
      http({
        url: 'system/remotetuning.html',
        method: 'post',
        data: parameters
      }).then(res => {
        Toast(res.message)
      })
    }

    const resetChannel = () => {
      http({
        url: 'system/getDeviceBaseInfoByDeviceId.html',
        method: 'post',
        data: {
          deviceId,
          showApi: 0,
          showModify: 1
        }
      }).then(res => {
        const { data } = res
        const { sensorAxle, sensorChannel, coef } = data
        const channels = coef.map((el, index) => {
          const temp = {
            show: false,
            axis: sensorAxle[index]
          }
          temp.value = el
          if (sensorChannel.includes(String(index + 1))) {
            temp.show = true
          }
          return temp
        })
        params.value = channels
      })
    }

    const indexKey = ref(null) // 修改通道的 index
    const show = ref(false) // 数字输入框
    const inputChannel = (key, e) => {
      indexKey.value = key
      show.value = true
      const target = e.target
      setTimeout(() => {
        const inputHeight = inputKeyboard.value.$el.clientHeight
        subContainer.value.style.height = inputHeight + originHeight + 'px'
        setTimeout(() => {
          target.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      }, 50)
    }

    const closeInput = () => {
      show.value = false
      indexKey.value = null
      subContainer.value.style.height = originHeight + 'px'
    }
    // 阻止 native 输入框弹出
    const stopKeyboard = e => {
      e.target.setAttribute('readonly', 'readonly')
      setTimeout(() => {
        e.target.removeAttribute('readonly')
      }, 100)
    }

    // 输入更新数据
    const onInput = value => {
      const channels = params.value.slice()
      console.log(channels)
      const item = channels[indexKey.value]
      item.value = item.value.toString() + value
      params.value = channels
    }

    const onDelete = () => {
      const channels = params.value.slice()
      const item = channels[indexKey.value]
      item.value = item.value.toString().slice(0, -1)
      params.value = channels
    }
    return {
      params,
      inputChannel,
      indexKey,
      closeInput,
      resetChannel,
      submitChannel,
      stopKeyboard,
      show,
      onInput,
      onDelete,
      inputKeyboard,
      container,
      subContainer
    }
  }
})
</script>
<style lang="scss" scoped>
.channel {
  padding: 12px 2px;
  font-size: 14px;
  line-height: 2;
  display: flex;
  &__axis {
    width: 70px;
    text-align: center;
  }
  &__title {
    width: 70px;
  }
  &__value {
    input {
      width: 100%;
      box-sizing: border-box;
      line-height: 2;
      padding: 0 4px;
      color: #333;
    }
  }
}
.container {
  overflow: scroll;
  .sub-container {
    box-sizing: border-box;
  }
}
.bottom-btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 24px;
  padding: 14px 0;
  button {
    width: 110px;
    height: 40px;
  }
}
input.active {
  border: 1px solid salmon;
}
</style>
